import React from 'react';
import ReactSelect, { type ActionMeta } from 'react-select';
import InputLabel from '../inputCommonElements/InputLabel.ts';
import InputError from '../inputCommonElements/InputError.tsx';
import { Container } from './shared/styles.ts';
import type {
  OptionType,
  RSOptionRef,
  SelectOnChange,
  SingleOrMultiValue,
} from './shared/types.ts';
import {
  createDropdownIndicator,
  createMultiValueChip,
  createNoOptionComponent,
  createOption,
} from './shared/utils.tsx';

export interface SelectProps {
  customContainerStyle?: () => string;
  customInputStyle?: () => string;
  customLabelStyle?: () => string;
  customOptionStyle?: () => string;
  customErrorContainerStyle?: () => string;
  options: OptionType[];
  dataTestId?: string;
  disabled?: boolean;
  error?: string;
  helperText?: string;
  hideBottomSpace?: boolean;
  isClearable: boolean;
  isMulti: boolean;
  label: string;
  loading?: boolean;
  onChange: SelectOnChange;
  onClear?: (value: OptionType[], actionMeta: ActionMeta<OptionType>) => void;
  placeholder?: string;
  value?: SingleOrMultiValue;
  isSearchable: boolean;
  noOptionsMessage?: string;
}

const Select = React.forwardRef(
  (
    {
      label,
      options,
      onChange,
      placeholder,
      error,
      helperText,
      value,
      disabled,
      customContainerStyle,
      customLabelStyle,
      customInputStyle,
      customOptionStyle,
      customErrorContainerStyle,
      dataTestId,
      hideBottomSpace,
      isMulti,
      isSearchable,
      noOptionsMessage,
      ...otherProps
    }: SelectProps,
    ref
  ) => {
    const chipLabel = `selected-chip${dataTestId ? `-${dataTestId}` : ''}`;
    const selectClass = `Select${dataTestId ? `-${dataTestId}` : ''}`;
    const matchedVal =
      typeof value === 'string' || typeof value === 'number'
        ? options?.find((option) => option.value === value)
        : value;

    return (
      <Container
        prefix={selectClass}
        error={error}
        disabled={disabled}
        customContainerStyle={customContainerStyle}
      >
        {label ? (
          <InputLabel
            error={error}
            disabled={disabled}
            customLabelStyle={customLabelStyle}
          >
            {label}
          </InputLabel>
        ) : null}
        <ReactSelect
          // @ts-expect-error override ref
          ref={ref}
          options={options}
          value={matchedVal}
          onChange={onChange}
          error={error}
          disabled={disabled}
          isDisabled={disabled}
          placeholder={placeholder}
          menuPlacement="auto"
          classNamePrefix={selectClass}
          components={{
            DropdownIndicator: (props) =>
              createDropdownIndicator({ props, dataTestId }),
            Option: (props) =>
              createOption({
                props,
                customOptionStyle,
                dataTestId,
                isMulti,
                ref: ref as RSOptionRef,
              }),
            MultiValueContainer: (props) =>
              createMultiValueChip({ props, dataTestId: chipLabel }),
            NoOptionsMessage: (props) =>
              createNoOptionComponent({
                props,
                dataTestId,
                ref: ref as RSOptionRef,
                noOptionsMessage,
              }),
          }}
          customInputStyle={customInputStyle}
          customOptionStyle={customOptionStyle}
          closeMenuOnSelect={!isMulti}
          hideSelectedOptions={!isMulti}
          isSearchable={isSearchable}
          data-testid={dataTestId}
          isMulti={isMulti}
          {...otherProps}
        />
        {!hideBottomSpace && (
          <InputError
            error={error}
            helperText={helperText}
            customErrorContainerStyle={customErrorContainerStyle}
            dataTestId={`input-error-${dataTestId}`}
          />
        )}
      </Container>
    );
  }
);

Select.displayName = 'Select';

export default Select;

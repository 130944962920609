import React, { useState } from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import THEMES from '../../../styles/themes/library/textInput';
import Checkbox from '../../Checkbox/Checkbox';
import Option from '../shared/Option.tsx';
import FONTSIZE_THEMES from '../../../styles/themes/fontSize/fontSize';
import DetectClickOutside from '../../../components/Common/DetectClickOutside';

const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
`;

const MainTableHeaderWrapper = styled.div`
  &:hover {
    text-decoration: ${(props) => (props.disabled ? 'none' : 'underline')};
    cursor: pointer;
  }
`;

const StyledSelect = styled(ReactSelect)`
  & .Select__control {
    height: 35px;
    border-color: transparent;
    background-color: transparent;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

    ${(props) => props?.customInputStyle?.(props)}
  }

  & .Select__single-value {
    color: ${THEMES.TEXT};
  }

  & .Select__control:focus-within {
    border-color: transparent;
    box-shadow: none;
  }

  & .Select__control--is-focused,
  & .Select__control:hover {
    border-color: transparent;
  }

  & .Select__control--is-disabled {
    margin-right: 5px;
  }

  & .Select__indicator-separator {
    display: none;
  }

  & .Select__menu {
    margin-top: 1px;
    width: 150px;
  }

  & .Select__menu-list {
    padding: 0;
    border: 1px solid ${THEMES.BORDER};
    border-radius: 5px;
  }

  & .Select__menu {
    position: absolute;
    top: 30px;
    left: 0;
    border-radius: 5px;
  }

  & .Select__option {
    height: 40px;
    font-weight: 500;
    width: 150px;
    text-transform: capitalize;
    font-family: 'Barlow', sans-serif;
    color: ${THEMES.OPTION_TEXT};
    background-color: ${THEMES.OPTION_BACKGROUND};
    display: flex;
    align-items: center;
    padding: 0 8px;
    ${(props) => props?.customOptionStyle?.(props)};
    font-size: ${FONTSIZE_THEMES.SELECT_TEXT};
  }

  & .Select__option:nth-of-type(1) {
    border-radius: 4px 4px 0 0;
  }

  & .Select__option:nth-last-of-type(1) {
    border-radius: 0 0 4px 4px;
  }

  & .Select__option--is-focused,
  .Select__option--is-focused:active {
    color: ${THEMES.OPTION_TEXT_HOVER};
    background-color: ${THEMES.OPTION_BACKGROUND_HOVER};
  }

  & .Select__option--is-selected {
    color: ${THEMES.OPTION_TEXT_ACTIVE};
    background-color: ${THEMES.OPTION_BACKGROUND_ACTIVE};
  }
  & .Select__indicators {
    display: ${(props) => (props.disabled ? 'none!important' : 'flex')};
  }
`;

const SelectDropdownArrow = styled.i`
  font-size: 0px;
  padding-left: 5px;
  ${(props) => (props.menuIsOpen ? 'transform: scaleY(-1)' : '')};
`;

const DropdownIndicator = ({ selectProps, dataTestId }) => (
  <SelectDropdownArrow
    className="ri-arrow-drop-down-line"
    menuIsOpen={selectProps?.menuIsOpen}
    data-testid={`${dataTestId}-dropdown-indicator`}
  />
);

DropdownIndicator.propTypes = {
  selectProps: PropTypes.object.isRequired,
  dataTestId: PropTypes.string.isRequired,
};

const MultiSelectTableItems = React.forwardRef(
  (
    {
      options,
      disabled,
      customContainerStyle,
      customOptionStyle,
      dataTestId,
      allItemsSelected,
      mainTableHeader,
      indeterminate,
      ...otherProps
    },
    ref
  ) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const createDropdownIndicator = (props) => (
      <DropdownIndicator dataTestId={dataTestId} {...props} />
    );

    const createOption = (props) => (
      <Option
        dataTestId={dataTestId}
        customOptionStyle={() => `text-transform: capitalize;`}
        {...props}
      />
    );

    const handleSelectChange = (selectedOption) => {
      if (selectedOption && selectedOption.handleSelect) {
        selectedOption.handleSelect();
      }
    };

    const handleMenuOpen = () => {
      setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
      setIsMenuOpen(false);
    };

    const handleOnCheckboxClick = () => {
      if (!disabled) {
        handleMenuOpen();
      }
    };

    return (
      <DetectClickOutside onClickOutside={() => handleMenuClose()}>
        <Container
          customContainerStyle={customContainerStyle}
          css={(props) => props?.customContainerStyle?.(props)}
        >
          <StyledSelect
            options={options}
            onChange={handleSelectChange}
            value={{ label: '', value: '' }}
            disabled={disabled}
            isDisabled={disabled}
            isSearchable={false}
            menuPlacement="auto"
            classNamePrefix="Select"
            menuIsOpen={isMenuOpen}
            onMenuOpen={handleMenuOpen}
            onMenuClose={handleMenuClose}
            components={{
              Option: createOption,
              DropdownIndicator: createDropdownIndicator,
            }}
            customOptionStyle={customOptionStyle}
            closeMenuOnSelect
            data-testid={dataTestId}
            {...otherProps}
            ref={ref}
          />
          <Checkbox
            onCheck={handleOnCheckboxClick}
            customContainerStyle={() => `position: absolute; top: 11px;`}
            customBoxStyle={() => `cursor: pointer;`}
            disabled={disabled}
            checked={!!allItemsSelected}
            indeterminate={indeterminate}
          />
          <MainTableHeaderWrapper
            disabled={disabled}
            onClick={handleOnCheckboxClick}
          >
            {mainTableHeader}
          </MainTableHeaderWrapper>
        </Container>
      </DetectClickOutside>
    );
  }
);

MultiSelectTableItems.propTypes = {
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  customContainerStyle: PropTypes.func,
  customOptionStyle: PropTypes.func,
  dataTestId: PropTypes.string,
  allItemsSelected: PropTypes.bool,
  indeterminate: PropTypes.bool,
  mainTableHeader: PropTypes.string,
};

MultiSelectTableItems.defaultProps = {
  disabled: false,
  customContainerStyle: null,
  customOptionStyle: null,
  dataTestId: 'multiSelectTableItems',
  allItemsSelected: false,
  indeterminate: false,
  mainTableHeader: '',
};

MultiSelectTableItems.displayName = 'MultiSelectTableItems';

export default MultiSelectTableItems;

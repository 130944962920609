import { v4 as uuidv4 } from "uuid";
import threadQuery from "../../graphql/query/ThreadQuery.graphql";
import threadHistoryQuery from "../../graphql/query/ThreadHistoryQuery.graphql";
// import customerAccountQuery from '../../graphql/query/CustomerAccountQuery.graphql';
import customersQuery from "../../graphql/query/CustomersQuery.graphql";
import recentCustomersQuery from "../../graphql/query/RecentCustomersQuery.graphql";
// import customerContactFragment from '../../graphql/fragments/CustomerContactFragment.graphql';
import userContactFragment from "../../graphql/fragments/UserContactFragment.graphql";
import threadFragment from "../../graphql/fragments/ThreadFragment.graphql";
import usersQuery from "../../graphql/query/UsersQuery.graphql";
import ACCOUNT_QUERY from "../../graphql/query/AccountQuery.graphql";
import { getCustomerName } from "../../utils/helpers";
import threadsQueryVars from "../../utils/threadsQueryVars";
import INBOX_SPAM_THREADS from "../../graphql/query/InboxSpamThreadsQuery.graphql";
import MY_INBOX_THREADS from "../../graphql/query/MyInboxThreadsQuery.graphql";
import MY_OPEN_THREADS from "../../graphql/query/MyOpenThreadsQuery.graphql";
import MY_CLOSED_THREADS from "../../graphql/query/MyClosedThreadsQuery.graphql";
import ALL_CLOSED_THREADS from "../../graphql/query/AllClosedThreadsQuery.graphql";
import ALL_OPEN_THREADS from "../../graphql/query/AllOpenThreadsQuery.graphql";
import thread_utils from "../../utils/thread_utils";
import WORK_ORDERS_QUERY from "../../graphql/query/WorkOrdersQuery.graphql";
import WORK_ORDER_COUNT_QUERY from "../../graphql/query/WorkOrderCountQuery.graphql";
import ACTIVITIES_QUERY from "../../graphql/query/ActivitiesQuery.graphql";
import OPEN_THREAD_COUNT_FOR_USER_BY_GROUP from "../../graphql/query/OpenThreadCountForUserByGroup.graphql";
import OPEN_THREAD_COUNT_FOR_USER_BY_USER from "../../graphql/query/OpenThreadCountForUserByUserQuery.graphql";
import OPEN_THREAD_COUNT_FOR_USER_TOTAL from "../../graphql/query/OpenThreadCountForUserTotalQuery.graphql";
import UNCLAIMED_THREAD_COUNT_FOR_USER_BY_GROUP from "../../graphql/query/UnclaimedThreadCountForUserByGroup.graphql";
import UNCLAIMED_THREAD_COUNT_FOR_USER_TOTAL from "../../graphql/query/UnclaimedThreadCountForUserTotal.graphql";
import ACTIVE_THREAD_COUNT_QUERY from "../../graphql/query/ActiveThreadCountQuery.graphql";

function refreshQuery(apolloClient, query, variables, field, callback) {
  try {
    const cachedData = apolloClient.readQuery({ query, variables });
    const data = {
      ...cachedData,
      [field]: callback(cachedData[field]),
    };

    apolloClient.writeQuery({ query, data, variables });
  } catch (e) {
    // console.error(e)
    // console.log('Refresh Query Not In Cache: ', e.message);
  }
}

function addOrUpdateItem(enumerable, item) {
  const itemIndex = enumerable.findIndex((i) => i.id === item.id);
  const enumerableCopy = enumerable.slice();

  if (itemIndex >= 0) {
    enumerableCopy.splice(itemIndex, 1, item);
  } else {
    enumerableCopy.push(item);
  }

  return enumerableCopy;
}

function addUpdateOrRemovePaginatedItem(
  cachedPaginatedData,
  item,
  remove = false,
  sortListFunc = (list) => list
) {
  const cachedDataCopy = {
    ...cachedPaginatedData,
    edges: [...cachedPaginatedData.edges],
  };

  const justNodes = cachedDataCopy.edges.map((edge) => edge.node);

  const newNodes = remove
    ? removeItem(justNodes, item)
    : addOrUpdateItem(justNodes, item);

  const sortedNodes = sortListFunc(newNodes);

  cachedDataCopy.edges = sortedNodes.map((node) => ({
    cursor: node.id,
    node,
    __typename: `${node.__typename}Edge`,
  }));

  return cachedDataCopy;
}

const isInternalFeatureFlagOn = (apolloClient, accountId) => {
  let accountData;
  try {
    accountData = apolloClient.readQuery({
      query: ACCOUNT_QUERY,
      variables: {
        id: accountId,
      },
    });
  } catch (e) {
    //
  }
  return !!accountData?.account?.ff_internal_threads;
};

const honorInternalThreads = (apolloClient, accountId) =>
  isInternalFeatureFlagOn(apolloClient, accountId);

const customerListIndexLookup = (edges, customerName) => {
  const names = edges.map((i) => {
    const { __typename } = i.node;
    return __typename === "CustomerContact"
      ? `${i.node.firstName} ${i.node.lastName}`
      : i.node.name;
  });

  return names.reduce((placementIndex, name, i) => {
    if (placementIndex !== -1) return placementIndex;
    return name.toLowerCase() < customerName.toLowerCase() ? placementIndex : i;
  }, -1);
};

function addOrUpdatePaginatedCustomer(customers, newOrEditedCustomer) {
  const { hasPreviousPage, hasNextPage } = customers.pageInfo;

  const itemIndex = customers.edges.findIndex(
    (customer) => customer.node.id === newOrEditedCustomer.id
  );

  const customersCopy = {
    ...customers,
    edges: [...customers.edges],
  };

  const formattedNewOrEditedCustomer = {
    node: newOrEditedCustomer,
    cursor: `${
      newOrEditedCustomer.__typename === "CustomerContact"
        ? "contact"
        : "account"
    }:${newOrEditedCustomer.id}`,
    __typename: "CustomerEdge",
  };

  const customerName = getCustomerName(newOrEditedCustomer);

  if (itemIndex >= 0) {
    // customer was edited, take out old customer
    customersCopy.edges.splice(itemIndex, 1);
  }

  const newIndex = customerListIndexLookup(customersCopy.edges, customerName);

  if (
    (!hasPreviousPage && newIndex === 0) || // will be the very first item in the list
    (newIndex !== -1 && newIndex !== 0) // is going into the middle of the list
  ) {
    customersCopy.edges.splice(newIndex, 0, formattedNewOrEditedCustomer);
  } else if (!hasNextPage && newIndex === -1) {
    // will be the very last item in the list
    customersCopy.edges.push(formattedNewOrEditedCustomer);
  }

  return customersCopy;
}

function workOrderListIndexLookup(edges, workOrderReferenceNumber) {
  const workOrderReferenceNumbers = edges.map((i) => i.node.referenceNumber);

  return workOrderReferenceNumbers.reduce((placementIndex, refNum, i) => {
    if (placementIndex !== -1) return placementIndex;
    return refNum.toLowerCase() < workOrderReferenceNumber.toLowerCase()
      ? placementIndex
      : i;
  }, -1);
}

function updatePaginatedWorkOrders(
  workOrders,
  newWorkOrder,
  originalWorkOrderId,
  checkIsDuplicate
) {
  const { hasPreviousPage, hasNextPage } = workOrders.pageInfo;

  const itemIndex = workOrders.edges.findIndex(
    (workOrder) => workOrder.node.id === originalWorkOrderId
  );

  const duplicateItemIndex = workOrders.edges.findIndex(
    (workOrder) => workOrder.node.id === newWorkOrder.id
  );

  // threads attached to work order amount
  const workOrdersCopy = {
    ...workOrders,
    edges: [...workOrders.edges],
  };

  if (itemIndex >= 0) {
    // work order was edited, remove old work order
    workOrdersCopy.edges.splice(itemIndex, 1);
  }

  if (duplicateItemIndex >= 0) {
    // work order was reassigned to existing work order
    checkIsDuplicate(true);
    workOrdersCopy.edges.splice(duplicateItemIndex, 1);
  }

  if (newWorkOrder === undefined) return workOrdersCopy; // if someone deletes a ref num we don't need to format and filter a new one

  const formattedNewWorkOrder = {
    node: newWorkOrder,
    cursor: `work_orders:${newWorkOrder.id}`,
    __typename: "WorkOrderEdge",
  };

  const newReferenceNumber = newWorkOrder.referenceNumber;

  const newIndex = workOrderListIndexLookup(
    workOrdersCopy.edges,
    newReferenceNumber
  );

  if (
    (!hasPreviousPage && newIndex === 0) || // will be the very first item in the list
    (newIndex !== -1 && newIndex !== 0)
  ) {
    // is going into the middle of the list
    workOrdersCopy.edges.splice(newIndex, 0, formattedNewWorkOrder);
  } else if (!hasNextPage && newIndex === -1) {
    // will be the last item in the list
    workOrdersCopy.edges.push(formattedNewWorkOrder);
  }

  return workOrdersCopy;
}

function removeItem(enumerable, item) {
  const itemIndex = enumerable.findIndex((i) => i.id === item.id);
  const enumerableCopy = enumerable.slice();

  if (itemIndex >= 0) {
    enumerableCopy.splice(itemIndex, 1);
  }

  return enumerableCopy;
}

function removeCustomer(customers, customerId) {
  const customerIndex = customers.edges.findIndex(
    (i) => i.node.id === customerId
  );
  const customersCopy = {
    ...customers,
    edges: [...customers.edges],
  };

  if (customerIndex >= 0) {
    customersCopy.edges.splice(customerIndex, 1);
  }

  return customersCopy;
}

const dispatchSetLatestClosedThread = ({ threads, thread, store }) => {
  // announcements immediately close on send, so we don't want to display snackbar for this use case.
  if (
    thread_utils.isAnnouncement(thread) &&
    !thread_utils.isAnnouncementReply(thread)
  )
    return;

  // only flag app if thread was not already in closed section.
  if (threads && !threads?.edges?.some((edge) => edge.node.id === thread?.id)) {
    // we need to flag the app to let it know that a thread was transferred to closed. Watch the flag for changes
    // elsewhere and call the snackbar when changes occur. Watching for this is now necessary after internal messaging
    // because the other party can now close the thread for the user.
    store.dispatch({
      type: "SET_LATEST_CLOSED_THREAD",
      latestClosedThread: thread,
    });
  }
};

// const isGroupSharedThread = (thread, threadsActiveGroupIds) => thread.groupShares.some(groupShare => threadsActiveGroupIds.some(groupId => groupId === groupShare.groupId));
// const isUserSharedThread = (thread, userId) => thread.userShares.some(userShare => userShare?.userId === userId);

const removeThreadFromEverywhereExcept = (
  except,
  { thread, currentUser, threadsActiveGroupIds, apolloClient }
) => {
  const { contactId, accountId } = currentUser;
  const includeInternal = honorInternalThreads(apolloClient, accountId);
  const {
    genMyInboxThreadsVars,
    genMyOpenThreadsVars,
    genAllOpenThreadsVars,
    genInboxSpamThreadsVars,
  } = threadsQueryVars();

  if (except !== "spam") {
    // remove from spam
    refreshQuery(
      apolloClient,
      INBOX_SPAM_THREADS,
      genInboxSpamThreadsVars({ threadsActiveGroupIds, includeInternal }),
      "inboxSpamThreads",
      (threads) => addUpdateOrRemovePaginatedItem(threads, thread, true)
    );
  }

  if (except !== "inbox") {
    // remove from inbox
    refreshQuery(
      apolloClient,
      MY_INBOX_THREADS,
      genMyInboxThreadsVars({ threadsActiveGroupIds, includeInternal }),
      "myInboxThreads",
      (threads) => addUpdateOrRemovePaginatedItem(threads, thread, true)
    );
  }

  if (except !== "myOpen") {
    // remove from myOpen
    refreshQuery(
      apolloClient,
      MY_OPEN_THREADS,
      genMyOpenThreadsVars({
        threadsActiveGroupIds,
        contactId,
        includeInternal,
      }),
      "myOpenThreads",
      (threads) => addUpdateOrRemovePaginatedItem(threads, thread, true)
    );
  }

  if (except !== "allOpen") {
    // remove from allOpen
    refreshQuery(
      apolloClient,
      ALL_OPEN_THREADS,
      genAllOpenThreadsVars({
        threadsActiveGroupIds,
        contactId,
        includeInternal,
      }),
      "allOpenThreads",
      (threads) => addUpdateOrRemovePaginatedItem(threads, thread, true)
    );
  }
};

const updateThreadIn = (
  where,
  { thread, currentUser, threadsActiveGroupIds, apolloClient, onThreadClose }
) => {
  const { contactId, accountId } = currentUser;
  const includeInternal = honorInternalThreads(apolloClient, accountId);
  const {
    genMyInboxThreadsVars,
    genMyOpenThreadsVars,
    genAllOpenThreadsVars,
    genMyClosedThreadVars,
    genAllClosedThreadVars,
    genInboxSpamThreadsVars,
  } = threadsQueryVars();

  if (where === "spam") {
    refreshQuery(
      apolloClient,
      INBOX_SPAM_THREADS,
      genInboxSpamThreadsVars({ threadsActiveGroupIds, includeInternal }),
      "inboxSpamThreads",
      (threads) =>
        addUpdateOrRemovePaginatedItem(threads, thread, false, (threads) =>
          threads.sort(
            (a, b) => new Date(b.insertedAt) - new Date(a.insertedAt)
          )
        )
    );
  }

  if (where === "inbox") {
    refreshQuery(
      apolloClient,
      MY_INBOX_THREADS,
      genMyInboxThreadsVars({ threadsActiveGroupIds, includeInternal }),
      "myInboxThreads",
      (threads) =>
        addUpdateOrRemovePaginatedItem(threads, thread, false, (threads) =>
          threads.sort(
            (a, b) => new Date(b.insertedAt) - new Date(a.insertedAt)
          )
        )
    );
  }

  if (where === "myOpen") {
    refreshQuery(
      apolloClient,
      MY_OPEN_THREADS,
      genMyOpenThreadsVars({
        threadsActiveGroupIds,
        contactId,
        includeInternal,
      }),
      "myOpenThreads",
      (threads) =>
        addUpdateOrRemovePaginatedItem(threads, thread, false, (threads) =>
          threads.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        )
    );
  }

  if (where === "allOpen") {
    refreshQuery(
      apolloClient,
      ALL_OPEN_THREADS,
      genAllOpenThreadsVars({
        threadsActiveGroupIds,
        contactId,
        includeInternal,
      }),
      "allOpenThreads",
      (threads) =>
        addUpdateOrRemovePaginatedItem(threads, thread, false, (threads) =>
          threads.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        )
    );
  }

  if (where === "myClosed") {
    refreshQuery(
      apolloClient,
      MY_CLOSED_THREADS,
      genMyClosedThreadVars({
        threadsActiveGroupIds,
        contactId,
        includeInternal,
      }),
      "myClosedThreads",
      (threads) => {
        onThreadClose(threads);
        return addUpdateOrRemovePaginatedItem(
          threads,
          thread,
          false,
          (threads) =>
            threads.sort(
              (a, b) => new Date(b.archivedAt) - new Date(a.archivedAt)
            )
        );
      }
    );
  }

  if (where === "allClosed") {
    refreshQuery(
      apolloClient,
      ALL_CLOSED_THREADS,
      genAllClosedThreadVars({
        threadsActiveGroupIds,
        contactId,
        includeInternal,
      }),
      "allClosedThreads",
      (threads) => {
        onThreadClose(threads);
        return addUpdateOrRemovePaginatedItem(
          threads,
          thread,
          false,
          (threads) =>
            threads.sort(
              (a, b) => new Date(b.archivedAt) - new Date(a.archivedAt)
            )
        );
      }
    );
  }

  // refresh query for individual thread
  refreshQuery(
    apolloClient,
    threadQuery,
    { id: thread.id },
    "thread",
    () => thread
  );
};

export function refreshThreadQueries(apolloClient, thread, store) {
  // keep interal threads from getting to the mobile app through subscriptions
  // if (thread.type === 'internal' && process.env.PLATFORM !== 'web') return;

  const { currentUser, threadsActiveGroupIds } = store.getState().session;
  const { account } = store.getState().accountData;

  // directly refresh thread in apollo store, might want to
  // make this more fine grained instead of totally replacing thread.
  apolloClient.writeFragment({
    id: thread.id,
    fragment: threadFragment,
    fragmentName: "ThreadFragment",
    data: thread,
  });

  const where = thread_utils.whereDoesThisThreadGo(
    thread,
    currentUser,
    threadsActiveGroupIds,
    account.ff_spam_filtering
  );
  const onThreadClose = (threads) =>
    dispatchSetLatestClosedThread({ threads, thread, store });

  // whereDoesThisThreadGo will return null if the thread isn't a part of the user's UI in their current state,
  // we used to have an if statement that would check that where was truthy before triggering these update/remove functions,
  // but with the inbox-transfer feature it ended up being helpful to remove threads that once belonged to a user but no longer do.
  removeThreadFromEverywhereExcept(where, {
    thread,
    currentUser,
    threadsActiveGroupIds,
    apolloClient,
  });
  updateThreadIn(where, {
    thread,
    currentUser,
    threadsActiveGroupIds,
    apolloClient,
    onThreadClose,
  });
}

export function refreshMessageQueries(apolloClient, message) {
  refreshQuery(
    apolloClient,
    threadHistoryQuery,
    { threadId: message?.threadId },
    "threadHistory",
    (threadHistory) => addOrUpdateItem(threadHistory, message)
  );
}

export function refreshCustomerAccountQueries(apolloClient, customerAccount) {
  refreshQuery(apolloClient, customersQuery, {}, "customers", (customers) =>
    addOrUpdatePaginatedCustomer(customers, customerAccount)
  );
}

export function removeCustomerAccount(apolloClient, customerAccountId) {
  refreshQuery(apolloClient, customersQuery, {}, "customers", (customers) =>
    removeCustomer(customers, customerAccountId)
  );
}

export function refreshCustomerContactQueries(
  apolloClient,
  customerContact,
  store
) {
  const { activeCustomerSource } = store.getState().customerInfo;
  // let pageInfo = {};
  try {
    // pageInfo = apolloClient.readQuery({ query: customersQuery, variables: {} }).customers;
  } catch (e) {
    console.error(e);
  }

  refreshQuery(apolloClient, customersQuery, {}, "customers", (customers) => {
    if (activeCustomerSource === "added" || activeCustomerSource === "edited")
      return customers;
    return addOrUpdatePaginatedCustomer(customers, customerContact);
  });
}

export function refreshWorkOrdersQueries(
  apolloClient,
  newWorkOrder,
  variables,
  originalWorkOrderId,
  checkIsDuplicate = () => {}
) {
  refreshQuery(
    apolloClient,
    WORK_ORDERS_QUERY,
    variables,
    "workOrders",
    (workOrders) =>
      updatePaginatedWorkOrders(
        workOrders,
        newWorkOrder,
        originalWorkOrderId,
        checkIsDuplicate
      )
  );
}

export function refreshWorkOrdersCount(apolloClient, variables, updateMethod) {
  refreshQuery(
    apolloClient,
    WORK_ORDER_COUNT_QUERY,
    variables,
    "workOrderCount",
    (workOrdersCount) => {
      switch (updateMethod) {
        case "add":
          return workOrdersCount + 1;
        case "delete":
          return workOrdersCount - 1;
        case "update":
          return workOrdersCount;
        default:
          return workOrdersCount;
      }
    }
  );
}

export function removeCustomerContact(apolloClient, customerContactId, store) {
  const { currentUser, threadsActiveGroupIds } = store.getState().session;
  const { contactId, accountId } = currentUser;

  const includeInternal = honorInternalThreads(apolloClient, accountId);

  const {
    genMyInboxThreadsVars,
    genMyOpenThreadsVars,
    genAllOpenThreadsVars,
    genMyClosedThreadVars,
    genAllClosedThreadVars,
  } = threadsQueryVars();

  const filterThreads = (threads) => {
    const filteredThreadEdges = threads.edges.filter(
      (edge) => edge.node.externalContact.id !== customerContactId
    );
    return {
      ...threads,
      edges: filteredThreadEdges,
    };
  };

  // Remove customer threads from active threads query.
  refreshQuery(
    apolloClient,
    MY_OPEN_THREADS,
    genMyOpenThreadsVars({ threadsActiveGroupIds, contactId, includeInternal }),
    "myOpenThreads",
    filterThreads
  );

  // remove customer threads from inbox
  refreshQuery(
    apolloClient,
    MY_INBOX_THREADS,
    genMyInboxThreadsVars({ threadsActiveGroupIds, includeInternal }),
    "myInboxThreads",
    filterThreads
  );

  // remove customer threads from "all" section
  refreshQuery(
    apolloClient,
    ALL_OPEN_THREADS,
    genAllOpenThreadsVars({
      threadsActiveGroupIds,
      contactId,
      includeInternal,
    }),
    "allOpenThreads",
    filterThreads
  );

  // remove customer threads from closed section
  refreshQuery(
    apolloClient,
    MY_CLOSED_THREADS,
    genMyClosedThreadVars({
      threadsActiveGroupIds,
      contactId,
      includeInternal,
    }),
    "myClosedThreads",
    filterThreads
  );

  // remove from threads for user
  refreshQuery(
    apolloClient,
    ALL_CLOSED_THREADS,
    genAllClosedThreadVars({
      threadsActiveGroupIds,
      contactId,
      includeInternal,
    }),
    "allClosedThreads",
    filterThreads
  );

  // Remove customer from the customers query.
  refreshQuery(apolloClient, customersQuery, {}, "customers", (customers) =>
    removeCustomer(customers, customerContactId)
  );

  // remove customer from recent customers query?
  refreshQuery(
    apolloClient,
    recentCustomersQuery,
    {},
    "recentCustomers",
    (customers) => removeCustomer(customers, customerContactId)
  );
}

export function refreshUserQueries(apolloClient, user) {
  const userContact = apolloClient.readFragment({
    id: "UserContact_" + user.contactId,
    fragment: userContactFragment,
    fragmentName: "UserContactFragment",
  });

  if (userContact) {
    const updatedUserContact = {
      ...userContact,
      firstName: user.firstName,
      lastName: user.lastName,
    };

    apolloClient.writeFragment({
      id: "UserContact_" + updatedUserContact.id,
      fragment: userContactFragment,
      data: updatedUserContact,
      fragmentName: "UserContactFragment",
    });
  }

  refreshQuery(apolloClient, usersQuery, {}, "users", (users) =>
    addOrUpdateItem(users, user)
  );
}

export const refreshActivityQuery = (
  apolloClient,
  response,
  queryVariables
) => {
  const activity = response.data.activityCreated;

  refreshQuery(
    apolloClient,
    ACTIVITIES_QUERY,
    {
      ...queryVariables,
    },
    "activities",
    (activities) => {
      const mockedPaginatedActivity = {
        cursor: uuidv4(),
        node: {
          ...activity,
        },
        __typename: "ActivityEdge",
      };

      const activitiesEdgesCopy = [...activities.edges];

      activitiesEdgesCopy.unshift(mockedPaginatedActivity);

      const activitiesCopy = {
        ...activities,
        edges: activitiesEdgesCopy,
      };

      return activitiesCopy;
    }
  );
};

export const refreshUnclaimedThreadCountTotalQuery = (
  apolloClient,
  response,
  variables
) => {
  const { data } = response;
  refreshQuery(
    apolloClient,
    UNCLAIMED_THREAD_COUNT_FOR_USER_TOTAL,
    variables,
    "unclaimedThreadCountForUserTotal",
    (prevData) => {
      return {
        __typename: "UnclaimedThreadCountForUserTotalPayload",
        count: prevData.count + data.unclaimedThreadCountForUserTotal,
        errors: null,
      };
    }
  );
};

export const refreshUnclaimedThreadCountByGroupQuery = (
  apolloClient,
  response,
  variables
) => {
  const { data } = response;

  const incomingThreadId = data?.unclaimedThreadCountForUserByGroup?.threadId;
  const incomingChange = data?.unclaimedThreadCountForUserByGroup?.change;
  const incomingInsertedAt =
    data?.unclaimedThreadCountForUserByGroup?.threadInsertedAt;
  const groupId = data?.unclaimedThreadCountForUserByGroup?.groupId;

  refreshQuery(
    apolloClient,
    UNCLAIMED_THREAD_COUNT_FOR_USER_BY_GROUP,
    variables,
    "unclaimedThreadCountForUserByGroup",
    (prevData) => {
      const updatedGroup = prevData.counts.find(
        (count) => count.groupId === groupId
      );
      const currGroupIncludesUpdatedThread = updatedGroup?.rawThreadData?.some(
        (thread) => thread.threadId === incomingThreadId
      );
      let newCounts;
      if (currGroupIncludesUpdatedThread) {
        // if incoming threadId is included in prev payload, remove it
        newCounts = [
          ...prevData.counts.filter((count) => count.groupId !== groupId),
          {
            ...updatedGroup,
            numUnclaimedThreads:
              updatedGroup.numUnclaimedThreads + incomingChange,
            rawThreadData: [
              ...updatedGroup.rawThreadData.filter(
                (thread) => thread.threadId !== incomingThreadId
              ),
            ],
          },
        ];
      } else {
        newCounts = [
          ...prevData.counts.filter((count) => count.groupId !== groupId),
          {
            ...updatedGroup,
            numUnclaimedThreads:
              updatedGroup.numUnclaimedThreads + incomingChange,
            rawThreadData: [
              ...updatedGroup.rawThreadData,
              {
                threadId: incomingThreadId,
                insertedAt: incomingInsertedAt,
                __typename: "UnclaimedThreadRawData",
              },
            ],
          },
        ];
      }

      const returnedData = {
        __typename: "UnclaimedThreadCountByGroupPayload",
        errors: null,
        counts: newCounts,
      };

      return returnedData;
    }
  );
};

export const refreshUnclaimedThreadCountForUserByTimeQuery = (
  apolloClient,
  response,
  variables
) => {
  const {
    data: { unclaimedThreadCountForUserByGroup },
  } = response;
  // data: unclaimedThreadCountForUserByGroup{groupId, threadId, change, insertedAt}
  // groupId is assumed
  // because this sub is for single group dashboard only
  const incomingThreadId = unclaimedThreadCountForUserByGroup.threadId;
  const incomingChange = unclaimedThreadCountForUserByGroup.change;
  const incomingInsertedAt =
    unclaimedThreadCountForUserByGroup.threadInsertedAt;
  refreshQuery(
    apolloClient,
    UNCLAIMED_THREAD_COUNT_FOR_USER_BY_GROUP,
    variables,
    "unclaimedThreadCountForUserByGroup",
    (prevData) => {
      const currentGroupCounts = prevData.counts[0]; // can assume 0 index group because this is for single group dashboard
      const currGroupIncludesUpdatedThread =
        currentGroupCounts.rawThreadData.some(
          (thread) => thread.threadId === incomingThreadId
        );
      let newCounts;
      if (currGroupIncludesUpdatedThread) {
        // if incoming threadId is included in prev payload, remove it
        newCounts = [
          {
            ...currentGroupCounts,
            numUnclaimedThreads:
              currentGroupCounts.numUnclaimedThreads + incomingChange,
            rawThreadData: [
              ...currentGroupCounts.rawThreadData.filter(
                (thread) => thread.threadId !== incomingThreadId
              ),
            ],
          },
        ];
      } else {
        // if incoming threadId is not included, add it
        newCounts = [
          {
            ...currentGroupCounts,
            numUnclaimedThreads:
              currentGroupCounts.numUnclaimedThreads + incomingChange,
            rawThreadData: [
              ...currentGroupCounts.rawThreadData,
              {
                insertedAt: incomingInsertedAt,
                threadId: incomingThreadId,
                __typename: "UnclaimedThreadRawData",
              },
            ],
          },
        ];
      }
      const returnedData = {
        __typename: "UnclaimedThreadCountByGroupPayload",
        errors: null,
        counts: newCounts,
      };
      return returnedData;
    }
  );
};

export const refreshOpenThreadCountTotalQuery = (
  apolloClient,
  response,
  variables,
  isCurrentUserAdmin
) => {
  const { data } = response;
  const isAdminThread = data?.openThreadCountForUserTotal?.isAdminThread;

  refreshQuery(
    apolloClient,
    OPEN_THREAD_COUNT_FOR_USER_TOTAL,
    variables,
    "openThreadCountForUserTotal",
    (prevData) => {
      let count = prevData.count;
      if (!isAdminThread || isCurrentUserAdmin) {
        count += data.openThreadCountForUserTotal.change;
      }

      return {
        __typename: "OpenThreadCountForUserTotalPayload",
        count,
        errors: null,
      };
    }
  );
};

export const refreshOpenThreadCountByGroupQuery = (
  apolloClient,
  response,
  variables,
  isCurrentUserAdmin
) => {
  const { data } = response;
  const changedCountData = data?.openThreadCountForUserByGroup;
  const isAdminThread = data?.isAdminThread;
  refreshQuery(
    apolloClient,
    OPEN_THREAD_COUNT_FOR_USER_BY_GROUP,
    variables,
    "openThreadCountForUserByGroup",
    (prevData) => {
      const updatedGroup = prevData.counts.find(
        (count) => count.groupId === changedCountData?.groupId
      );

      let newCounts;
      if (!isAdminThread || isCurrentUserAdmin) {
        newCounts = [
          ...prevData.counts.filter(
            (count) => count.groupId !== changedCountData.groupId
          ),
          {
            ...updatedGroup,
            numOpenThreads:
              updatedGroup.numOpenThreads + changedCountData.change,
          },
        ].sort((a, b) => {
          if (a.numOpenThreads < b.numOpenThreads) {
            return 1;
          }
          if (a.numOpenThreads > b.numOpenThreads) {
            return -1;
          }
          return 0;
        });
      } else {
        newCounts = prevData?.counts;
      }

      return {
        __typename: "OpenThreadCountByGroupPayload",
        errors: null,
        counts: newCounts,
      };
    }
  );
};

// Note: This is no longer being actively used in the application since
// subscriptions were removed from the dashboard.
export const refreshOpenThreadCountByUserQuery = (
  apolloClient,
  response,
  variables
) => {
  const { data } = response;
  const changedCountData = data?.openThreadCountForUserByUser;

  refreshQuery(
    apolloClient,
    OPEN_THREAD_COUNT_FOR_USER_BY_USER,
    variables,
    "openThreadCountForUserByUser",
    (prevData) => {
      const updatedUser = prevData.counts.find(
        (count) => count.userId === changedCountData?.userId
      );
      let newCounts;
      if (updatedUser) {
        newCounts = [
          ...prevData.counts.filter(
            (count) => count.userId !== changedCountData.userId
          ),
          {
            ...updatedUser,
            numOpenThreads:
              updatedUser.numOpenThreads + changedCountData.change,
          },
        ].sort((a, b) => {
          if (a.numOpenThreads < b.numOpenThreads) {
            return 1;
          }
          if (a.numOpenThreads > b.numOpenThreads) {
            return -1;
          }
          return 0;
        });
      } else {
        newCounts = prevData?.counts;
      }

      const returnedData = {
        __typename: "OpenThreadCountByUserPayload",
        errors: null,
        counts: newCounts,
      };

      return returnedData;
    }
  );
};

// Note: This is no longer being actively used in the application since
// subscriptions were removed from the dashboard.
export const refreshActiveThreadCountQuery = (
  apolloClient,
  response,
  variables,
  isCurrentUserAdmin
) => {
  const { data } = response;
  const isAdminThread = data?.isAdminThread;
  refreshQuery(
    apolloClient,
    ACTIVE_THREAD_COUNT_QUERY,
    variables,
    "activeThreadCount",
    (prevData) => {
      const newData = { ...prevData };

      if (!isAdminThread || isCurrentUserAdmin) {
        const category = data.activeThreadCountForUser.category;
        const currentValue =
          newData[data.activeThreadCountForUser.category] || 0;
        const newValue = currentValue + data.activeThreadCountForUser.change;
        newData[category] = newValue;
      }

      return {
        __typename: "ActiveThreadCountPayload",
        ...newData,
      };
    }
  );
};

import React, { useState } from 'react';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { useSelector } from 'react-redux';
import type { AppState, LabelOption } from '../../utils/helpers/types';
import { ContactNamedStatus } from '../../components/Customers/helpers/types';
import type { OptionType, SingleOrMultiValue } from '../Select/shared/types.ts';
import {
  getContactTypeOption,
  getContactTypeOptions,
} from '../../components/Customers/helpers/labelOptionUtils';
import FilterBy from './FilterBy';
import { Select, SelectDate, InputLabel, Tooltip } from '../index';
import THEMES from '../../styles/themes/app.js';
import GroupAsyncSelect from '../../components/AsyncSelects/GroupAsyncSelect.js';
import UserAsyncSelect from '../../components/AsyncSelectPaginates/UserAsyncSelect.tsx';
import TagsAsyncSelectPaginate from '../../components/AsyncSelectPaginates/TagsAsyncSelectPaginate.tsx';
import LabelAsyncSelect from '../../components/AsyncSelectPaginates/LabelAsyncSelect.tsx';

const ContactFilterRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  margin-bottom: 16px;
`;

const HalfRow = styled.div`
  width: 100%;
  display: flex;
`;

const Row = styled.div`
  width: 100%;
  margin-top: 3%;
`;

const InfoWrapper = styled.div`
  margin-left: 4px;
  font-size: 16px;
  display: flex;
`;

const LabelRow = styled.div`
  display: flex;
  align-items: center;
`;

const TooltipContent = styled.div`
  overflow-wrap: break-word;
  max-width: 250px;
  overflow: hidden;
  color: ${THEMES.FOREGROUND_HIGH};
`;

export const calculateActiveFilters = (
  filtersArr: (number | string | null | undefined)[]
): number => {
  const totalFilters = filtersArr.reduce(
    (acc: number, curr: number | string | null | undefined) => {
      return acc + (curr ? 1 : 0);
    },
    0
  );
  return totalFilters;
};

type LastMessageDateOptions = {
  relativeDate: OptionType | null;
  direction: OptionType | null;
  date: string;
};

export interface FilterVariables {
  accountRepList: OptionType | null;
  contactType: OptionType;
  groupList: OptionType[] | [];
  labelList: Partial<LabelOption>[] | [];
  lastMessageDate: LastMessageDateOptions;
  numFilters: number;
  tagList: OptionType[];
}

export const defaultFilterInputState: FilterVariables = {
  accountRepList: null,
  contactType: getContactTypeOption(i18n, ContactNamedStatus.All),
  groupList: [],
  labelList: [],
  lastMessageDate: {
    relativeDate: null,
    direction: null,
    date: '',
  },
  numFilters: 0,
  tagList: [],
};

export enum SelectedInputs {
  AccountRep = 'account_rep',
  ContactType = 'contact_type',
  Groups = 'groups',
  Labels = 'labels',
  LastMessageDate = 'last_message_date',
  Tags = 'tags',
}

export type ContactsQueryFilters = {
  groupIds?: string[];
  labelIds?: (string | undefined)[];
  accountRepId?: string | null;
  contactNamedStatus?: string | null;
  filterBy?: string;
  workOrderIds?: string[] | null;
};

export interface ContactsFilterByProps {
  handleApplyFilters: (filters: ContactsQueryFilters) => void;
  selectedInputs?: SelectedInputs[];
  bubbleProps?: Record<string, string[] | string | number | boolean | null>;
  dataTestId?: string;
}

const ContactsFilterBy = ({
  handleApplyFilters,
  dataTestId,
  bubbleProps,
  selectedInputs = [
    SelectedInputs.ContactType,
    SelectedInputs.Groups,
    SelectedInputs.Labels,
    SelectedInputs.AccountRep,
    SelectedInputs.LastMessageDate,
    SelectedInputs.Tags,
  ], // only specify default selectedInput value if filter available on the CONTACTS_QUERY
}: ContactsFilterByProps) => {
  const [filterInputState, setFilterInputState] = useState(
    defaultFilterInputState
  );
  const {
    accountRepList,
    contactType,
    groupList,
    labelList,
    lastMessageDate,
    numFilters,
    tagList,
  } = filterInputState;
  const [filterByOpen, setFilterByOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({
    relativeDate: false,
    direction: false,
    date: false,
  });

  const groupIds = useSelector(
    (state: AppState) => state.session?.currentUser?.groupIds
  );
  const ff_contact_labels = useSelector(
    (state: AppState) => state.accountData.account?.ff_contact_labels
  );
  const ff_smart_tags = useSelector(
    (state: AppState) => state.accountData.account?.ff_smart_tags
  );

  const setAccountRepList = (val: OptionType | null) => {
    setFilterInputState((prev) => ({
      ...prev,
      accountRepList: val,
    }));
  };

  const setGroupList = (val: OptionType[]) => {
    setFilterInputState((prev) => ({
      ...prev,
      groupList: val,
    }));
  };

  const setLabelList = (val: SingleOrMultiValue) => {
    setFilterInputState((prev) => ({
      ...prev,
      labelList: val as Partial<LabelOption>[],
    }));
  };

  const setContactType = (val: OptionType) => {
    setFilterInputState((prev) => ({
      ...prev,
      contactType: val,
    }));
  };

  const setLastMessageDate = (val: LastMessageDateOptions) => {
    setFilterInputState((prev) => ({
      ...prev,
      lastMessageDate: val,
    }));
  };

  const setTagList = (val: OptionType[]) => {
    setFilterInputState((prev) => ({
      ...prev,
      tagList: val,
    }));
  };

  const validateLastMessage = () => {
    const { relativeDate, direction, date } = lastMessageDate;

    if (relativeDate || direction || date) {
      setFormErrors({
        relativeDate: !relativeDate,
        direction: !direction,
        date: !date,
      });
      return !(relativeDate && direction && date);
    }
    return false;
  };

  const handleSubmitFilter = () => {
    const activeFilters = [
      groupList?.length,
      accountRepList?.value,
      labelList?.length,
      tagList?.length,
      contactType.value !== ContactNamedStatus.All ? contactType.value : null,
    ];

    if (
      Object.values(lastMessageDate).every(
        (value) => value !== null && value !== undefined && value !== ''
      )
    ) {
      activeFilters.push(lastMessageDate.date);
    }

    const countActiveFilters = calculateActiveFilters(activeFilters);

    const hasAnyLastMessageSet = Object.values(lastMessageDate).some(
      (value) => value !== null && value !== undefined && value !== ''
    );

    if (hasAnyLastMessageSet && validateLastMessage()) {
      return;
    }

    const conditionalCustomerContactType = {
      ...(contactType.value !== ContactNamedStatus.All && {
        type: 'CUSTOMER_CONTACT',
      }),
    };

    const lastMessageFilter = {
      messageDirection: lastMessageDate?.direction?.value,
      date: lastMessageDate?.date,
      dateRelationship: lastMessageDate?.relativeDate?.value,
    };

    const filterVariables: ContactsQueryFilters = {
      groupIds: groupList?.map((group) => group.value) || [],
      labelIds: labelList?.map((label) => label.value) || [],
      workOrderIds: tagList?.map((tag) => tag.value) || [],
      contactNamedStatus: contactType.value,
      ...conditionalCustomerContactType,
      ...(hasAnyLastMessageSet && { lastMessageFilter }),
    };

    if (accountRepList?.value) {
      filterVariables.accountRepId = accountRepList.value;
    } // This is to avoid sending accountRepList = null to the backend which results in unexpected behavior

    handleApplyFilters(filterVariables);
    setFilterInputState((prev) => ({
      ...prev,
      numFilters: countActiveFilters,
    }));
    setFilterByOpen(false);
  };

  const handleCancelFilter = () => {
    setFilterByOpen(false);
  };

  const handleClearAll = () => {
    // clear all state inside filterBy component
    setFilterInputState((prev) => ({
      ...defaultFilterInputState,
      numFilters: prev.numFilters,
    }));
    setFormErrors({
      relativeDate: false,
      direction: false,
      date: false,
    });
  };

  const relativeDateOptions = [
    { label: i18n.t('customers-filterBy-relativeDate-on'), value: 'on' },
    {
      label: i18n.t('customers-filterBy-relativeDate-before'),
      value: 'before',
    },
    { label: i18n.t('customers-filterBy-relativeDate-after'), value: 'after' },
  ];

  const directionOptions = [
    {
      label: i18n.t('customers-filterBy-messageDirection-sent'),
      value: 'sent',
    },
    {
      label: i18n.t('customers-filterBy-messageDirection-received'),
      value: 'received',
    },
    {
      label: i18n.t('customers-filterBy-messageDirection-allMessages'),
      value: 'all',
    },
  ];

  const tooltipContent = (
    <TooltipContent>
      {i18n.t('customers-filterBy-lastMessageDate-tooltip', {
        defaultValue:
          'Filter contacts based on their most recent message. Choose to filter by messages received by the contact from any Prokeep user, messages they’ve sent, or all messages. Then specify whether the message date is before, after, or on a specific date.',
      })}
    </TooltipContent>
  );

  return (
    <FilterBy
      onSubmit={handleSubmitFilter}
      clearAllFilters={handleClearAll}
      onCancel={handleCancelFilter}
      isOpen={filterByOpen}
      setIsOpen={setFilterByOpen}
      activeFiltersNum={numFilters}
      bubbleProps={{
        moveBubbleRightVal: 240,
        top: '12px',
        ...bubbleProps,
      }}
      dataTestId={dataTestId}
    >
      {selectedInputs.includes(SelectedInputs.ContactType) && (
        <ContactFilterRow>
          <InputLabel>
            {i18n.t('contacts-filter-contactType', {
              defaultValue: 'Contact Type',
            })}
          </InputLabel>
          <Select
            key="customers-filterBy-contactType"
            onChange={(selectedOption: OptionType) =>
              setContactType(selectedOption)
            }
            options={getContactTypeOptions(i18n)}
            value={contactType}
            hideBottomSpace
          />
        </ContactFilterRow>
      )}
      {selectedInputs.includes(SelectedInputs.Groups) && (
        <ContactFilterRow>
          <GroupAsyncSelect
            omitAll
            dataTestId="create-broadcastList-groupSelector"
            value={groupList}
            setValue={setGroupList}
            label={i18n.t('settings-TeamMemberForm-groups', {
              defaultValue: 'Groups',
            })}
            isCheckable
            isMulti
            error={null}
            omitIds={[]}
            hideBottomSpace
            menuPlacement="bottom"
          />
        </ContactFilterRow>
      )}
      {!!ff_contact_labels &&
        selectedInputs.includes(SelectedInputs.Labels) && (
          <ContactFilterRow>
            <LabelAsyncSelect
              value={labelList as OptionType[]}
              setValue={setLabelList}
              hideBottomSpace
            />
          </ContactFilterRow>
        )}
      {ff_smart_tags && selectedInputs.includes(SelectedInputs.Tags) && (
        <ContactFilterRow>
          <TagsAsyncSelectPaginate
            dataTestId="create-broadcastList-tagSelector"
            value={tagList}
            setValue={setTagList}
            placeholder=""
            label="Tags"
            isMulti
            isClearable
            hideBottomSpace
          />
        </ContactFilterRow>
      )}
      {selectedInputs.includes(SelectedInputs.AccountRep) && (
        <ContactFilterRow>
          <UserAsyncSelect
            value={accountRepList ?? ''}
            setValue={setAccountRepList}
            isClearable={!!accountRepList?.value} // this is to hide the X button when no value is selected
            onClear={() => setAccountRepList(null)}
            error=""
            queryVars={{
              groupIds: groupIds ?? [],
              includeAccountScopedUsers: true,
            }}
            label={i18n.t('settings-TeamMemberForm-accountRep', {
              defaultValue: 'Account Rep',
            })}
            hideBottomSpace
            hideAvatar
            placeholder=""
          />
        </ContactFilterRow>
      )}
      {selectedInputs.includes(SelectedInputs.LastMessageDate) && (
        <ContactFilterRow>
          <LabelRow>
            <InputLabel>
              {i18n.t('customers-filterBy-lastMessageDate', {
                defaultValue: 'Last Message Date',
              })}
            </InputLabel>
            <Tooltip place="top" elementsContent={tooltipContent}>
              <InfoWrapper data-tip>
                <i className="ri-information-line" />
              </InfoWrapper>
            </Tooltip>
          </LabelRow>
          <HalfRow>
            <Select
              key="customers-filterBy-messageDirection"
              options={relativeDateOptions}
              value={lastMessageDate.relativeDate}
              onChange={(val: OptionType) => {
                setLastMessageDate({
                  relativeDate: val,
                  direction: lastMessageDate.direction
                    ? lastMessageDate.direction
                    : {
                        label: i18n.t(
                          'customers-filterBy-messageDirection-allMessages'
                        ),
                        value: 'all',
                      },
                  date: lastMessageDate.date,
                });
                setFormErrors({
                  relativeDate: false,
                  direction: formErrors.direction,
                  date: formErrors.date,
                });
              }}
              customOptionStyle={() => `text-transform: none;`}
              error={formErrors.relativeDate}
              hideBottomSpace
            />
            <Select
              key="customers-filterBy-lastMessage"
              options={directionOptions}
              value={lastMessageDate.direction}
              onChange={(val: OptionType) => {
                setLastMessageDate({
                  relativeDate: lastMessageDate.relativeDate,
                  direction: val,
                  date: lastMessageDate.date,
                });
                setFormErrors({
                  relativeDate: formErrors.relativeDate,
                  direction: false,
                  date: formErrors.date,
                });
              }}
              customOptionStyle={() => 'text-transform: none;'}
              error={formErrors.direction}
              customContainerStyle={() => 'margin-left: 3%'}
              hideBottomSpace
            />
          </HalfRow>
          <Row>
            <SelectDate
              dataTestId="upsert-task-date-input"
              placeholder={i18n.t('tasks-create-formDate')}
              value={lastMessageDate.date}
              closeCalendarOnChange
              customCalendarStyle={() => `
                  bottom: 100%;
                  margin-bottom: 8px;
                `}
              maxDate={new Date()}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                setLastMessageDate({
                  relativeDate: lastMessageDate.relativeDate,
                  direction: lastMessageDate.direction
                    ? lastMessageDate.direction
                    : {
                        label: i18n.t(
                          'customers-filterBy-messageDirection-allMessages'
                        ),
                        value: 'all',
                      },
                  date: ev.target.value,
                });
                setFormErrors({
                  relativeDate: formErrors.relativeDate,
                  direction: formErrors.direction,
                  date: false,
                });
              }}
              calendarProps={{ maxDate: new Date(), minDate: null }}
              max="9999-12-31"
              allowPlaceholder
              error={formErrors.date}
              hideBottomSpace
              isRequired
            />
          </Row>
        </ContactFilterRow>
      )}
    </FilterBy>
  );
};

export default ContactsFilterBy;

import { PK_TYPENAMES } from 'client-lib';
import i18n from 'i18n-js';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import {
  TableHead,
  Button,
  useMultiSelectTableItems,
  MultiSelectTableItems,
  Table,
} from '../../../elements/index.ts';
import CommonTableRow from '../shared/AudienceBuilderTables/subComponents/CommonTableRow';
import {
  FlexCenterContainer,
  FlexBetweenContainer,
  EmptyListHeader,
  MediumHeader,
  SmallHeader,
} from '../shared/sharedStyles.ts';
import AudienceFilterSort from '../shared/AudienceBuilderTables/subComponents/AudienceFilterSort';
import {
  getSortOptions,
  getOptInStatusText,
  addSelectedDataTestId,
} from '../shared/utils';
import InfiniteScroll from '../../InfiniteScroll/InfiniteScroll.js';
import THEMES from '../../../styles/themes/app.js';
import {
  SMS_STATUS,
  type CustomerContact,
  type PageInfo,
} from '../../../utils/helpers/types.ts';
import {
  ANNOUNCEMENT_TYPES,
  BROADCAST_LIST,
  BULK_TASK,
  type BROADCAST_AUDIENCE_BUILDER_TYPES,
} from '../groupMessage/types.ts';
import type { ContactsQueryFilters } from '../../../elements/FilterBy/ContactsFilterBy.tsx';
import {
  shouldItemBeChecked,
  determineAllItemsSelected,
  determineIndeterminateState,
  type HandleAudienceSelectionParams,
} from './helpers/utils';
import type { SelectedAudience } from './types.ts';

interface TaskContactsTableProps {
  contacts: CustomerContact[];
  selectedAudience: SelectedAudience;
  loading: boolean;
  loadMoreRows: () => void;
  onAdd: (args: HandleAudienceSelectionParams) => void;
  onFilter: (filters: ContactsQueryFilters) => void;
  onSort: (args: { label: string; value: string }) => void;
  pageInfo: PageInfo;
  sort: {
    label: string;
    value: string;
  };
  heightDifference?: number;
  disableCheckboxes: boolean;
  hasLabelsFF: boolean;
  type: BROADCAST_AUDIENCE_BUILDER_TYPES;
  noResultsCustomTranslation?: string;
  noResultsCustomTranslationDefault?: string;
  allSelectableContactIds?: Array<string>;
}

const TaskContactsTable = ({
  contacts,
  selectedAudience,
  loading,
  loadMoreRows,
  onAdd,
  onFilter,
  onSort,
  pageInfo,
  sort,
  heightDifference = 354,
  disableCheckboxes = false,
  hasLabelsFF = false,
  type = BROADCAST_LIST.LIST,
  noResultsCustomTranslation = '',
  noResultsCustomTranslationDefault = '',
  allSelectableContactIds = [],
}: TaskContactsTableProps) => {
  const [contactIds, setContactIds] = useState<string[]>([]);
  const [companyIds, setCompanyIds] = useState<string[]>([]);
  const [optedInContacts, setOptedInContacts] = useState<CustomerContact[]>([]);

  const sortOptions = getSortOptions(i18n);

  const { handleCheckItem, handleSelectNone, selectedItems, setSelectItems } =
    useMultiSelectTableItems({
      items: optedInContacts,
      paginated: {
        totalContacts: pageInfo?.totalCount || 0,
      },
    });

  useEffect(() => {
    if (selectedAudience.selectAll) {
      setSelectItems(optedInContacts.map((item) => item.id));
    }
  }, [selectedAudience.selectAll, optedInContacts, setSelectItems]);

  /**
   * Check if a given contact should be allowed to be added to the list
   * @returns true if contact is able to be added to list
   */
  const checkContactDisabled = useCallback(
    (contact: CustomerContact): boolean => {
      if (disableCheckboxes) return true;
      if (
        contact.smsStatus?.some((status) =>
          [SMS_STATUS.landline, SMS_STATUS.not_in_service].includes(status)
        )
      )
        return true;
      if (type === BULK_TASK.TASK && contact.conversationsOptOut) return true;
      if (
        type === ANNOUNCEMENT_TYPES.INFORMATIONAL &&
        contact.announcementsOptOut
      )
        return true;
      if (contact.announcementsOptOut === null && type !== BULK_TASK.TASK)
        return true;
      if (
        type === ANNOUNCEMENT_TYPES.PROMOTIONAL &&
        (contact.promotionalBroadcastOptOut || contact.announcementsOptOut)
      )
        return true;
      return false;
    },
    [disableCheckboxes, type]
  );

  const allItemsSelected = useMemo(() => {
    return determineAllItemsSelected(
      optedInContacts,
      selectedAudience,
      checkContactDisabled,
      allSelectableContactIds
    );
  }, [
    optedInContacts,
    selectedAudience,
    checkContactDisabled,
    allSelectableContactIds,
  ]);

  const indeterminateState = useMemo(() => {
    return determineIndeterminateState(
      optedInContacts,
      selectedAudience,
      allItemsSelected,
      checkContactDisabled,
      allSelectableContactIds
    );
  }, [
    optedInContacts,
    selectedAudience,
    allItemsSelected,
    checkContactDisabled,
    allSelectableContactIds,
  ]);

  const handleContactSelection = (id: string, contactType: string) => {
    const isContact = contactType === PK_TYPENAMES.CUSTOMER_CONTACT;
    const isSelected = isContact
      ? selectedAudience.contactIds.includes(id)
      : selectedAudience.companyIds.includes(id);

    // First update the hook's state
    handleCheckItem(id);

    if (selectedAudience.selectAll) {
      // In select all mode, we're managing exclusions
      const isExcluded = isContact
        ? (selectedAudience.excludedContactIds || []).includes(id)
        : (selectedAudience.excludedCompanyIds || []).includes(id);

      if (isExcluded) {
        // Re-include the item
        onAdd({
          transitionFromSelectAll: true,
          includeId: id,
          includeType: contactType,
        });
      } else {
        // Exclude the item
        onAdd({
          transitionFromSelectAll: true,
          excludeId: id,
          excludeType: contactType,
        });
      }
    } else {
      // Regular add/remove
      onAdd({
        contactIds: isContact ? [id] : [],
        companyIds: !isContact ? [id] : [],
        remove: isSelected,
      });
    }
  };
  const handleSelectAll = () => {
    // Get the selectable contacts from the current view for UI selection
    const selectableContacts = optedInContacts.filter(
      (contact) => !checkContactDisabled(contact)
    );
    const selectableVisibleIds = selectableContacts.map((item) => item.id);

    // Update the UI selection state
    setSelectItems(selectableVisibleIds);

    // For company IDs, we still need to extract them from visible contacts
    const allSelectableCompanyIds = selectableContacts
      .filter((item) => item.__typename !== PK_TYPENAMES.CUSTOMER_CONTACT)
      .map((item) => item.id);

    // Use the backend-provided IDs for contacts
    onAdd({
      selectAll: true,
      contactIds: allSelectableContactIds || [],
      companyIds: allSelectableCompanyIds || [],
    });
  };

  const handleSelectNoneAll = () => {
    handleSelectNone();
    onAdd({
      clearAll: true,
      remove: true,
    });
  };

  const multiSelectOptions = [
    {
      label: i18n.t('settings-multiSelectTableItems-options-none', {
        number: 0,
      }),
      value: 'none',
      handleSelect: handleSelectNoneAll,
    },
    {
      label: i18n.t('settings-multiSelectTableItems-options-all', {
        number:
          allSelectableContactIds?.length ||
          pageInfo?.totalCount ||
          optedInContacts.length,
      }),
      value: 'all',
      handleSelect: handleSelectAll,
    },
  ];

  useEffect(() => {
    if (allItemsSelected) {
      const newContacts: string[] = [];
      const newCompanies: string[] = [];
      optedInContacts.forEach((item) => {
        if (item.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
          newContacts.push(item.id);
        } else newCompanies.push(item.id);
      });
      setContactIds(newContacts);
      setCompanyIds(newCompanies);
    }
  }, [allItemsSelected, optedInContacts]);

  useEffect(() => {
    if (type === BULK_TASK.TASK) {
      // For tasks, only filter out contacts that have opted out of conversations
      setOptedInContacts(
        contacts.filter(
          (c) =>
            !c.conversationsOptOut ||
            c.__typename !== PK_TYPENAMES.CUSTOMER_CONTACT
        )
      );
    } else if (type !== BROADCAST_LIST.LIST) {
      // Existing filter logic for other types
      setOptedInContacts(
        contacts.filter(
          (c) =>
            (type === ANNOUNCEMENT_TYPES.INFORMATIONAL &&
              !c.announcementsOptOut) ||
            (type === ANNOUNCEMENT_TYPES.PROMOTIONAL &&
              !c.announcementsOptOut &&
              !c.promotionalBroadcastOptOut) ||
            c.__typename !== PK_TYPENAMES.CUSTOMER_CONTACT
        )
      );
    } else {
      setOptedInContacts(contacts);
    }
  }, [contacts, type]);

  // Adds selected Ids to list and then resets selection
  const handleAdd = () => {
    onAdd({
      contactIds,
      companyIds,
    });

    setContactIds([]);
    setCompanyIds([]);
    handleSelectNone();
  };

  return (
    <>
      <FlexBetweenContainer>
        <AudienceFilterSort
          hasFilter
          sortBy={sort}
          sortOptions={sortOptions}
          handleSubmitFilter={onFilter}
          handleSubmitSort={onSort}
        />
        {type !== BULK_TASK.TASK ? (
          <Button
            dataTestId={`contact-table-${addSelectedDataTestId}`}
            onClick={() => handleAdd()}
            disabled={selectedItems.length === 0 || disableCheckboxes}
          >
            {i18n.t('broadcasts-broadcast-addSelected', {
              defaultValue: 'Add %{number} Selected',
              number: selectedItems.length,
            })}
          </Button>
        ) : null}
      </FlexBetweenContainer>
      <Table data-testid="audiencebuildertables-contacts-table">
        <TableHead>
          <tr>
            <MediumHeader>
              <MultiSelectTableItems
                key={`multi-select-${optedInContacts.length}-${selectedAudience.contactIds.length}-${selectedAudience.selectAll ? 'all' : 'some'}`}
                options={multiSelectOptions}
                mainTableHeader={i18n.t('settings-FinalMessage-customer', {
                  defaultValue: 'contact',
                })}
                indeterminate={indeterminateState}
                allItemsSelected={allItemsSelected}
                disabled={disableCheckboxes}
              />
            </MediumHeader>
            <SmallHeader>
              {i18n.t('settings-Groups-groups', { defaultValue: 'groups' })}
            </SmallHeader>
            <SmallHeader>
              {hasLabelsFF
                ? i18n.t('settings-Label-labels', { defaultValue: 'labels' })
                : ''}
            </SmallHeader>
            <SmallHeader>
              {i18n.t('broadcasts-broadcast-optin', {
                defaultValue: 'Opt-in Status',
              })}
            </SmallHeader>
          </tr>
        </TableHead>
      </Table>
      <InfiniteScroll
        dataTestId="broadcast-recipient-list"
        bidirectionalScroll={false}
        height={`calc(100vh - ${heightDifference}px)`}
        scrollableList={contacts}
        noRowsElement={
          <FlexCenterContainer>
            <EmptyListHeader dataTestId="create-broadcastList-empty">
              {noResultsCustomTranslation
                ? i18n.t(noResultsCustomTranslation, {
                    defaultValue:
                      noResultsCustomTranslationDefault || 'No results found.',
                  })
                : i18n.t('slideouts-TargetedAudience-noResults', {
                    defaultValue:
                      'No results were found for this type of audience.',
                  })}
            </EmptyListHeader>
          </FlexCenterContainer>
        }
        renderRow={(args: { list: CustomerContact[]; index: number }) => {
          const { list, index } = args;
          const contact = list[index] as CustomerContact;
          return (
            <CommonTableRow
              key={contact.id}
              id={contact.id}
              checked={shouldItemBeChecked(contact.id, selectedAudience)}
              name={
                contact.__typename === PK_TYPENAMES.CUSTOMER_ACCOUNT
                  ? contact.name
                  : contactName(contact)
              }
              onCheck={() =>
                handleContactSelection(contact.id, contact.__typename)
              }
              type={contact.__typename}
              company={contact.account?.name}
              groupIds={contact.groupIds}
              labels={contact.labels}
              optInStatusText={getOptInStatusText(
                contact,
                i18n,
                type === BULK_TASK.TASK
              )}
              disabled={checkContactDisabled(contact)}
              hasLabelsFF={hasLabelsFF}
            />
          );
        }}
        hasNextPage={pageInfo.hasNextPage}
        hasPreviousPage={pageInfo.hasPreviousPage}
        listItemHeight={64}
        loadingHeight={64}
        loadingBackgroundColor={THEMES.BACKGROUND_PRIMARY}
        loadingBorderBottom
        loadMoreRows={loadMoreRows}
        loading={loading}
      />
    </>
  );
};

export default TaskContactsTable;

import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import i18n from 'i18n-js';
import { Tooltip, ClickAwayListener } from '@mui/material';
import { InlineTextButton, Button, Text } from '..';
import BUBBLE_THEMES from '../../styles/themes/library/bubble';
import THEMES from '../../styles/themes/app';

const SortWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: flex-start;
  margin-top: 8px;
`;

const Eyecon = styled.i`
  font-size: 20px !important;
  padding-right: 4px;
`;

const OptionsButtonContainer = styled.div`
  border-radius: 4px;
  border: 1px solid ${THEMES.BORDER_COLOR};
  box-shadow: ${THEMES.BOX_SHADOW};
  display: flex;
  flex-direction: column;
  min-width: 170px;
  button {
    background: ${THEMES.BACKGROUND_PRIMARY};
    color: ${THEMES.FOREGROUND_HIGH} !important;
    justify-content: flex-start;
    padding: 0 8px;
    &:first-child {
      border-radius: 2px 2px 0 0;
    }
    &:last-child {
      border-radius: 0 0 2px 2px;
    }
    &:hover {
      background: ${BUBBLE_THEMES.HOVER_STATE};
    }
    &.isSelected {
      background: ${BUBBLE_THEMES.SELECTED_STATE};
    }
  }
`;

export type SortByOptionType = {
  value: string;
  label: 'ASC' | 'DESC' | string; // enum supported by BE
};

export interface SortByProps {
  options: SortByOptionType[];
  selection?: SortByOptionType;
  onSelect: (selection: SortByOptionType, index: number) => void;
  dataTestId?: string;
}

const SortBy = ({
  options,
  selection = undefined,
  onSelect,
  dataTestId = 'SortBy',
}: SortByProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const styledTheme = useContext(ThemeContext);

  const getPopperComponent = () => {
    return (
      <OptionsButtonContainer>
        {options?.map((option, i) => (
          <Button
            key={option.value}
            type="noStyle"
            noOutline
            iconAndText
            isSelected={option?.value === selection?.value}
            onClick={() => {
              onSelect(option, i);
              setTooltipOpen(false);
            }}
            className={option?.value === selection?.value ? 'isSelected' : ''}
            dataTestId={`${dataTestId}-sort-by-option-${option.value}`}
            id={`${dataTestId}-sort-by-option-${option.value}`}
          >
            {option?.label}
          </Button>
        ))}
      </OptionsButtonContainer>
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
      <Tooltip
        title={getPopperComponent()}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="bottom-start"
        open={tooltipOpen}
        slotProps={{ tooltip: { style: { padding: 0, marginTop: 8 } } }}
      >
        <SortWrapper>
          <InlineTextButton
            noOutline
            customStyle={() =>
              `color: ${
                tooltipOpen
                  ? THEMES.FOREGROUND_HIGH_COLOR({ theme: styledTheme })
                  : THEMES.FOREGROUND_HIGH({ theme: styledTheme })
              };
            &:hover{
              color: ${
                tooltipOpen
                  ? THEMES.FOREGROUND_MED_COLOR({ theme: styledTheme })
                  : THEMES.FOREGROUND_LOW({ theme: styledTheme })
              }
            }`
            }
            onClick={() => setTooltipOpen(!tooltipOpen)}
            dataTestId={`${dataTestId}-sort-by-button`}
          >
            <Eyecon className="ri-arrow-up-down-line" />
            {i18n.t('customers-SortBy-SortBy', { defaultValue: 'Sort By' })}
          </InlineTextButton>
          <Text
            customStyle={() =>
              `padding-left: 8px; color: ${THEMES.FOREGROUND_MED({ theme: styledTheme })}`
            }
          >
            {selection?.label}
          </Text>
        </SortWrapper>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default SortBy;

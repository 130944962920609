import React, { useState, useRef, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { PK_TYPENAMES, TEAM_QUERY, useCreateInternal } from 'client-lib';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import uniqid from 'uniqid';
import i18n from 'i18n-js';
import {
  resolveAvatarUrl,
  contactName,
} from 'client-lib/src/lib/utils/helpers';
import {
  showUploadProgressBar,
  hideUploadProgressBar,
  showUploadFailureBar,
  setDefaultContact,
} from '../../actions/uploadModal';
import { phoneRegex, normalizePhone } from '../Inputs/validation';
import {
  closeCreateSectionModal,
  openSnackbar,
  setActiveSidebar,
} from '../../actions/general';
import {
  clearCreateSection,
  setCreateInternal,
} from '../../actions/createSection';
import FileReaderInput from '../Inputs/FileReaderInput';
import UploadArt from '../Modals/UploadArt';
import EmojiMenu from '../Threads/EmojiMenu';
import {
  Heading2,
  IconLabel,
  Button,
  TextArea,
  SelectedEntity,
  InfiniteSuggest,
} from '../../elements';
import CreateMultiTag from '../Tag/CreateMultiTag';
import { populateAvatarIcon } from '../../elements/Avatar/Avatar';
import { CreateFormAttachment } from '../FileUpload/CreateFormAttachment';
import AnnotationsModal from '../Annotations/AnnotationsModal';
import validateAttachment from '../../utils/helpers/validateAttachment.ts';
import handleFileReaderInputChange from '../../utils/helpers/handleFileReaderInputChange.ts';
import { I18nError } from '../../utils/helpers/constants/index.ts';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 50px;
  height: 90%;
  max-height: 90%;
`;
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  max-width: 600px;
  align-items: stretch;
  justify-content: ${(props) =>
    props.centerVertically ? 'center' : 'flex-start'};
`;

const FileReaderInputWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FullFormRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
`;

const HeadingWrap = styled.div`
  margin-bottom: 48px;
`;

const LinkWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 40px;
`;

const ButtonRow = styled.div`
  margin: 8px 0;
  display: flex;
  position: relative;
`;

const AttachmentButtonWrap = styled.div`
  margin: 0 0 0 8px;
`;

const labelStyle = () => `
  margin-top: 8px;
  margin-left: 8px;
`;

const modalBodyStyleWithDragging = {
  height: '100%',
  width: '100%',
};

const CreateInternal = ({ createSection }) => {
  const client = useApolloClient();

  const history = useHistory();

  const [toFieldError, setToFieldError] = useState('');

  const [annotate, setAnnotate] = useState(false);

  const [emojiMenuOpen, setEmojiMenuOpen] = useState(false);

  const [contactText, setContactText] = useState('');

  const [isDraggingOnPage, setIsDraggingOnPage] = useState(false);

  const [annotationAttachmentIndex, setAnnotationAttachmentIndex] = useState(0);

  const [pasteMeta, setPasteMeta] = useState({
    cursorPosition: 0,
    pasted: false,
    event: null,
  });

  const [sendingMessage, setSendingMessage] = useState(false);

  const messageInput = useRef();

  const dispatch = useDispatch();
  const threadsActiveGroupIds = useSelector(
    (state) => state?.session?.threadsActiveGroupIds
  );
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const uploadModal = useSelector((state) => state?.uploadModal);

  const allGroups = useSelector((state) => state?.accountData?.allGroups);
  const presenceTrackingAvailable = useSelector(
    (state) => state?.accountData?.account?.ff_presence_tracking
  );

  const onMutationStart = () => {
    dispatch(showUploadProgressBar());
    setSendingMessage(true);
  };

  const onMutationSuccess = (data) => {
    const startedThread = data.startThread.thread;

    history.push(`/threads/open/${startedThread.id}`);

    dispatch(hideUploadProgressBar());
    dispatch(closeCreateSectionModal());
    dispatch(clearCreateSection());
    dispatch(setActiveSidebar('default'));
    setSendingMessage(false);
  };

  const onMutationError = (err) => {
    setAttachments([]);
    dispatch(showUploadFailureBar());
    setTimeout(() => dispatch(hideUploadProgressBar()), 3000);
    console.error(err);
    setSendingMessage(false);
  };

  const handleNotifyError = (msg) => {
    dispatch(openSnackbar(msg, 'error'));
  };

  const {
    handleMutationStartThread,
    contact,
    setContact,
    contactPhoneNumber,
    setContactPhoneNumber,
    tags,
    setTags,
    messageInputValue,
    setMessageInputValue,
    attachments,
    setAttachments,
    deleteAttachment,
    sendAttachmentAsLink,
    setSendAttachmentAsLink,
  } = useCreateInternal({
    client,
    currentUser,
    threadsActiveGroupIds,
    onMutationStart,
    onMutationSuccess,
    onMutationError,
    initialThread: createSection.createInternal,
    onError: handleNotifyError,
    allGroups,
  });

  const focusOnTextArea = () => messageInput?.current?.focus?.();

  useEffect(() => {
    if (
      contactPhoneNumber ||
      contact?.__typename === PK_TYPENAMES.USER ||
      contact?.__typename === PK_TYPENAMES.GROUP
    ) {
      focusOnTextArea();
    }
  }, [contactPhoneNumber, contact?.__typename]);

  useEffect(() => {
    if (uploadModal.defaultContact) {
      const { defaultContact } = uploadModal;
      setContact(defaultContact);
      // setContactPhoneNumber(defaultContact.phoneNumber);
    }

    return () => dispatch(setDefaultContact(null));
  }, []);

  const onSuggestionSelected = (e, { suggestion }) => {
    if (suggestion.__typename === PK_TYPENAMES.USER) {
      setContactPhoneNumber('');
      setContact(suggestion);
      setToFieldError('');
    } else if (suggestion.__typename === PK_TYPENAMES.GROUP) {
      setContactPhoneNumber('');
      setContact(suggestion);
      setToFieldError('');
    }
  };

  const onChange = (e) => setContactText(e.target.value);

  const handleInputChange = (e) => setMessageInputValue(e.target.value);

  const certifyToInputValueOnBlur = (e) => {
    const trimmedValue = e.target.value.trim();
    if (phoneRegex.test(trimmedValue) === true) {
      const number = normalizePhone(trimmedValue);
      setContactPhoneNumber(number);
      setContactText(number);
    } else if (!contact || contactPhoneNumber === '') {
      setContactText('');
    }
  };

  const handleEnterSubmit = (e) => {
    if (e.shiftKey && e.key === 'Enter' && messageInputValue.trim() !== '') {
      e.preventDefault();
      if (contactPhoneNumber || contact?.__typename === PK_TYPENAMES.USER) {
        handleMutationStartThread();
      }
    }
  };

  const handleSetAttachment = (
    originalFile,
    originalFilename = originalFile.name
  ) => {
    const reader = new window.FileReader();
    reader.onload = () => {
      const attachment = {
        data: reader.result,
        originalFilename,
        type: originalFile.type,
        id: uniqid(),
        size: originalFile.size,
      };
      setAttachments((attachments) => [...attachments, attachment]);
    };
    reader.readAsDataURL(originalFile);
  };

  const handleOnPaste = (e) => {
    e.preventDefault();
    e.persist();

    let clipBoardItem = null;

    if (e.clipboardData.types.includes('Files')) {
      clipBoardItem = e.clipboardData.items[1]
        ? e.clipboardData.items[1]
        : e.clipboardData.items[0];
    } else {
      clipBoardItem = e.clipboardData.items[0];
      if (
        e.clipboardData.types[0] === 'text/plain' ||
        e.clipboardData.types[0] === 'text/html'
      ) {
        // Paste in text based on cursor location.
        const pastedText = e.clipboardData.getData('Text');
        const selectionStart = e.target.selectionStart; // cursor location in input
        const selectionEnd = e.target.selectionEnd;
        const beforeSelection = e.target.value.slice(0, selectionStart);
        const afterSelection = e.target.value.slice(selectionEnd);
        const cursorPosition = beforeSelection.length + pastedText.length;

        setMessageInputValue(
          `${beforeSelection}${pastedText}${afterSelection}`
        );
        setPasteMeta({ cursorPosition, pasted: true, event: e });

        return;
      }
    }

    const originalFile = clipBoardItem.getAsFile();
    handleSetAttachment(originalFile);
  };

  useEffect(() => {
    if (pasteMeta.pasted && pasteMeta.event) {
      const { event, cursorPosition } = pasteMeta;
      event.target.setSelectionRange(cursorPosition, cursorPosition);
      setPasteMeta({ pasted: false, event: null, cursorPosition: 0 });
    }
  }, [pasteMeta.pasted]);

  useEffect(() => {
    const createInternalRedux = {
      contact,
      contactPhoneNumber,
      messageInputValue,
      attachments,
    };
    dispatch(setCreateInternal(createInternalRedux));
  }, [contact?.id, contactPhoneNumber, messageInputValue, attachments?.length]);

  const clearToFieldBubble = () => {
    setContactText('');
    setContactPhoneNumber('');
    setContact(null);
  };

  const onFileError = (error) => {
    if (error instanceof I18nError) {
      dispatch(
        openSnackbar(i18n.t(error.i18n_id, error.i18n_variables), 'error')
      );
    } else {
      console.error(error);
    }
  };

  const handleOnDragEnter = (e) => {
    e.preventDefault();
    setIsDraggingOnPage(true);
  };

  const handleOnDragLeave = (e) => {
    e.preventDefault();
    setIsDraggingOnPage(false);
  };

  const handleOnDrop = (acceptedFiles) => {
    setIsDraggingOnPage(false);
    validateAttachment(
      acceptedFiles[0],
      onFileError,
      handleSetAttachment,
      attachments
    );
  };

  const handleEmojiSelection = (emoji) => {
    const selectionStart = messageInput.current.selectionStart; // cursor group in input
    const beforeSelection = messageInput.current.value.slice(0, selectionStart);
    const afterSelection = messageInput.current.value.slice(selectionStart);
    setMessageInputValue(`${beforeSelection}${emoji.native} ${afterSelection}`);
    setEmojiMenuOpen(false);

    setTimeout(() => messageInput?.current?.focus?.(), 0);
  };

  const renderAttachmentSection = () =>
    attachments.length ? (
      <FullFormRow>
        <CreateFormAttachment
          attachments={attachments}
          onClose={deleteAttachment}
          sendAsLink={sendAttachmentAsLink}
          onMethodChange={() => setSendAttachmentAsLink(!sendAttachmentAsLink)}
          onAnnotate={(i) => {
            setAnnotationAttachmentIndex(i);
            setAnnotate(true);
          }}
          allowAnnotation
        />
      </FullFormRow>
    ) : null;

  const handleCloseAnnotation = (uri) => {
    setAnnotate(false);
    handleSaveAnnotation(uri);
  };

  const handleSaveAnnotation = (uri) => {
    const activeAttachment = attachments[annotationAttachmentIndex];
    const newAttachment = {
      data: uri,
      originalFilename: activeAttachment.originalFilename,
      type: activeAttachment.type,
      // id: attachment.id,
      id: uniqid(), // creating a new id on annotation save, so that the image for sure re-renders when new state set.
      size: activeAttachment.size,
    };

    deleteAttachment(activeAttachment.id);
    setAttachments((attachments) => [...attachments, newAttachment]);
  };

  const inferAvatarChildren = (contact) => {
    if (contact?.__typename === PK_TYPENAMES.USER) {
      return contact?.text || undefined;
    }
    if (contact?.__typename === PK_TYPENAMES.GROUP) {
      return contact?.text || undefined;
    }

    return undefined;
  };

  const inferEntityCardMainText = (contact) => {
    if (contact?.__typename === PK_TYPENAMES.USER) {
      return contactName(contact) || '';
    }
    if (contact?.__typename === PK_TYPENAMES.GROUP) {
      return contact?.name || '';
    }
    if (!contact && contactPhoneNumber) {
      return contactPhoneNumber;
    }

    return '';
  };

  const inferEntityCardSubText = (contact) => {
    if (contact?.__typename === PK_TYPENAMES.USER) {
      return contact?.title || '';
    }
    if (contact?.__typename === PK_TYPENAMES.GROUP) {
      return '';
    }

    return '';
  };

  let isGroup;
  let isUser;
  const avatarUrl = resolveAvatarUrl(contact);

  if (contact?.__typename === PK_TYPENAMES.USER) {
    isGroup = false;
    isUser = true;
  } else if (contact?.__typename === PK_TYPENAMES.GROUP) {
    isGroup = true;
    isUser = false;
  }

  const sharedValidation = contact?.id || contactPhoneNumber;
  const enableSubmissionWithMessage =
    sharedValidation && messageInputValue && !sendingMessage;
  const enableSubmissionWithAttachment =
    sharedValidation && attachments.length && !sendingMessage;

  return (
    <Dropzone
      disableClick
      multiple={false}
      onDragEnter={handleOnDragEnter}
      onDragLeave={handleOnDragLeave}
      onDrop={handleOnDrop}
      style={modalBodyStyleWithDragging}
    >
      <Container>
        <InnerContainer centerVertically={isDraggingOnPage}>
          {isDraggingOnPage ? (
            <UploadArt height="60%" />
          ) : (
            <>
              <HeadingWrap>
                <Heading2 dataTestId="new-internal-message-header">
                  {i18n.t('slideouts-CreateInternal-new')}
                </Heading2>
              </HeadingWrap>
              {contactPhoneNumber === '' && !contact?.id ? (
                <InfiniteSuggest
                  inputProps={{
                    label: i18n.t('slideouts-CreateThread-toLabel'),
                    error: toFieldError,
                    onBlur: certifyToInputValueOnBlur,
                    value: contactText,
                    onChange,
                    autoFocus: true,
                    placeholder: i18n.t('slideouts-CreateThread-enterName', {
                      defaultValue: 'Enter Name',
                    }),
                  }}
                  query={TEAM_QUERY}
                  queryKey="team"
                  queryVariables={{ name: contactText, after: null }}
                  onSelect={onSuggestionSelected}
                  includePresence={presenceTrackingAvailable}
                  includeGroupIndicator
                />
              ) : (
                <div style={{ position: 'relative' }}>
                  <SelectedEntity
                    label={i18n.t('slideouts-CreateThread-toLabel')}
                    avatarChildren={inferAvatarChildren(contact)}
                    maintext={inferEntityCardMainText(contact)}
                    subtext={inferEntityCardSubText(contact)}
                    onDelete={clearToFieldBubble}
                    avatarProps={{
                      avatarUrl,
                      type: 'internal',
                      displayPresence: isUser && presenceTrackingAvailable,
                      active: contact?.online,
                      icon: populateAvatarIcon({ isUser, isGroup }),
                    }}
                  />
                </div>
              )}
              {contactPhoneNumber === null ? (
                <LinkWrap>
                  <Link
                    to={`/contacts/all/${
                      contact?.id
                    }/${contact?.__typename?.toLowerCase()}`}
                  >
                    <Button
                      type="link"
                      size="zero"
                      onClick={() => dispatch(closeCreateSectionModal())}
                    >
                      {i18n.t('slideouts-CreateThread-cantSendMessage')}
                    </Button>
                  </Link>
                </LinkWrap>
              ) : (
                <TextArea
                  label={i18n.t('slideouts-CreateThread-message')}
                  rightSideLabelContent={
                    <CreateMultiTag tags={tags} setTags={setTags} />
                  }
                  placeholder={i18n.t('slideouts-CreateThread-pressShift')}
                  onKeyPress={handleEnterSubmit}
                  value={messageInputValue}
                  onChange={handleInputChange}
                  onPaste={handleOnPaste}
                  ref={messageInput}
                  id="textarea"
                  name="messageInputValue"
                  rows={4}
                  dataTestId="at-textarea"
                  customLabelWrapperStyle={() => `
                    align-items: flex-end;
                    max-height: 38px;
                  `}
                />
              )}
              {renderAttachmentSection()}
              <ButtonRow>
                <Button
                  disabled={
                    attachments.length
                      ? !enableSubmissionWithAttachment
                      : !enableSubmissionWithMessage
                  }
                  onClick={handleMutationStartThread}
                  data-testid="send-message-button"
                >
                  {i18n.t('slideouts-CreateThread-send')}
                </Button>
                <AttachmentButtonWrap>
                  <FileReaderInput
                    name="modalAttachmentInput"
                    WrapperComponent={FileReaderInputWrapper}
                    handleFileReaderInputChange={(e) =>
                      handleFileReaderInputChange(
                        e,
                        onFileError,
                        handleSetAttachment,
                        attachments
                      )
                    }
                    multiple
                  >
                    <IconLabel
                      htmlFor="modalAttachmentInput"
                      customStyle={labelStyle}
                      contrast="medColor"
                    >
                      <i className="ri-attachment-2" />
                    </IconLabel>
                  </FileReaderInput>
                </AttachmentButtonWrap>

                <EmojiMenu
                  labelType="grey"
                  handleEmojiSelection={handleEmojiSelection}
                  open={emojiMenuOpen}
                  onClickOutside={() => setEmojiMenuOpen(false)}
                >
                  {({ emojiTouchTap }) => (
                    <IconLabel
                      onClick={(e) => {
                        emojiTouchTap(e);
                        setEmojiMenuOpen(true);
                      }}
                      title={i18n.t('slideouts-CreateThread-insertEmoji')}
                      htmlFor="emojiInput"
                      customStyle={labelStyle}
                      contrast="medColor"
                    >
                      <i className="ri-emotion-line" />
                    </IconLabel>
                  )}
                </EmojiMenu>
              </ButtonRow>
            </>
          )}
        </InnerContainer>
        <AnnotationsModal
          open={annotate}
          setOpen={setAnnotate}
          attachment={attachments[annotationAttachmentIndex]}
          handleSave={handleCloseAnnotation}
        />
      </Container>
    </Dropzone>
  );
};

CreateInternal.propTypes = {
  createSection: PropTypes.object.isRequired,
};

export default CreateInternal;

import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate'; // eslint-disable-line import/no-named-default
import type { InputActionMeta } from 'react-select';
import InputLabel from '../../inputCommonElements/InputLabel.ts';
import InputError from '../../inputCommonElements/InputError.tsx';
import type {
  AsyncPaginateLoadOptionsFunction,
  SelectOnChange,
  RSOptionRef,
  SingleOrMultiValue,
} from '../shared/types.ts';
import { Container } from '../shared/styles.ts';
import {
  createDropdownIndicator,
  createMultiValueChip,
  createNoOptionComponent,
  createOption,
} from '../shared/utils.tsx';

export interface AsyncSelectPaginateProps {
  customContainerStyle?: () => string;
  customInputStyle?: () => string;
  customLabelStyle?: () => string;
  customOptionStyle?: () => string;
  dataTestId?: string;
  disabled?: boolean;
  error?: string;
  helperText?: string;
  hideBottomSpace?: boolean;
  isClearable?: boolean;
  isMulti: boolean;
  label: string;
  loading?: boolean;
  loadOptions: AsyncPaginateLoadOptionsFunction;
  onChange: SelectOnChange;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  placeholder?: string;
  value: SingleOrMultiValue;
}

const AsyncSelectPaginate = React.forwardRef(
  (
    {
      customContainerStyle,
      customInputStyle,
      customLabelStyle,
      customOptionStyle,
      dataTestId,
      disabled,
      error,
      helperText,
      hideBottomSpace,
      isClearable = false,
      isMulti,
      label,
      loadOptions,
      onInputChange,
      placeholder,
      value,
      ...otherProps
    }: AsyncSelectPaginateProps,
    ref
  ) => {
    const chipLabel = `selected-chip${dataTestId ? `-${dataTestId}` : ''}`;
    const selectClass = `AsyncSelectPaginate${dataTestId ? `-${dataTestId}` : ''}`;

    return (
      <Container
        error={error}
        disabled={disabled}
        prefix={selectClass}
        customContainerStyle={customContainerStyle}
      >
        {label ? (
          <InputLabel
            error={error}
            disabled={disabled}
            customLabelStyle={customLabelStyle}
          >
            {label}
          </InputLabel>
        ) : null}
        <AsyncPaginate
          // @ts-expect-error overwrite ref
          ref={ref}
          loadOptions={loadOptions}
          value={value}
          onInputChange={onInputChange}
          error={error}
          disabled={disabled}
          isDisabled={disabled}
          placeholder={placeholder}
          isClearable={isClearable}
          menuPlacement="auto"
          classNamePrefix={selectClass}
          components={{
            DropdownIndicator: (props) =>
              createDropdownIndicator({ props, dataTestId }),
            Option: (props) =>
              createOption({
                props,
                customOptionStyle,
                dataTestId,
                isMulti,
                ref: ref as RSOptionRef,
              }),
            MultiValueContainer: (props) =>
              createMultiValueChip({ props, dataTestId: chipLabel }),
            NoOptionsMessage: (props) =>
              createNoOptionComponent({
                props,
                dataTestId,
                ref: ref as RSOptionRef,
              }),
          }}
          customInputStyle={customInputStyle}
          customOptionStyle={customOptionStyle}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          hideSelectedOptions={!isMulti}
          defaultOptions
          cacheOptions
          {...otherProps}
        />
        {(!hideBottomSpace || error) && (
          <InputError error={error} helperText={helperText} />
        )}
      </Container>
    );
  }
);

AsyncSelectPaginate.displayName = 'AsyncSelectPaginate';

export default AsyncSelectPaginate;

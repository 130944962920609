import { any, contains, map } from 'ramda';

export const AVAILABLE_PERMISSIONS = {
  APPLY_OR_REMOVE_TAGS: 'APPLY_OR_REMOVE_TAGS',
  BLOCK_CHANNEL: 'BLOCK_CHANNEL',
  CREATE_GROUP: 'CREATE_GROUP',
  CREATE_LABEL: 'CREATE_LABEL',
  CREATE_PAYMENT_REQUEST: 'CREATE_PAYMENT_REQUEST',
  CREATE_REGION: 'CREATE_REGION',
  DELETE_COMPANY_AND_CONTACTS: 'DELETE_COMPANY_AND_CONTACTS',
  DELETE_COMPANY: 'DELETE_COMPANY',
  DELETE_CUSTOMER: 'DELETE_CUSTOMER',
  DELETE_LABEL: 'DELETE_LABEL',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  EDIT_ACCOUNT: 'EDIT_ACCOUNT',
  EDIT_ANNOUNCEMENT: 'EDIT_ANNOUNCEMENT',
  EDIT_AUTO_RETURN: 'EDIT_AUTO_RETURN',
  EDIT_COMPANIES: 'EDIT_COMPANIES',
  EDIT_CONTACT_EMAIL_OPT_OUT: 'EDIT_CONTACT_EMAIL_OPT_OUT',
  EDIT_CONTACT_FAX_OPT_OUT: 'EDIT_CONTACT_FAX_OPT_OUT',
  EDIT_CONTACT_LABELS: 'EDIT_CONTACT_LABELS',
  EDIT_CONTACT_OPT_OUT: 'EDIT_CONTACT_OPT_OUT',
  EDIT_CUSTOMER_REP: 'EDIT_CUSTOMER_REP',
  EDIT_CUSTOMERS: 'EDIT_CUSTOMERS',
  EDIT_GROUP: 'EDIT_GROUP',
  EDIT_TEAM: 'EDIT_TEAM',
  EDIT_TEMPLATE: 'EDIT_TEMPLATE',
  EDIT_TITLE: 'EDIT_TITLE',
  GRANT_ADMINISTRATOR_ROLE_PERMISSION: 'GRANT_ADMINISTRATOR_ROLE',
  GRANT_ANNOUNCEMENTS_ACCESS: 'GRANT_ANNOUNCEMENTS_ACCESS',
  GRANT_CREATE_PAYMENT_REQUEST: 'GRANT_CREATE_PAYMENT_REQUEST',
  GRANT_MANAGER_ROLE_PERMISSION: 'GRANT_MANAGER_ROLE',
  GRANT_MEMBER_ROLE_PERMISSION: 'GRANT_MEMBER_ROLE',
  GROUP_TEXT: 'GROUP_TEXT',
  GROWTH_HUB_DASHBOARD_ACCESS: 'GROWTH_HUB_DASHBOARD_ACCESS',
  IMPORT_CUSTOMERS: 'IMPORT_CUSTOMERS',
  LIST_CUSTOMERS: 'LIST_CUSTOMERS',
  LIST_TAGS_GROUPS: 'LIST_TAGS_GROUPS',
  LIST_TAGS: 'LIST_TAGS',
  LIST_TEMPLATES: 'LIST_TEMPLATES',
  LIST_WIDGETS: 'LIST_WIDGETS',
  LOCK_MANAGER_NOTIFICATION_SETTINGS: 'LOCK_MANAGER_NOTIFICATION_SETTINGS',
  LOCK_MEMBER_NOTIFICATION_SETTINGS: 'LOCK_MEMBER_NOTIFICATION_SETTINGS',
  MANAGE_API_CREDENTIALS: 'MANAGE_API_CREDENTIALS',
  MANAGE_AWAY: 'MANAGE_AWAY_MESSAGE',
  MANAGE_FEEDBACK_SETTINGS: 'MANAGE_FEEDBACK_SETTINGS',
  MANAGE_GROUPS: 'MANAGE_GROUPS',
  MANAGE_TAGS: 'MANAGE_TAGS',
  MANAGE_TEAM: 'MANAGE_TEAM',
  MANAGE_WEBCHAT: 'MANAGE_WEBCHAT',
  READ_BLOCKED_CHANNELS: 'READ_BLOCKED_CHANNELS',
  READ_CONTACT_LABELS: 'READ_CONTACT_LABELS',
  READ_REGIONS: 'READ_REGIONS',
  RECUR_BTM: 'RECUR_BTM',
  TOGGLE_PRIORITY_NOTIFICATIONS: 'TOGGLE_PRIORITY_NOTIFICATIONS',
  UNBLOCK_CHANNEL: 'UNBLOCK_CHANNEL',
  UNLOCK_MANAGER_NOTIFICATION_SETTINGS: 'UNLOCK_MANAGER_NOTIFICATION_SETTINGS',
  UNLOCK_MEMBER_NOTIFICATION_SETTINGS: 'UNLOCK_MEMBER_NOTIFICATION_SETTINGS',
  UPDATE_LABEL: 'UPDATE_LABEL',
  UPDATE_MANAGER_NOTIFICATION_SETTINGS: 'UPDATE_MANAGER_NOTIFICATION_SETTINGS',
  UPDATE_MEMBER_NOTIFICATION_SETTINGS: 'UPDATE_MEMBER_NOTIFICATION_SETTINGS',
  UPDATE_REGION: 'UPDATE_REGION',
  VIEW_ADMIN_CONFIG: 'VIEW_ADMIN_CONFIG',
  VIEW_AUTOMATION_RULES: 'VIEW_AUTOMATION_RULES',
  VIEW_CONTACT_ACTIVITY: 'VIEW_CONTACT_ACTIVITY',
  VIEW_CONTACT_MANAGEMENT: 'VIEW_CONTACT_MANAGEMENT',
  VIEW_CUSTOMER_GROUPS: 'VIEW_CUSTOMER_GROUPS',
  VIEW_DASHBOARD: 'VIEW_DASHBOARD',
  VIEW_FEEDBACK: 'VIEW_FEEDBACK',
  VIEW_GROUP: 'VIEW_GROUP',
  VIEW_LABELS_CONFIGURATION: 'VIEW_LABELS_CONFIGURATION',
  VIEW_LABELS_SETTINGS: 'VIEW_LABELS_SETTINGS',
  VIEW_PRIORITY_NOTIFICATIONS_STATE: 'VIEW_PRIORITY_NOTIFICATIONS_STATE',
  VIEW_TASKS: 'VIEW_TASKS',
  VIEW_TEMPLATE: 'VIEW_TEMPLATE',
  VIEW_THREAD_REPORT: 'VIEW_THREAD_REPORT',
  VIEW_THREADS: 'VIEW_THREADS',
  VIEW_UPGRADE_BLURBS: 'VIEW_UPGRADE_BLURBS',
};

export const USER_ROLES = {
  MEMBER: 'MEMBER',
  ADMINISTRATOR: 'ADMINISTRATOR',
  MANAGER: 'MANAGER',
};

export const checkIfCurrentUserHasPermission = (
  targetPermission,
  accountPolicies,
  groupPolicies,
) =>
  any(
    (accountPolicy) => contains(targetPermission, accountPolicy.actions),
    accountPolicies,
  ) ||
  any(
    (groupPolicy) => contains(targetPermission, groupPolicy.actions),
    groupPolicies,
  );

export const existPermissionInAccountPolicies = (
  targetPermission,
  accountPolicies,
) =>
  any(
    (accountPolicy) => contains(targetPermission, accountPolicy.actions),
    accountPolicies,
  );

// Takes list of all groups and a list of permission based groupIds and creates list of permissable groups
export const availableGroupsByGroupIds = (groups, groupIds) => {
  const groupsOptions = groups.filter((group) => groupIds?.includes(group.id));
  return map(
    ({ name, id }) => ({ label: name, value: id }),
    groupsOptions,
  ).sort((a, b) => ('' + a.label).localeCompare(b.label));
};

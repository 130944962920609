import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import theme from 'styled-theming';
import { Heading2, Text, Heading5, IconButton } from '../../../../../elements';

const REACHABLE_AUDIENCE_TEXT_FONTSIZE = theme('fontSize', {
  default: '10px',
  large: '14px',
});

const CENTER_FOOTER_CONTAINER_WIDTH = theme('fontSize', {
  default: '600px',
  large: '700px',
});

const CenterFooterContainer = styled.div`
  display: flex;
  width: ${CENTER_FOOTER_CONTAINER_WIDTH};
  justify-content: space-between;
  margin-left: 70px;
  height: 100%;
`;

const AudienceWrapper = styled.div`
  width: fit-content;
  padding: 8px 4px;
`;

const IconWrapper = styled.div`
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  margin-bottom: 15px;
`;

const ReachableAudienceFooter = ({
  reachableAudienceCount,
  optedOutAudienceCount,
  handleEyeconClick,
}) => {
  return (
    <CenterFooterContainer>
      <AudienceWrapper>
        <Heading5 contrast="colorHigh">
          {i18n.t('slideouts-GroupMessageRecipients-reachable')}
        </Heading5>
        <TextWrapper>
          <Text
            contrast="colorHigh"
            customStyle={(props) =>
              `font-size: ${REACHABLE_AUDIENCE_TEXT_FONTSIZE(
                props
              )}; letter-spacing: 0.1px;`
            }
          >
            {i18n.t('slideouts-GroupMessageRecipients-optedInCustomers')}
          </Text>
        </TextWrapper>
        <Heading2 contrast="colorHigh">{reachableAudienceCount}</Heading2>
      </AudienceWrapper>
      <AudienceWrapper>
        <Heading5 contrast="colorHigh">
          {i18n.t('slideouts-GroupMessageRecipients-optedOut')}
        </Heading5>
        <TextWrapper>
          <Text
            contrast="colorHigh"
            customStyle={(props) =>
              `font-size: ${REACHABLE_AUDIENCE_TEXT_FONTSIZE(
                props
              )}; letter-spacing: 0.1px;`
            }
          >
            {i18n.t('slideouts-GroupMessageRecipients-optedOutCustomers')}
          </Text>
        </TextWrapper>
        <div style={{ height: 29 }}>
          <Heading2 contrast="colorHigh">{optedOutAudienceCount}</Heading2>
        </div>
      </AudienceWrapper>
      <IconWrapper showButton={reachableAudienceCount}>
        {reachableAudienceCount ? (
          <IconButton
            contrast="colorHigh"
            size="sm"
            onClick={handleEyeconClick}
          >
            <i data-testid="reachable-audience-icon" className="ri-eye-fill" />
          </IconButton>
        ) : null}
      </IconWrapper>
    </CenterFooterContainer>
  );
};

ReachableAudienceFooter.propTypes = {
  reachableAudienceCount: PropTypes.number,
  optedOutAudienceCount: PropTypes.number,
  handleEyeconClick: PropTypes.func.isRequired,
};

ReachableAudienceFooter.defaultProps = {
  reachableAudienceCount: null,
  optedOutAudienceCount: null,
};

export default ReachableAudienceFooter;

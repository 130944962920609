import type { UiLabel } from 'client-lib/src/lib/utils/helpers/types';
import type { I18nError } from './constants';

/**
 * Allows asserting the T is the type of the item passed in.
 * @param item Item to assert type for
 * @param checkValue Check value to see if it exists on item, to have some type of object shape check
 * @returns boolean
 */
export const assertType = <T>(
  item: T | object | undefined | null,
  checkValues: string[]
): item is T => {
  if (!item) return false;
  return (
    typeof item === 'object' && checkValues.every((value) => value in item)
  );
};

/**
 * Contact that has at least these values to determine thier SMS status
 * @typedef {object} ContactSMSSettings
 * @property {boolean} announcementsOptOut
 * @property {boolean} promotionalBroadcastOptOut
 * @property {SMSStatus[]} smsStatus
 */

/**
 * Group object for user
 * @typedef {object} Group
 * @property {string} id
 * @property {string} name
 * @property {string} accountId
 * @property {string} timezone
 * @property {boolean} awayMessageEnabled
 * @property {string} awayMessageTemplate
 * @property {string} awayMessageText
 * @property {number} autoReturnThreadsDelayMins
 * @property {boolean} autoReturnThreadsEnabled
 */

/**
 * User Role Enum
 */
export enum UserRole {
  ADMINISTRATOR = 'administrator',
  MANAGER = 'manager',
  MEMBER = 'member',
}

/**
 * Type for i18n when it's passed as a prop
 */
export interface I18NType {
  t: (
    key: string,
    args?: {
      defaultValue: string;
      [key: string]: string | number;
    }
  ) => string;
  locale?: string;
}

/**
 * Types for App Theme
 */

export enum FontSizeTheme {
  default = 'default',
  large = 'large',
}

export enum ModeTheme {
  light = 'light',
  dark = 'dark',
}

export type Theme = {
  fontSize: FontSizeTheme;
  mode: ModeTheme;
};

export type StockMessage = {
  label: string;
  key: string;
};

export type User = {
  __typename?: 'User';
  accountId: string;
  accountPolicies: string[];
  accountPoliciesQueried: string[];
  firstName: string;
  fontSize: string;
  groupIds: string[];
  groupPolicies: string[];
  language: string;
  theme: string;
  userId: string;
  avatarUrl: string;
  contactId: string;
  id: string;
  lastName: string;
  online: boolean;
  phoneNumber: string;
  title: string;
  username: string;
  role?: UserRole;
  stockMessagesConfig: StockMessage[];
};

/**
 * Type for CurrentUser in AppState
 */
export type CurrentUser = User;

export type PhoneNumber = {
  carrierType: string | null;
  contactId: string;
  id: string;
  label: string;
  number: string;
  primary: boolean;
  __typename?: 'PhoneNumber';
};

export type DailyHours = {
  closeAt: string | null;
  openAt: string | null;
  __typename?: 'DailyHours';
};

export type Address = {
  addressLine1: string;
  addressLine2: string;
  country: string;
  locality: string;
  administrativeDistrictLevel1: string;
  postalCode: string;
};

/**
 * Type for Group in AppState
 */
export type Group = {
  id: string;
  accountId: string;
  name: string;
  timezone: string;
  address: Address;
  contact: {
    id: string;
    groupId: string;
    phoneNumbers: PhoneNumber[];
    __typename?: 'GroupContact';
  };
  businessHours: {
    monday: DailyHours;
    tuesday: DailyHours;
    wednesday: DailyHours;
    thursday: DailyHours;
    friday: DailyHours;
    saturday: DailyHours;
    sunday: DailyHours;
    __typename?: 'WeeklyHours';
  };
  autoReturnThreadsDelayMins: number;
  autoReturnThreadsEnabled: boolean;
  awayMessageEnabled: boolean;
  awayMessageTemplate: string;
  awayMessageText: string | null;
  groupConfig?: {
    feedbackInterval: string | null;
  };
  member_count: number;
  __typename?: 'Group';
};

export type Announcement = {
  audienceInfo?: { reachableAudienceCount: number };
  attachments?: object[];
  failedCount?: number;
  link?: string | null;
  clickCount: number | null;
  id: string;
  insertedByUser: { __typename: 'User'; firstName: string; lastName: string };
  numThreads: number;
  outboundChannel: {
    __typename: 'Channel';
    id: string;
    group: Partial<Group>;
    smsConfig: {
      __typename: 'SmsConfig';
      smsCarrier: string;
    };
  };
  responseCount: number;
  sendCompletedAt: string;
  sendStartedAt: string;
  sentCount: number;
  subject: string;
  unsubscribeCount: number;
  updatedAt: string;
  dueAt: string;
  endsAt?: string;
  __typename: 'Announcement';
  repeats: string;
};

export interface Attachment {
  data: string;
  originalFilename: string;
  type: string;
  id: string;
  size: number;
}

export type ImageToUpload = {
  nodeKey: string;
  url?: string | undefined;
  error?: I18nError | null;
  attachment: Attachment;
};

export enum ActiveCustomerSource {
  click = 'click',
  edited = 'edited',
  load = 'load',
  refetch = 'refetch',
  scroll = 'scroll',
  search = 'search',
}

/**
 * Type for AppState in useSelector
 */
export interface AppState {
  accountData: {
    account: {
      ff_btm_opt_in_prompt: boolean;
      ff_broadcast_list_improvements: boolean;
      ff_broadcast_text_reply_routing: boolean;
      ff_bulk_create_tasks: boolean;
      ff_contact_labels: boolean;
      ff_email: boolean;
      ff_rich_text_email_formatting: boolean;
      ff_fax: boolean;
      ff_webchat: boolean;
      ff_jit_enabled: boolean;
      ff_smart_tags?: boolean;
      ff_internal_threads?: boolean;
      name: string;
    };
    allGroups: Array<Group>;
  };
  applicationState: {
    userLoadedFromDistributor: boolean;
    applicationLoaded: boolean;
  };
  customerInfo: {
    filterText: string;
  };
  session: {
    currentUser: CurrentUser;
    feedbackActiveGroupIds: string[];
    threadsActiveGroupIds: string[];
    [key: string]: string | boolean | string[] | object;
  };
  general: {
    activeCreateBroadcastListModal: boolean;
    activeCustomerInfoSlideout: boolean;
    activeCustomerSource: ActiveCustomerSource;
    announcementFilter: string;
    announcementStatus: string;
    contactSlideoutAutofocusField: string;
    snackAutoHide: boolean;
    snackbarOpen: boolean;
    snackButtonLink: string;
    snackMessage: string;
    snackType: string;
    triggerRefetchBroadcast: boolean;
  };
  createSection: {
    createTask: {
      id: string;
    };
  };
  uploadModal: {
    modalOpen: boolean;
    annotationsModalOpen: boolean;
    uploadProgressBarOpen: boolean;
    uploadFailed: boolean;
    attachment: File | null;
    attachments: Attachment[];
    openUnblockModal: boolean;
    numberToBeUnblock: string;
    annotationAttachmentIndex: 0;
  };
  label: {
    createLabelState: boolean;
    editLabelState: boolean;
  };
  editCustomer: {
    optimisticDeletedCompanyIds: string[];
    triggerEditContactLabels: boolean;
  };
}

export enum SMS_STATUS {
  landline = 'SUSPECTED_LANDLINE',
  not_in_service = 'NOT_IN_SERVICE',
}

export enum AUTH_PROVIDER {
  web = 'web',
  saml = 'saml',
  microsoft = 'microsoft',
  cognito = 'cognito',
}

export enum WEEK_DAYS {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

/**
 * Type for PageInfo in Paginated Queries
 */
export type PageInfo = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  totalCount: number;
};

/**
 * Type for Companies, also known as CustomerAccounts
 */
export type CustomerAccount = {
  __typename?: 'CustomerAccount';
  accountNumber?: string;
  emailAddress?: string;
  id: string;
  name: string;
  notes?: string;
  notesUpdatedAt?: Date;
  phoneNumber?: string;
  workOrderCount: number;
  // eslint-disable-next-line no-use-before-define
  contacts: CustomerContact[];
};

/**
 * Type for Contact Labels, also known plainly as Labels
 */
export type Label = {
  __typename: 'Label';
  color?: string;
  description?: string;
  id: string;
  insertedAt: string;
  name: string;
  numberOfContacts: number;
  updatedAt?: string;
};

/**
 * Type for Label Options passed into onChange for LabelFormInput
 */

export type LabelOption = {
  color: string;
  label: string;
  text: string;
  value: string;
};

/**
 * Type for tag (aka workOrder)
 */
export interface WorkOrder {
  id: string;
  referenceNumber: string;
  source?: string;
}

export interface GraphEdge<T> {
  node: T;
}

/**
 * Type for Broadcast Lists, also known as Tags
 */
export type Tag = {
  id: string;
  name: string;
  __typename: 'Tag';
};

/**
 * Type for Contacts, also known as CustomerContacts
 */
export type CustomerContact = {
  __typename: 'CustomerContact';
  account: CustomerAccount;
  announcementsOptInAt?: Date;
  announcementsOptOut: boolean;
  apiOptOut: boolean;
  blockedChannels: string[];
  conversationsOptOut: boolean;
  emailAddress: string;
  emailsOptOut: boolean;
  faxNumber?: string;
  faxOptOut: boolean;
  firstName: string;
  groupIds: string[];
  id: string;
  jobTitle?: string;
  labels: Label[];
  lastName: string;
  name?: string;
  notes?: string;
  emailAddresses?: object[];
  notesUpdatedAt?: Date;
  phoneNumber?: string;
  prioritizeRep: boolean;
  promotionalBroadcastOptOut: boolean;
  repUser?: User;
  smsStatus: SMS_STATUS[];
  tags: WorkOrder[];
};

export interface CreateThreadState {
  additionalRecipients: CustomerContact[];
  attachment: Attachment | null;
  contact: CustomerContact | null;
  contactPhoneNumber: string;
  messageInputValue: string;
  senderGroup: UiLabel | null;
  taskId?: string;
  templateId?: string;
}

export interface CreateEmailState {
  attachments: Attachment[];
  carbonCopyContacts: CustomerContact[];
  contact: CustomerContact | null;
  contactEmail: string;
  messageInputValue: string;
  messageSubjectValue: string;
  senderGroup: UiLabel | null;
  taskId?: string;
  templateId?: string;
}

export type AccountRep = {
  value: string;
  id: string;
  lastSelectedName: string;
  selectedUser: Record<string, unknown>;
};

export type CreateCustomerGroup = {
  value?: string /* id in some cases */;
  text?: string /* name in some cases */;
  id?: string /* this exists when trying to merge contact */;
  name?: string /* this exists when trying to merge contact */;
  label?: string;
};

export type CreateCustomer = {
  firstName: string;
  id: string;
  lastName: string;
  jobTitle: string;
  phoneNumber: string;
  faxNumber: string;
  email: string;
  welcomeMessage: boolean;
  company: {
    value: string;
    text: string;
    addCompanySuggestion: boolean;
  };
  accountPhoneNumber: string;
  accountEmail: string;
  accountNumber: string;
  group: {
    value: CreateCustomerGroup[];
    text: string;
  };
  accountRep: {
    value: string;
    id: string;
    lastSelectedName: string;
    selectedUser: Record<string, unknown>;
  };
  prioritizeRep: boolean;
  updateContact: boolean;
  updateContactType: string;
  isMergeContact: boolean;
  newPhoneNumber: string;
  newFaxNumber: string;
  newEmail: string;
  mergeOn: string;
  mergeContactId?: string;
  phoneSelectValue: string;
  faxSelectValue?: string;
  emailSelectValue?: string;
  groupIds?: string[];
};

export enum ThreadTypeEnum {
  sms = 'sms',
  email = 'email',
  fax = 'fax',
}

// This is theoreticaly, CustomerContact but the
// optional fields seem different.
export type ExternalContact = {
  id: string;
  account?: CustomerAccount | null;
  emailAddress?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  jobTitle?: string | null;
  __typename?: 'ExternalContact';
};

// This is only a start to a _very_ complex type.
// PLEASE add to it!
export type Thread = {
  accountId: string;
  group?: {
    name: string;
    id: string;
  };
  groupId?: string;
  id: string;
  type: ThreadTypeEnum;
  subject: string | null;
  insertedAt: string;
  claimedAt?: string | null;
  archivedAt?: string | null;
  externalContact: ExternalContact | null;
  otherExternalContacts?: ExternalContact[];
};

export type Message = {
  attachments: object[];
  createdAt: string;
  deleted: boolean;
  deletedByContact?: CustomerContact;
  deletionDescription?: string;
  deletionReason?: string;
  html?: string;
  id: string;
  ignoreReason?: string;
  ignored: boolean;
  ignoredByContact?: CustomerContact;
  insertedAt: string;
  insertedByContact: CustomerContact;
  metadata: object;
  status: string;
  targetContact?: CustomerContact;
  text: string;
  thread: { __typename: 'Thread'; subject?: string; avatarUrl?: string };
  threadId: string;
  transmittedAt: string;
  type: string;
  updatedAt: string;
  __typename: 'Message';
};

export enum ThemeType {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  quaternary = 'quaternary',
}

export type Option = {
  label: string;
  value: string;
};

export enum WebChatWidgetType {
  WEBCONNECT = 'WEBCONNECT',
  WEBCHAT_FULL = 'STANDARD',
  WEBCHAT_EMBEDDED = 'EMBEDDED',
}

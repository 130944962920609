import React, { forwardRef } from 'react';
import { LABELS_MINIMAL_QUERY } from 'client-lib';
import i18n from 'i18n-js';
import { AsyncSelectPaginate } from '../../elements';
import type { SingleOrMultiValue } from '../../elements/Select/shared/types.ts';
import useAsyncSelectPaginateHelper, {
  OptionTypeKeys,
} from './useAsyncSelectPaginateHelper.ts';

interface LabelAsyncSelectProps {
  hideLabel?: boolean;
  value: { [key: string]: string }[];
  setValue: (value: SingleOrMultiValue) => void;
  hideBottomSpace?: boolean;
}

const LabelAsyncSelect = forwardRef(
  (
    {
      value,
      setValue,
      hideBottomSpace = false,
      hideLabel = false,
      ...props
    }: LabelAsyncSelectProps,
    ref
  ) => {
    const generateOptionMap = {
      text: OptionTypeKeys.Label,
      name: OptionTypeKeys.Label,
      id: OptionTypeKeys.Value,
      color: OptionTypeKeys.Color,
    };

    const { loadOptions, queryError } = useAsyncSelectPaginateHelper({
      generateOption: generateOptionMap,
      query: LABELS_MINIMAL_QUERY,
      key: 'labels',
      queryVariables: {},
      resultsNumber: 25,
    });

    return (
      <AsyncSelectPaginate
        label={
          hideLabel
            ? ''
            : i18n.t('settings-Label-labels', {
                defaultValue: 'Labels',
              })
        }
        hideBottomSpace={hideBottomSpace}
        value={
          value.map((val) => ({
            label: val.text || val.name,
            value: val.id,
            ...val,
          })) as SingleOrMultiValue
        }
        isMulti
        loadOptions={loadOptions}
        onChange={(selectedOption) => setValue(selectedOption)}
        dataTestId="label-async-select"
        error={queryError}
        ref={ref}
        {...props}
      />
    );
  }
);

export default LabelAsyncSelect;

// import customerAccountSubscription from './subscription/CustomerAccountSubscription.graphql';
import customerAccountSubscription from "../../graphql/subscription/CustomerAccountSubscription.graphql";
import customerContactSubscription from "../../graphql/subscription/CustomerContactSubscription.graphql";
import messageCreatedOrUpdatedSubscription from "../../graphql/subscription/MessageCreatedOrUpdatedSubscription.graphql";
import openThreadSubscription from "../../graphql/subscription/OpenThreadSubscription.graphql";
import openThreadForUserSubscription from "../../graphql/subscription/OpenThreadForUserSubscription.graphql";
import closedThreadSubscription from "../../graphql/subscription/ClosedThreadSubscription.graphql";
import userCreatedOrUpdatedSubscription from "../../graphql/subscription/UserCreatedOrUpdatedSubscription.graphql";
import globalSnackbarEventsSubscription from "../../graphql/subscription/GlobalSnackbarEventsSubscription.graphql";
import {
  refreshCustomerAccountQueries,
  refreshCustomerContactQueries,
  refreshMessageQueries,
  refreshThreadQueries,
  refreshUserQueries,
  removeCustomerAccount,
  removeCustomerContact,
  refreshActivityQuery,
  refreshUnclaimedThreadCountByGroupQuery,
  refreshUnclaimedThreadCountTotalQuery,
  refreshOpenThreadCountByGroupQuery,
  refreshOpenThreadCountTotalQuery,
  refreshOpenThreadCountByUserQuery,
  refreshActiveThreadCountQuery,
  refreshUnclaimedThreadCountForUserByTimeQuery,
} from "./refreshQueries";
// import { setActiveUnclaimedThread } from '../actions';
// import { updateSession } from '../actions/session';
import globalSnackbarHandler from "./globalSnackbarHandler";

export const SET_ACTIVE_UNCLAIMED_THREAD = "SET_ACTIVE_UNCLAIMED_THREAD";
export const UPDATE_SESSION = "UPDATE_SESSION";
export const UPDATE_STOCK_MESSAGES = "UPDATE_STOCK_MESSAGES";
export const UPDATE_DESKTOP_NOTIFICATION_SETTINGS =
  "UPDATE_DESKTOP_NOTIFICATION_SETTINGS";
export const UPDATE_EMAIL_NOTIFICATION_SETTINGS =
  "UPDATE_EMAIL_NOTIFICATION_SETTINGS";
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";

export const setActiveUnclaimedThread = (activeUnclaimedThreadId) => ({
  type: SET_ACTIVE_UNCLAIMED_THREAD,
  activeUnclaimedThreadId,
});

export const updateSession = (payload) => ({
  type: UPDATE_SESSION,
  payload,
});

export const updateStockMessages = (payload) => ({
  type: UPDATE_STOCK_MESSAGES,
  payload,
});

export const updateDesktopNotificationSettings = (payload) => ({
  type: UPDATE_DESKTOP_NOTIFICATION_SETTINGS,
  payload,
});

export const updateEmailNotificationSettings = (payload) => ({
  type: UPDATE_EMAIL_NOTIFICATION_SETTINGS,
  payload,
});

const formatStockMessagesResponse = (stockMessagesArray) =>
  stockMessagesArray.map((value, i) => ({ label: value, key: i + 1 }));

const customerAccountSubscriptionHandler = (apolloClient, response) => {
  const type = response.data.customerAccountSubscription.__typename;

  switch (type) {
    case "CreateCustomerAccountPayload":
      refreshCustomerAccountQueries(
        apolloClient,
        response.data.customerAccountSubscription.customerAccount
      );
      break;
    case "DeleteCustomerAccountPayload":
      removeCustomerAccount(
        apolloClient,
        response.data.customerAccountSubscription.customerAccount.id
      );
      break;
    case "UpdateCustomerAccountPayload":
      refreshCustomerAccountQueries(
        apolloClient,
        response.data.customerAccountSubscription.customerAccount
      );
      break;
    default:
      console.error("CustomerAccountSubscription case fail", type);
  }
};

export const startCustomerAccountSubscription = (store, apolloClient) => {
  const accountId = store.getState().session.currentUser.accountId;

  apolloClient
    .subscribe({
      query: customerAccountSubscription,
      variables: {
        prokeepAccountId: accountId,
        groupIds: [],
      },
    })
    .subscribe(
      (response) => {
        customerAccountSubscriptionHandler(apolloClient, response);
      },
      (e) => {
        console.error(e);
      }
    );
};

const customerContactSubscriptionHandler = (apolloClient, response, store) => {
  const type = response.data.customerContactSubscription.__typename;

  // make sure data comees with customerContact :)
  if (response.data?.customerContactSubscription?.customerContact) {
    switch (type) {
      case "AddCustomerContactToGroupPayload":
        refreshCustomerContactQueries(
          apolloClient,
          response.data.customerContactSubscription.customerContact,
          store
        );
        break;
      case "CreateCustomerContactPayload":
        refreshCustomerContactQueries(
          apolloClient,
          response.data.customerContactSubscription.customerContact,
          store
        );
        break;
      case "DeleteCustomerContactPayload":
        removeCustomerContact(
          apolloClient,
          response.data.customerContactSubscription.customerContact.id,
          store
        );
        break;
      case "RemoveCustomerContactFromGroupPayload":
        refreshCustomerContactQueries(
          apolloClient,
          response.data.customerContactSubscription.customerContact,
          store
        );
        break;
      case "UpdateCustomerContactPayload":
        refreshCustomerContactQueries(
          apolloClient,
          response.data.customerContactSubscription.customerContact,
          store
        );
        break;
      default:
        console.error("CustomerContactSubscription case fail", type);
    }
  }
};

export const startCustomerContactSubscription = (store, apolloClient) => {
  const accountId = store.getState().session.currentUser.accountId;

  apolloClient
    .subscribe({
      query: customerContactSubscription,
      variables: {
        prokeepAccountId: accountId,
        groupIds: [],
      },
    })
    .subscribe(
      (response) => {
        customerContactSubscriptionHandler(apolloClient, response, store);
      },
      (e) => {
        console.error(e);
      }
    );
};

export const startUserCreatedOrUpdatedSubscription = (store, apolloClient) => {
  apolloClient
    .subscribe({
      query: userCreatedOrUpdatedSubscription,
    })
    .subscribe(
      (response) => {
        const userId = store.getState().session.currentUser.userId;

        if (response.data.userCreatedOrUpdated.id === userId) {
          store.dispatch(
            updateSession({
              firstName: response.data.userCreatedOrUpdated.firstName,
              lastName: response.data.userCreatedOrUpdated.lastName,
              theme: response.data.userCreatedOrUpdated.theme,
              fontSize: response.data.userCreatedOrUpdated.fontSize,
              language: response.data.userCreatedOrUpdated.language,
              avatarUrl: response.data.userCreatedOrUpdated.avatarUrl,
              role: response.data.userCreatedOrUpdated.role,
              announcementsAccess:
                response.data.userCreatedOrUpdated.announcementsAccess,
              title: response.data.userCreatedOrUpdated.title,
            })
          );

          store.dispatch(
            updateStockMessages(
              formatStockMessagesResponse(
                response.data.userCreatedOrUpdated.stockMessages
              )
            )
          );

          store.dispatch(
            updateDesktopNotificationSettings({
              notificationSound:
                response.data.userCreatedOrUpdated.notificationSound,
              notificationInterval:
                response.data.userCreatedOrUpdated.notificationInterval,
              notificationSettingsLocked:
                response.data.userCreatedOrUpdated.notificationSettingsLocked,
              groupInboxNotificationsEnabled:
                response.data.userCreatedOrUpdated
                  .groupInboxNotificationsEnabled,
            })
          );

          store.dispatch(
            updateEmailNotificationSettings({
              emailNotificationsEnabled:
                response.data.userCreatedOrUpdated.emailNotificationsEnabled,
              limitEmailNotifications:
                response.data.userCreatedOrUpdated.limitEmailNotifications,
              emailNotificationDelayMins:
                response.data.userCreatedOrUpdated.emailNotificationDelayMins,
              unclaimedThreadEmailNotificationsEnabled:
                response.data.userCreatedOrUpdated
                  .unclaimedThreadEmailNotificationsEnabled,
              unreadThreadEmailNotificationsEnabled:
                response.data.userCreatedOrUpdated
                  .unreadThreadEmailNotificationsEnabled,
            })
          );
        }

        refreshUserQueries(apolloClient, response.data.userCreatedOrUpdated);
      },
      (e) => {
        console.error(e);
      }
    );
};

export const openThreadSubscriptionHandler = (store, apolloClient, thread) => {
  const activeUnclaimedThreadId =
    store.getState().threadInfo.activeUnclaimedThreadId;

  if (activeUnclaimedThreadId === thread.id && thread.ownerContact !== null) {
    store.dispatch(setActiveUnclaimedThread(""));
  }

  refreshThreadQueries(apolloClient, thread, store);

  const threadHistoryResponse = {
    data: {
      threadHistory: thread?.latestMessage,
    },
  };

  messageSubscriptionHandler(store, apolloClient, threadHistoryResponse);
};

export const startOpenThreadsSubscription = (store, apolloClient, groupId) => {
  apolloClient
    .subscribe({
      query: openThreadSubscription,
      variables: { groupIds: [groupId] },
    })
    .subscribe(
      (response) => {
        openThreadSubscriptionHandler(store, apolloClient, response);
      },
      (e) => {
        console.error(e);
      }
    );
};

const openThreadForUserSubscriptionHandler = (
  store,
  apolloClient,
  response
) => {
  const thread = response.data.openThreadForUser;

  const activeUnclaimedThreadId =
    store.getState().threadInfo.activeUnclaimedThreadId;

  if (activeUnclaimedThreadId === thread.id && thread.ownerContact !== null) {
    store.dispatch(setActiveUnclaimedThread(""));
  }

  refreshThreadQueries(apolloClient, thread, store);

  const threadHistoryResponse = {
    data: {
      threadHistory: thread?.latestMessage,
    },
  };

  messageSubscriptionHandler(store, apolloClient, threadHistoryResponse);
};

export const startOpenThreadForUserSubscription = (store, apolloClient) => {
  const userId = store.getState()?.session?.currentUser?.userId;

  if (userId) {
    apolloClient
      .subscribe({
        query: openThreadForUserSubscription,
        variables: { userId },
      })
      .subscribe(
        (response) => {
          openThreadForUserSubscriptionHandler(store, apolloClient, response);
        },
        (e) => {
          console.error(e);
        }
      );
  }
};

const closedThreadSubscriptionHandler = (store, apolloClient, response) => {
  const thread = response.data.closedThread;
  const activeUnclaimedThreadId =
    store.getState().threadInfo.activeUnclaimedThreadId;

  if (activeUnclaimedThreadId === thread.id && thread.ownerContact !== null) {
    store.dispatch(setActiveUnclaimedThread(""));
  }

  refreshThreadQueries(apolloClient, thread, store);
};

export const startClosedThreadsSubscription = (store, apolloClient) => {
  apolloClient
    .subscribe({
      query: closedThreadSubscription,
    })
    .subscribe(
      (response) => {
        closedThreadSubscriptionHandler(store, apolloClient, response);
      },
      (e) => {
        console.error(e);
      }
    );
};

export const messageSubscriptionHandler = (store, apolloClient, response) => {
  refreshMessageQueries(apolloClient, response.data.threadHistory);
};

export const startMessageCreatedOrUpdatedSubscription = (
  store,
  apolloClient
) => {
  apolloClient
    .subscribe({
      query: messageCreatedOrUpdatedSubscription,
    })
    .subscribe(
      (response) => {
        messageSubscriptionHandler(store, apolloClient, response);
      },
      (e) => {
        console.error(e);
      }
    );
};

export const startGlobalSnackbarSubscription = (store, apolloClient) => {
  apolloClient
    .subscribe({
      query: globalSnackbarEventsSubscription,
    })
    .subscribe(
      (response) => {
        globalSnackbarHandler(store, response);
      },
      (e) => {
        console.error(e);
      }
    );
};

export const activityCreatedSubscriptionHandler = (
  apolloClient,
  response,
  queryVariables
) => {
  refreshActivityQuery(apolloClient, response, queryVariables);
};

export const unclaimedThreadCountTotalSubscriptionHandler = (
  apolloClient,
  response,
  variables = {}
) => {
  refreshUnclaimedThreadCountTotalQuery(apolloClient, response, variables);
};

export const unclaimedThreadCountForUserByTimeSubscriptionHandler = (
  apolloClient,
  response,
  variables = {}
) => {
  refreshUnclaimedThreadCountForUserByTimeQuery(
    apolloClient,
    response,
    variables
  );
};

export const unclaimedThreadCountForUserByGroupSubscriptionHandler = (
  apolloClient,
  response,
  variables
) => {
  refreshUnclaimedThreadCountByGroupQuery(apolloClient, response, variables);
};

export const openThreadCountTotalSubscriptionHandler = (
  apolloClient,
  response,
  variables,
  isCurrentUserAdmin
) => {
  refreshOpenThreadCountTotalQuery(
    apolloClient,
    response,
    variables,
    isCurrentUserAdmin
  );
};

export const openThreadCountForUserByGroupSubscriptionHandler = (
  apolloClient,
  response,
  variables,
  isCurrentUserAdmin
) => {
  refreshOpenThreadCountByGroupQuery(
    apolloClient,
    response,
    variables,
    isCurrentUserAdmin
  );
};

// Note: This is no longer being actively used in the application since
// subscriptions were removed from the dashboard.
export const openThreadCountForUserByUserSubscriptionHandler = (
  apolloClient,
  response,
  variables
) => {
  refreshOpenThreadCountByUserQuery(apolloClient, response, variables);
};

// Note: This is no longer being actively used in the application since
// subscriptions were removed from the dashboard.
export const activeThreadCountForUserSubscriptionHandler = (
  apolloClient,
  response,
  variables,
  isCurrentUserAdmin
) => {
  refreshActiveThreadCountQuery(
    apolloClient,
    response,
    variables,
    isCurrentUserAdmin
  );
};

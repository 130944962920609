import React from 'react';
import i18n from 'i18n-js';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { IconButton, Text } from '../../../../elements';
import GroupMessageTemplate from '../GroupMessageTemplate';
import OverviewItem from './subComponents/OverviewItem';
import { ANNOUNCEMENT_TYPES } from '../GroupMessageWizard';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 850px;
  max-width: 85%;
  position: relative;
`;

const GroupMessageType = ({
  closeWizard,
  setWizardPage,
  wizardStateSetters,
}) => {
  const nextStep = (type) => {
    wizardStateSetters.setAnnouncementType(type);
    setWizardPage('GroupMessageOverviewCombined');
  };

  return (
    <GroupMessageTemplate
      title={i18n.t('slideouts-GroupMessageName-createGroupBroadcast')}
      headerRightElement={
        <IconButton size="lg" onClick={closeWizard}>
          <i className="ri-close-line" />
        </IconButton>
      }
      showFooter
      continueButtonText={i18n.t('slideouts-GroupMessageName-saveContinue')}
      continueButtonAction={() => null}
      continueButtonDisabled={false}
      backButtonAction={closeWizard}
    >
      <Container>
        <OverviewItem
          title={i18n.t('broadcasts-list-promotional')}
          subTitle={
            <Text>{i18n.t('broadcasts-list-promotionalDescription')}</Text>
          }
          onClickArrow={() => nextStep(ANNOUNCEMENT_TYPES.PROMOTIONAL)}
          allowNextStep
          completed={false}
        />
        <OverviewItem
          title={i18n.t('broadcasts-list-informational')}
          subTitle={
            <Text>{i18n.t('broadcasts-list-informationalDescription')}</Text>
          }
          onClickArrow={() => nextStep(ANNOUNCEMENT_TYPES.INFORMATIONAL)}
          allowNextStep
          completed={false}
        />
      </Container>
    </GroupMessageTemplate>
  );
};

GroupMessageType.propTypes = {
  closeWizard: PropTypes.func.isRequired,
  setWizardPage: PropTypes.func.isRequired,
  wizardStateSetters: PropTypes.object.isRequired,
};

export default GroupMessageType;

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useApolloClient, useQuery } from '@apollo/client';
import {
  CONTACTS_QUERY,
  useAccountQuery,
  usePaginated,
  GET_TASK_QUERY,
  type ApolloClientLib,
} from 'client-lib';

import { Loading } from '../../../elements';
import {
  closeCreateSectionModal,
  setActiveLoseProgressState,
  setActiveSidebar,
} from '../../../actions/general';
import { clearCreateSection } from '../../../actions/createSection';

import type { AppState } from '../../../utils/helpers/types';
import Wizard from '../index.tsx';
import CreateAndEditTask from '../../Tasks/CreateAndEditTask.tsx';
import TaskAudienceBuilder from './pages/TaskAudienceBuilder.tsx';
import type {
  TaskFormProps,
  TaskWizardGlobalProps,
  SelectedAudience,
} from './types.ts';
import type { ContactsQueryFilters } from '../../../elements/FilterBy/ContactsFilterBy.tsx';
import {
  handleClearSelectedAudience,
  handleAudienceSelection,
  type HandleAudienceSelectionParams,
} from './helpers/utils';

const TaskWizardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const LoadingWrapper = styled.div`
  min-width: 400px;
  max-width: 960px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * TaskWizard Component
 *
 * Manages the task creation wizard and audience selection process.
 */
const TaskWizard: React.FC = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const history = useHistory();

  // State Management
  const [selectedAudience, setSelectedAudience] = useState<SelectedAudience>({
    contactIds: [],
    groupIds: [],
    companyIds: [],
    labelIds: [],
    tagIds: [],
    selectAll: false,
    wasSelectAll: false,
    excludedContactIds: [],
    excludedCompanyIds: [],
  });
  const [previousAudience, setPreviousAudience] =
    useState<SelectedAudience | null>(null);
  const [audienceFilters, setAudienceFilters] = useState<ContactsQueryFilters>(
    {}
  );
  const [search, setSearch] = useState('');
  const [taskIsSubmitting, setTaskIsSubmitting] = useState(false);
  const [formProps, setFormProps] = useState<TaskFormProps>({
    fields: undefined,
    onSubmit: () => {},
    isVisitedForm: false,
    updateField: () => {},
  });

  // Redux State
  const taskId = useSelector(
    (state: AppState) => state?.createSection?.createTask?.id
  );
  const allGroups = useSelector(
    (state: AppState) => state?.accountData?.allGroups
  );
  const currentUser = useSelector(
    (state: AppState) => state?.session?.currentUser
  );

  // Queries
  const { data: accountData } = useAccountQuery({
    client,
    variables: { id: currentUser?.accountId },
    skip: !currentUser,
  });

  const {
    called: tasksCalled,
    data: taskData,
    loading: taskLoading,
  } = useQuery(GET_TASK_QUERY, {
    client,
    variables: { id: taskId },
    fetchPolicy: 'network-only',
    skip: !taskId,
  });

  // Audience Management - Paginated Contacts
  const {
    contacts,
    handleFetchMore,
    loading: contactsLoading,
    pageInfo,
    extraParameters,
  } = usePaginated({
    client: client as unknown as ApolloClientLib,
    key: 'contacts',
    query: CONTACTS_QUERY,
    queryVariables: {
      ...audienceFilters,
      search,
      groupIds: selectedAudience.groupIds,
      labelIds: selectedAudience.labelIds,
      contactIds: selectedAudience.contactIds,
      companyIds: selectedAudience.companyIds,
      tagIds: selectedAudience.tagIds,
      audienceBuilder: true,
      conversationsOptOut: false,
      returnAllIds: true,
    },
  });

  // Derived Data
  const contactsForBulkTaskCreate = extraParameters?.all_contact_ids;
  const selectedAudienceCount = pageInfo?.totalCount || 0;
  const memoizedTaskData = useMemo(() => taskData?.task?.task, [taskData]);
  const memoizedGroups = useMemo(() => allGroups, [allGroups]);

  // Determine if we should render the form
  const shouldRenderForm = useMemo(() => {
    if (taskId) {
      return !taskLoading && tasksCalled;
    }
    return true;
  }, [taskId, taskLoading, tasksCalled]);

  // Callbacks
  const handleFormReady = useCallback((props: TaskFormProps) => {
    setFormProps((prevProps: TaskFormProps) => {
      if (JSON.stringify(prevProps) !== JSON.stringify(props)) {
        return props;
      }
      return prevProps;
    });
  }, []);

  const closeModal = useCallback(() => {
    dispatch(setActiveLoseProgressState(false));
    dispatch(closeCreateSectionModal());
    dispatch(clearCreateSection());
    dispatch(setActiveSidebar('default'));
    history.push('/tasks');
  }, [dispatch, history]);

  const handleClearSelectedAudienceCallback = useCallback(() => {
    setSelectedAudience(handleClearSelectedAudience(previousAudience));
  }, [previousAudience]);

  const handleAudienceSelectedCallback = useCallback(
    (params: HandleAudienceSelectionParams) => {
      setSelectedAudience((prevAudience) =>
        handleAudienceSelection(prevAudience, {
          ...params,
          // Add additional context from current state
          selectAllFilters: params.selectAll
            ? { search, ...audienceFilters }
            : undefined,
          allContactIds: extraParameters?.all_contact_ids,
          allCompanyIds: extraParameters?.all_company_ids,
        })
      );
    },
    [search, audienceFilters, extraParameters]
  );

  const handleSaveAndExit = useCallback((audience: SelectedAudience) => {
    setPreviousAudience(audience);
  }, []);

  // Effect to handle changes in filters when select all is active
  useEffect(() => {
    if (selectedAudience.selectAll) {
      // When filters/search change, turn off "select all" mode but keep explicit selections
      handleAudienceSelectedCallback({
        selectAll: false,
        wasSelectAll: true,
      });
    }
  }, [
    search,
    audienceFilters,
    handleAudienceSelectedCallback,
    selectedAudience.selectAll,
  ]);

  // Props for wizard child components
  const wizardGlobalProps: TaskWizardGlobalProps = useMemo(
    () => ({
      closeModal,
      taskData: memoizedTaskData,
      groups: memoizedGroups,
      taskId,
      taskIsSubmitting,
      setTaskIsSubmitting,
      onFormReady: handleFormReady,
      accountData,
      selectedAudience,
      paginatedContactList: contacts,
      contactsLoading,
      handleFetchMoreContacts: handleFetchMore,
      contactPageInfo: pageInfo,
      selectedAudienceCount,
      contactsForBulkTaskCreate,
      search,
      setSearch,
      contactFilters: audienceFilters,
      setContactFilters: setAudienceFilters,
      handleAudienceSelected: handleAudienceSelectedCallback,
      taskRecipient: memoizedTaskData?.recipient,
      templateMessage: memoizedTaskData?.template?.message,
      isEditingAudience: !!previousAudience,
      handleSaveAndExit,
      setSelectedAudience,
      ...formProps,
    }),
    [
      closeModal,
      memoizedTaskData,
      memoizedGroups,
      taskId,
      taskIsSubmitting,
      handleFormReady,
      accountData,
      selectedAudience,
      contacts,
      contactsLoading,
      handleFetchMore,
      pageInfo,
      selectedAudienceCount,
      contactsForBulkTaskCreate,
      search,
      audienceFilters,
      handleAudienceSelectedCallback,
      previousAudience,
      handleSaveAndExit,
      formProps,
    ]
  );

  // Render loading state if needed
  if (!shouldRenderForm) {
    return (
      <TaskWizardContainer data-testid="task-wizard">
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      </TaskWizardContainer>
    );
  }

  // Render wizard
  return (
    <TaskWizardContainer data-testid="task-wizard">
      <Wizard<TaskWizardGlobalProps>
        pages={{
          CreateAndEditTask: {
            start: true,
            component: CreateAndEditTask,
          },
          TaskAudience: {
            start: false,
            component: TaskAudienceBuilder,
          },
        }}
        wizardGlobalProps={wizardGlobalProps}
        closeWizard={handleClearSelectedAudienceCallback}
        setWizardPageOverride={() => {}}
      />
    </TaskWizardContainer>
  );
};

export default TaskWizard;

import type { useHistory } from 'react-router';

/**
 * Decides which view to return home to based on permissions
 * @param dashboardPagePermission - does the user have permission to view the dashboard page?
 * @param feedbackPagePermission - does the user have permission to view the feedback page?
 * @param history, optional
 * @returns route (and if history is provided, pushes new route)
 */
export const navigateHome = (
  dashboardPagePermission: boolean,
  feedbackPagePermission: boolean,
  history: ReturnType<typeof useHistory> | null = null
): string => {
  let route = '';
  if (dashboardPagePermission) route = '/dashboard';
  else if (!dashboardPagePermission && feedbackPagePermission)
    route = '/feedback';
  else route = '/threads/inbox';

  if (history) history.push(route);
  return route;
};

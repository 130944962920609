import React from 'react';
import styled from 'styled-components';
import SortBy, {
  type SortByOptionType,
} from '../../../../../elements/SortBy/SortBy.tsx';
import ContactsFilterBy, {
  SelectedInputs,
  type ContactsQueryFilters,
} from '../../../../../elements/FilterBy/ContactsFilterBy.tsx';

const FilterSortContainer = styled.div`
  display: flex;
  gap: 8px;
  div {
    margin-top: unset;
  }
`;

type Option = {
  label: string;
  value: string;
};

export interface AudienceFilterSortProps {
  handleSubmitFilter?: (filters: ContactsQueryFilters) => void;
  handleSubmitSort: (args: { label: string; value: string }) => void;
  sortBy?: SortByOptionType;
  selectedInputs?: SelectedInputs[];
  hasFilter?: boolean;
  sortOptions: Option[];
}

const AudienceFilterSort = ({
  handleSubmitFilter,
  handleSubmitSort,
  sortBy,
  selectedInputs,
  hasFilter = false,
  sortOptions = [],
}: AudienceFilterSortProps) => {
  // Applies new sort direction
  const handleSort = (_: SortByOptionType, ind: number) => {
    const selectedSortOption = sortOptions[ind];
    if (selectedSortOption) {
      handleSubmitSort(selectedSortOption);
    }
  };

  return (
    <FilterSortContainer>
      {hasFilter && handleSubmitFilter && (
        // This div allows the filter bubble to be positioned correctly
        <div>
          <ContactsFilterBy
            handleApplyFilters={handleSubmitFilter}
            dataTestId="create-broadcastList-filterBy"
            selectedInputs={selectedInputs}
            bubbleProps={{
              classesNotOutside: ['Select__clear-indicator'],
            }}
          />
        </div>
      )}
      <SortBy
        options={sortOptions}
        onSelect={handleSort}
        selection={sortBy}
        dataTestId="AudienceFilterSort"
      />
    </FilterSortContainer>
  );
};

export default AudienceFilterSort;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  AvatarWrap,
  iconButtonStyle,
  WhitelabelImg,
  WhitelabelLogoWrap,
} from './styles';
import THEMES from '../../styles/themes/app';
import { Button } from '../../elements';
import { navigateHome } from './utils.ts';
import AppLogo from './AppLogo.tsx';

const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: ${THEMES.SIDEBAR_BACKGROUND};
`;

const SettingsSidebar = ({
  whitelabelLogo,
  dashboardPagePermission,
  feedbackPagePermission,
  history,
  onExit,
}) => {
  return (
    <Wrapper data-testid="settings-sidebar">
      {whitelabelLogo ? (
        <WhitelabelLogoWrap>
          <WhitelabelImg
            src={whitelabelLogo}
            onClick={() =>
              navigateHome(
                dashboardPagePermission,
                feedbackPagePermission,
                history
              )
            }
          />
        </WhitelabelLogoWrap>
      ) : (
        <AvatarWrap
          data-testid="sidebar-avatar"
          onClick={() =>
            navigateHome(
              dashboardPagePermission,
              feedbackPagePermission,
              history
            )
          }
        >
          <AppLogo />
        </AvatarWrap>
      )}
      <div style={{ marginTop: 'auto', marginBottom: 20 }}>
        <Button
          type="noStyle"
          size="zero"
          noOutline
          customStyle={iconButtonStyle}
          onClick={() => {
            onExit();
            history.push('/threads/inbox');
          }}
        >
          <i className="ri-close-fill" data-testid="sidebar-close" />
        </Button>
      </div>
    </Wrapper>
  );
};

SettingsSidebar.propTypes = {
  dashboardPagePermission: PropTypes.bool.isRequired,
  feedbackPagePermission: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  onExit: PropTypes.func,
  whitelabelLogo: PropTypes.string,
};

SettingsSidebar.defaultProps = {
  onExit: null,
  whitelabelLogo: null,
};

export default SettingsSidebar;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Sound extends Component {
  // eslint-disable-next-line react/no-unused-class-component-methods
  playSound = () => {
    const playPromise = this.audio.play();

    if (playPromise !== undefined) {
      playPromise.catch((e) => {
        console.error(`${e}`);
      });
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  loadAudio = () => {
    this.audio.load();
  };

  render() {
    const routeSound = this.props.routeSound.toLowerCase();

    const noSound = routeSound === 'none';

    return (
      <audio
        id="sound"
        preload="auto"
        ref={(audio) => {
          this.audio = audio;
        }}
      >
        <source
          src={noSound ? '' : `/sounds/alert-sound-${routeSound}.mp3`}
          type="audio/mpeg"
        />
        <source
          src={noSound ? '' : `/sounds/alert-sound-${routeSound}.ogg`}
          type="audio/ogg"
        />
        <embed
          hidden
          src={noSound ? '' : `/sounds/alert-sound-${routeSound}.mp3`}
        />
      </audio>
    );
  }
}

Sound.propTypes = {
  routeSound: PropTypes.string.isRequired,
};
export default Sound;

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useParams } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'styled-theming';
import THEMES from '../../styles/themes/app';
import BUTTON_THEMES from '../../styles/themes/library/button';
import { EmphasisText, Badge } from '../../elements';
import COLORS from '../../styles/colors';

// generic colors
export const SELECTION_INDICATOR = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_NAVY,
  light: COLORS.PK.BLUE_100,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});

export const SELECTION_INDICATOR_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.BLUE_60,
  dark: COLORS.PK.BLUE_40,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_ACCENT_2,
});

export const SELECTION_INDICATOR_UNSELECTED = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_50,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_LOW,
  holiday: COLORS.PK.GREY_50,
});

const SelectionIndicator = styled.div`
  height: 5px;
  width: 100%;
  background-color: ${(props) =>
    props.isSelected ? SELECTION_INDICATOR : SELECTION_INDICATOR_UNSELECTED};
`;

const TabNavLink = styled(NavLink)`
  display: inline-grid;
  height: 100%;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  justify-items: center;
  align-items: flex-end;
  color: ${THEMES.FOREGROUND_HIGH};
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;

  &:hover div.selection-indicator {
    background-color: ${SELECTION_INDICATOR_HOVER} !important;
  }
  &:focus {
    z-index: 3;
    outline-offset: -1px;
    outline-color: ${BUTTON_THEMES.BUTTON_OUTLINE};
  }
`;

const BadgeContainer = styled.div`
  margin-left: 4px;
`;

const FilterAndBadgeContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const TruncateOverflowEmphasisText = styled(EmphasisText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: center;
  max-width: 100%;
`;

const Bar = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: ${(props) =>
    props.threeTabs ? '33% 34% 33%' : '50% 50%'};
  height: 35px;
  width: 100%;
`;

const ScopeTabBar = ({ links, onTabClick, threeTabs }) => {
  const { filter } = useParams();

  return (
    <div>
      <Bar threeTabs={threeTabs} data-testid="scope-tab-bar">
        {links.map((link) => (
          <TabNavLink
            key={link.filter}
            id={link.filter}
            to={link.route}
            onClick={() => onTabClick(link.filter)}
            data-testid={link?.dataTestId ?? `scope-tab-${link.filter}`}
          >
            <FilterAndBadgeContainer title={link.label}>
              <TruncateOverflowEmphasisText>
                {link.label}
              </TruncateOverflowEmphasisText>
              {link.badgeValue ? (
                <BadgeContainer>
                  <Badge>{link.badgeValue}</Badge>
                </BadgeContainer>
              ) : null}
            </FilterAndBadgeContainer>
            <SelectionIndicator
              className="selection-indicator"
              isSelected={filter === link.filter}
            />
          </TabNavLink>
        ))}
      </Bar>
    </div>
  );
};

ScopeTabBar.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTabClick: PropTypes.func,
  threeTabs: PropTypes.bool,
};

ScopeTabBar.defaultProps = {
  onTabClick: () => {},
  threeTabs: false,
};

export default ScopeTabBar;

import React from 'react';
import { default as ReactSelectAsync } from 'react-select/async'; // eslint-disable-line import/no-named-default
import i18n from 'i18n-js';
import InputLabel from '../../inputCommonElements/InputLabel.ts';
import InputError from '../../inputCommonElements/InputError.tsx';
import { Container } from '../shared/styles.ts';
import type {
  AsyncLoadOptionsFunction,
  SelectOnChange,
  RSOptionRef,
  SingleOrMultiValue,
} from '../shared/types.ts';
import {
  createDropdownIndicator,
  createMultiValueChip,
  createNoOptionComponent,
  createOption,
} from '../shared/utils.tsx';

export interface AsyncSelectProps {
  customContainerStyle?: () => string;
  customInputStyle?: () => string;
  customLabelStyle?: () => string;
  customOptionStyle?: () => string;
  dataTestId?: string;
  disabled?: boolean;
  error?: string;
  helperText?: string;
  hideBottomSpace?: boolean;
  isClearable: boolean;
  isMulti: boolean;
  label: string;
  loading?: boolean;
  loadOptions: AsyncLoadOptionsFunction;
  onChange: SelectOnChange;
  placeholder?: string;
  value: SingleOrMultiValue;
}

const AsyncSelect = React.forwardRef(
  (
    {
      customContainerStyle,
      customInputStyle,
      customLabelStyle,
      customOptionStyle,
      dataTestId,
      disabled,
      error,
      helperText,
      hideBottomSpace,
      isMulti,
      label,
      loadOptions,
      onChange,
      placeholder,
      value,
      ...otherProps
    }: AsyncSelectProps,
    ref
  ) => {
    const chipLabel = `selected-chip${dataTestId ? `-${dataTestId}` : ''}`;
    const selectClass = `AsyncSelect${dataTestId ? `-${dataTestId}` : ''}`;

    return (
      <Container
        prefix={selectClass}
        error={error}
        disabled={disabled}
        customContainerStyle={customContainerStyle}
      >
        {label ? (
          <InputLabel
            error={error}
            disabled={disabled}
            customLabelStyle={customLabelStyle}
          >
            {label}
          </InputLabel>
        ) : null}
        <ReactSelectAsync
          // @ts-expect-error override ref
          ref={ref}
          loadOptions={loadOptions}
          value={value}
          onChange={onChange}
          error={error}
          disabled={disabled}
          isDisabled={disabled}
          placeholder={placeholder}
          menuPlacement="auto"
          classNamePrefix={selectClass}
          noOptionsMessage={() =>
            i18n.t('customers-InfiniteScroll-noResultsFound')
          }
          components={{
            DropdownIndicator: (props) =>
              createDropdownIndicator({ props, dataTestId }),
            Option: (props) =>
              createOption({
                props,
                customOptionStyle,
                dataTestId,
                isMulti,
                ref: ref as RSOptionRef,
              }),
            MultiValueContainer: (props) =>
              createMultiValueChip({ props, dataTestId: chipLabel }),
            NoOptionsMessage: (props) =>
              createNoOptionComponent({
                props,
                dataTestId,
                ref: ref as RSOptionRef,
              }),
          }}
          customInputStyle={customInputStyle}
          customOptionStyle={customOptionStyle}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          hideSelectedOptions={!isMulti}
          defaultOptions
          cacheOptions
          {...otherProps}
        />
        {!hideBottomSpace && (
          <InputError error={error} helperText={helperText} />
        )}
      </Container>
    );
  }
);

AsyncSelect.displayName = 'AsyncSelect';

export default AsyncSelect;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from './Button';

const ButtonSelectorContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  gap: 8px;

  & button {
    border-width: 2px;
  }
`;

const ButtonSelector = ({ options, selectedValues, onChange, ...rest }) => (
  <ButtonSelectorContainer>
    {options.map((option) => (
      <Button
        onClick={() => onChange(option.value)}
        type={
          selectedValues.some((value) => option.value === value)
            ? 'buttonSelector'
            : 'secondary'
        }
        {...rest}
        {...option?.props}
        dataTestId={`button-selector-${option.value}`}
      >
        {option.label}
      </Button>
    ))}
  </ButtonSelectorContainer>
);

ButtonSelector.propTypes = {
  options: PropTypes.array.isRequired,
  selectedValues: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ButtonSelector;

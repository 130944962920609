import React from 'react';
import { type Task } from 'client-lib/src/lib/controllers/hooks/tasks/types';
import moment from 'moment';
import styled from 'styled-components';
import { IconLabel, Text } from '../../../elements';
import COLORS from '../../../styles/colors';
import {
  type CustomerContact,
  type I18NType,
} from '../../../utils/helpers/types';

const ErrorMessage = styled.span`
  color: ${COLORS.PK.RED};
`;

const DueDateYesterday = styled.span`
  color: ${COLORS.PK.RED};
  display: flex;
  align-items: center;
`;

export const mapCustomerRespondedToText = (
  i18n: I18NType,
  customerResponded?: boolean
): string => {
  if (customerResponded) {
    return i18n.t('tasks-general-yes', {
      defaultValue: 'Yes',
    });
  }

  return i18n.t('settings-FinalMessage-no', {
    defaultValue: 'No',
  });
};

export const formatDueDateField = (task: Task | undefined, i18n: I18NType) => {
  const dueDate = moment(task?.dueDate);
  const now = moment();

  const translateDate = (date: moment.Moment) => {
    const currentLanguage = i18n.locale;
    const day = i18n.t(`date.abbr_day_names.${date.day()}`, {
      defaultValue: date.format('ddd'),
    });
    const month = i18n.t(`date.abbr_month_names.${date.month() + 1}`, {
      defaultValue: date.format('MMM'),
    });

    let formattedDueDate;

    if (currentLanguage === 'es-MX')
      formattedDueDate = `${day} ${date.date()} de ${month.toLocaleLowerCase()}. del ${date.year()}`;

    if (currentLanguage === 'fr-CA')
      formattedDueDate = `${day} ${date.date()} ${month.toLocaleLowerCase()} ${date.year()}`;

    if (currentLanguage === 'en-US')
      formattedDueDate = `${day}, ${month} ${date.date()}, ${date.year()}`;

    return formattedDueDate;
  };

  let formattedDueDate = translateDate(dueDate);
  let styledDiv;

  if (dueDate.isSame(now, 'day')) {
    formattedDueDate = i18n.t('tasks-general-dueDateToday', {
      defaultValue: 'Due today',
    });
    styledDiv = (
      <ErrorMessage title={formattedDueDate}>{formattedDueDate}</ErrorMessage>
    );
  } else if (dueDate.isSame(now.clone().subtract(1, 'day'), 'day')) {
    formattedDueDate = i18n.t('tasks-general-dueDateOneDay', {
      defaultValue: '1d ago',
    });
    styledDiv = (
      <DueDateYesterday title={formattedDueDate}>
        <IconLabel
          size="sm"
          noOutline
          customStyle={() => `
            color: ${COLORS.PK.RED};
            pointer-events: none;
          `}
        >
          <i className="ri-information-fill" />
        </IconLabel>
        {formattedDueDate}
      </DueDateYesterday>
    );
  } else if (dueDate.isSame(now.clone().add(1, 'day'), 'day')) {
    formattedDueDate = i18n.t('tasks-general-dueDateTomorrow', {
      defaultValue: 'Tomorrow',
    });
    styledDiv = <Text title={formattedDueDate}>{formattedDueDate}</Text>;
  } else if (dueDate.isBefore(now.clone().subtract(1, 'day'))) {
    styledDiv = (
      <ErrorMessage title={formattedDueDate}>{formattedDueDate}</ErrorMessage>
    );
  } else {
    styledDiv = <Text title={formattedDueDate}>{formattedDueDate}</Text>;
  }

  return styledDiv;
};

export const buildUpsertTaskResponse = (
  i18n: I18NType,
  selectedContactsLength: number = 0,
  hasInitialFormValues: boolean = false
) => {
  let successMessage;

  if (selectedContactsLength > 0) {
    successMessage = i18n.t('tasks-bulkCreate-notifyOnJobStarted', {
      taskCount: selectedContactsLength,
      defaultValue: `${selectedContactsLength} tasks were successfully created.`,
    });
  } else if (hasInitialFormValues) {
    successMessage = i18n.t('tasks-update-success', {
      defaultValue: 'Your task was successfully updated.',
    });
  } else {
    successMessage = i18n.t('tasks-create-success', {
      defaultValue: 'Your task was successfully created.',
    });
  }

  return successMessage;
};

export const buildSelectedContactsMessage = (
  i18n: I18NType,
  contactCount: number = 0
) => {
  if (contactCount === 0) {
    return i18n.t('tasks-bulkCreate-noneSelected', {
      defaultValue: 'None Selected',
    });
  }

  const contactText =
    contactCount === 1
      ? i18n.t('customers-ContactsSectionContactActivity-contact', {
          defaultValue: 'Contact',
        })
      : i18n.t('customers-CustomerList-contacts', {
          defaultValue: 'Contacts',
        });

  return `${contactCount} ${contactText}`;
};

export const isContact = (
  recipient: CustomerContact | string[] | null
): recipient is CustomerContact =>
  recipient !== null && !Array.isArray(recipient) && 'firstName' in recipient;

export const getChannelType = (
  i18n: I18NType,
  channelType: string | null | undefined
): string => {
  if (channelType === 'SMS') {
    return i18n.t('tasks-create-message-selection-sms', {
      defaultValue: 'Text Message',
    });
  }

  if (channelType === 'email') {
    return i18n.t('tasks-create-message-selection-email', {
      defaultValue: 'Email',
    });
  }

  return '';
};

export const getTranslatedTaskStatus = (
  i18n: I18NType,
  task?: Task
): string => {
  if (task?.completedAt) {
    return i18n.t('threads-Threads-close', {
      defaultValue: 'Closed',
    });
  }

  if (task?.assignee?.id) {
    return i18n.t('threads-Threads-open', {
      defaultValue: 'Open',
    });
  }

  if (task && task?.assignee === null) {
    return i18n.t('tasks-general-unassigned', {
      defaultValue: 'Unassigned',
    });
  }

  return i18n.t('settings-manageRules-unknownContactOptedStatus', {
    defaultValue: 'Unknown Status',
  });
};

import React, { useState, useCallback, useMemo } from 'react';
import i18n from 'i18n-js';
import { useApolloClient } from '@apollo/client';
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
  CONTACTS_QUERY,
  GROUPS_V2_NAMES_QUERY,
  LABELS_QUERY,
  TAGS_V2_QUERY,
  usePaginated,
  type ApolloClientLib,
} from 'client-lib';
import { useSelector } from 'react-redux';
import CreateBroadcastListTemplate from '../../createBroadcastList/CreateBroadcastListTemplate';
import { TabList } from '../../../../elements';
import TaskContactsTable from '../TaskContactsTable';
import SearchBar from '../../../SearchBar/SearchBar';
import GroupsTable from '../../shared/AudienceBuilderTables/GroupsTable';
import ListsTable from '../../shared/AudienceBuilderTables/ListsTable';

import { Container, Header, customInputStyle } from './styles';
import { getSortOptions } from '../../shared/utils';
import type { AppState } from '../../../../utils/helpers/types';
import LabelsTable from '../../shared/AudienceBuilderTables/LabelsTable';
import type { TaskWizardGlobalProps } from '../types';
import { BULK_TASK } from '../../groupMessage/types';
import type { ContentProps } from '../..';
import type { ContactsQueryFilters } from '../../../../elements/FilterBy/ContactsFilterBy';
import type { HandleAudienceSelectionParams } from '../helpers/utils';

export const TABS = {
  CONTACTS: 'contacts',
  GROUPS: 'groups',
  LABELS: 'labels',
  BROADCAST_LISTS: 'broadcastLists',
};

const TaskAudienceBuilder: React.FC<ContentProps<TaskWizardGlobalProps>> = ({
  wizardGlobalProps,
  setWizardPage,
  closeWizard,
}) => {
  const {
    search,
    setSearch,
    contactFilters,
    setContactFilters,
    selectedAudience,
    selectedAudienceCount,
    handleSaveAndExit,
    handleAudienceSelected,
  } = wizardGlobalProps;

  const currentUser = useSelector(
    (state: AppState) => state?.session?.currentUser
  );

  const hasLabelsFF = useSelector(
    (state: AppState) => state.accountData.account?.ff_contact_labels
  );

  const hasBTMListPermissions = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_ANNOUNCEMENT,
    currentUser?.accountPolicies || [],
    currentUser?.groupPolicies || []
  );

  const [selectedTab, setSelectedTab] = useState(TABS.CONTACTS);

  // Sort States
  const ascending = useMemo(() => {
    return (
      getSortOptions(i18n)[0] || { label: 'Ascending (A-Z)', value: 'ASC' }
    );
  }, []);
  const [contactSort, setContactSort] = useState(ascending);
  const [groupSort, setGroupSort] = useState(ascending);
  const [listSort, setListSort] = useState(ascending);
  const [labelsSort, setLabelsSort] = useState(ascending);

  const client = useApolloClient() as unknown as ApolloClientLib;

  const contactsQueryVariables = {
    sortType: contactSort?.value,
    audienceBuilder: false,
    returnAllIds: true,
    ...contactFilters,
  };

  // Data for Contacts tab
  const {
    contacts: contactsData,
    handleFetchMore: fetchContacts,
    loading: contactsLoading,
    pageInfo: contactPageInfo,
    extraParameters,
  } = usePaginated({
    client,
    filter: search,
    key: 'contacts',
    query: CONTACTS_QUERY,
    queryVariables: contactsQueryVariables,
  });

  const groupsQueryVariables = {
    sortType: groupSort?.value,
    relayStylePagination: true,
  };

  // Data for Groups tab
  const {
    groupsV2: groupsData,
    handleFetchMore: fetchGroups,
    loading: groupsLoading,
    pageInfo: groupPageInfo,
  } = usePaginated({
    client,
    query: GROUPS_V2_NAMES_QUERY,
    key: 'groupsV2',
    filter: search,
    queryVariables: groupsQueryVariables,
  });

  const customMapNode = (edgeNode: {
    node?: object | null;
    selected: boolean;
  }) => ({
    ...edgeNode.node,
    selected: edgeNode.selected,
  });

  // Data for BTM lists
  const {
    handleFetchMore: tagsHandleFetchMore,
    tagsV2: tags,
    loading: tagsLoading,
    pageInfo: tagsPageInfo,
  } = usePaginated({
    client,
    query: TAGS_V2_QUERY,
    filter: search,
    queryVariables: {
      sortType: listSort?.value,
    },
    resultsNumber: 30,
    key: 'tagsV2',
    customMapNode,
  });

  const labelsQueryVariables = {
    sortType: labelsSort?.value,
    relayStylePagination: true,
  };

  // Data for labels tab
  const {
    handleFetchMore: fetchLabels,
    labels: labelsData,
    loading: labelsLoading,
    pageInfo: labelsPageInfo,
  } = usePaginated({
    client,
    filter: search,
    key: 'labels',
    query: LABELS_QUERY,
    queryVariables: labelsQueryVariables,
  });

  // Selects an active tab and unsets any active filters
  const handleTabSelect = useCallback(
    ({ value }: { value: string }) => {
      setSelectedTab(value);
      setContactSort(ascending);
      setGroupSort(ascending);
      setContactFilters({});
      setSearch('');
    },
    [ascending, setSearch, setContactFilters]
  );

  const getTabs = useCallback(() => {
    const tabs = [
      {
        label: i18n.t('customers-CustomerList-contacts'),
        value: TABS.CONTACTS,
      },
      {
        label: i18n.t('settings-Groups-groups'),
        value: TABS.GROUPS,
      },
    ];
    if (hasBTMListPermissions) {
      tabs.push({
        label: i18n.t('slideouts-GroupMessageRecipients-lists', {
          defaultValue: 'Lists',
        }),
        value: TABS.BROADCAST_LISTS,
      });
    }
    if (hasLabelsFF) {
      tabs.push({
        label: i18n.t('settings-Label-labels'),
        value: TABS.LABELS,
      });
    }
    return tabs;
  }, [hasBTMListPermissions, hasLabelsFF]);

  const activeTabs = getTabs().map((tab) => ({
    ...tab,
    active: selectedTab === tab.value,
  }));

  // Maps selected filter options into an array of IDs
  const handleSubmitFilter = (filters: ContactsQueryFilters) => {
    if (selectedTab === TABS.CONTACTS) {
      setContactFilters(filters);
    }
  };

  const handleSubmitSort = (sort: { label: string; value: string }) => {
    if (selectedTab === TABS.CONTACTS) {
      setContactSort(sort);
    } else if (selectedTab === TABS.GROUPS) {
      setGroupSort(sort);
    } else if (selectedTab === TABS.BROADCAST_LISTS) {
      setListSort(sort);
    } else if (selectedTab === TABS.LABELS) {
      setLabelsSort(sort);
    }
  };

  const handleContinue = useCallback(() => {
    handleSaveAndExit(selectedAudience);
    if (setWizardPage) setWizardPage('CreateAndEditTask');
  }, [handleSaveAndExit, selectedAudience, setWizardPage]);

  const backStep = () => {
    closeWizard();
    if (setWizardPage) setWizardPage('CreateAndEditTask');
  };

  const continueButtonText =
    selectedAudienceCount === 1
      ? i18n.t('slideouts-GroupMessageRecipients-contact', {
          number: selectedAudienceCount,
        })
      : i18n.t('slideouts-GroupMessageRecipients-addContacts', {
          number: selectedAudienceCount,
        });

  const handleAudienceSelectionWithContext = (
    params: HandleAudienceSelectionParams
  ) => {
    const allContactIds = extraParameters?.all_contact_ids || [];
    const allCompanyIds = extraParameters?.all_company_ids || [];

    if (params.selectAll) {
      // Select All
      handleAudienceSelected({
        ...params,
        contactIds: allContactIds,
        companyIds: allCompanyIds,
        wasSelectAll: true,
      });
    } else if (params.transitionFromSelectAll) {
      if (params.excludeId) {
        // Handle excluding an item in select all mode
        handleAudienceSelected({
          selectAll: true,
          wasSelectAll: true,
          excludeId: params.excludeId,
          excludeType: params.excludeType,
        });
      } else if (params.includeId) {
        // Handle re-including an item in select all mode
        handleAudienceSelected({
          selectAll: true,
          wasSelectAll: true,
          includeId: params.includeId,
          includeType: params.includeType,
        });
      }
    } else {
      // Normal selection (add/remove)
      handleAudienceSelected(params);
    }
  };

  const allSelectableContactIds = extraParameters?.all_contact_ids;

  return (
    <CreateBroadcastListTemplate
      continueButtonText={continueButtonText}
      continueButtonAction={handleContinue}
      continueButtonDisabled={selectedAudienceCount === 0}
      backButtonAction={backStep}
      showIcon={false}
      isEdit={false}
      tag={{
        name: i18n.t('tasks-bulkCreate-selectContacts'),
        error: '',
        description: '',
        descriptionError: '',
      }}
    >
      <Container>
        <Header>
          <TabList
            dataTestId="create-broadcastList-tabs"
            options={activeTabs}
            onClick={handleTabSelect}
          />
          <SearchBar
            dataTestId="create-broadcastList-search"
            value={search}
            setValue={(e: string) => {
              setSearch(e);
            }}
            placeholder={i18n.t('search-Search-search')}
            hideBottomSpace
            customContainerStyle={customInputStyle}
          />
        </Header>
        {selectedTab === TABS.CONTACTS && (
          <TaskContactsTable
            contacts={contactsData || []}
            allSelectableContactIds={allSelectableContactIds}
            onAdd={handleAudienceSelectionWithContext}
            onFilter={handleSubmitFilter}
            onSort={handleSubmitSort}
            sort={contactSort}
            pageInfo={contactPageInfo}
            loading={contactsLoading}
            loadMoreRows={fetchContacts}
            type={BULK_TASK.TASK}
            hasLabelsFF={hasLabelsFF}
            selectedAudience={selectedAudience}
            heightDifference={329}
            disableCheckboxes={false}
            noResultsCustomTranslation="tasks-general-noResultsFound"
            noResultsCustomTranslationDefault="no results found"
          />
        )}
        {selectedTab === TABS.GROUPS && (
          <GroupsTable
            groups={groupsData || []}
            onAdd={handleAudienceSelected}
            onSort={handleSubmitSort}
            sort={groupSort}
            pageInfo={groupPageInfo}
            loading={groupsLoading}
            loadMoreRows={fetchGroups}
            type={BULK_TASK.TASK}
            selectedAudience={selectedAudience}
          />
        )}
        {selectedTab === TABS.BROADCAST_LISTS && (
          <ListsTable
            onSort={handleSubmitSort}
            loadMoreRows={tagsHandleFetchMore}
            lists={tags}
            sort={listSort}
            loading={tagsLoading}
            onAdd={handleAudienceSelected}
            heightDifference={354}
            disableCheckboxes={false}
            type={BULK_TASK.TASK}
            selectedAudience={selectedAudience}
            pageInfo={tagsPageInfo}
          />
        )}
        {selectedTab === TABS.LABELS && (
          <LabelsTable
            labels={labelsData || []}
            onAdd={handleAudienceSelected}
            onSort={handleSubmitSort}
            sort={labelsSort}
            pageInfo={labelsPageInfo}
            loading={labelsLoading}
            loadMoreRows={fetchLabels}
            type={BULK_TASK.TASK}
            selectedAudience={selectedAudience}
          />
        )}
      </Container>
    </CreateBroadcastListTemplate>
  );
};

export default TaskAudienceBuilder;

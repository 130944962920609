import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { canPreviewBasedOnFileExtension } from 'client-lib/src/lib/utils/helpers';
import { Checkbox, AttachmentTile } from '../../elements';

const FormWrapper = styled.div`
  width: 100%;
`;

const CheckboxWrapper = styled.div`
  padding-top: 8px;
`;

const AttachmentsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

// TODO refactor everywhere that uses this to use new attachments array
export const CreateFormAttachment = ({
  attachments,
  sendAsLink,
  onMethodChange,
  onClose,
  disabled,
  disableSendAsLink,
  allowAnnotation,
  onAnnotate,
}) => {
  const canPreviewAtLeastOneAttachment = attachments.some((attachment) =>
    canPreviewBasedOnFileExtension(attachment.originalFilename)
  );

  return attachments.length ? (
    <FormWrapper>
      <AttachmentsWrapper>
        {attachments?.map((attachment, i) => (
          <AttachmentTile
            attachment={attachment}
            onDelete={() => {
              if (!disabled) onClose(attachment?.id);
            }}
            allowAnnotation={allowAnnotation}
            onAnnotate={() => onAnnotate(i)}
            key={attachment?.id || i}
          />
        ))}
      </AttachmentsWrapper>
      {canPreviewAtLeastOneAttachment && !disableSendAsLink ? (
        <CheckboxWrapper>
          <Checkbox
            checked={sendAsLink}
            onCheck={onMethodChange}
            label={i18n.t('threads-FileUpload-sendAsLink')}
            disabled={disabled}
            dataTestId="send-as-link-checkbox"
          />
        </CheckboxWrapper>
      ) : null}
    </FormWrapper>
  ) : null;
};

CreateFormAttachment.propTypes = {
  attachments: PropTypes.array.isRequired,
  sendAsLink: PropTypes.bool,
  onMethodChange: PropTypes.func,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
  disableSendAsLink: PropTypes.bool,
  allowAnnotation: PropTypes.bool,
  onAnnotate: PropTypes.func,
};

CreateFormAttachment.defaultProps = {
  sendAsLink: false,
  onMethodChange: () => {},
  onClose: null,
  disabled: false,
  disableSendAsLink: false,
  allowAnnotation: false,
  onAnnotate: () => {},
};

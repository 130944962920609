import React from 'react';
import i18n from 'i18n-js';
import { Avatar, Chip, EmphasisText, MetaText } from '../..';
import Option, { centeredText } from './Option';
import {
  ChildrenColumnWrapper,
  ChildrenWrapper,
  OptionColumnWrapper,
  OptionWrapper,
  RightSideOptionWrapper,
} from './styles';
import type {
  CreateDropdownProps,
  CreateMultiValueChipProps,
  CreateNoOptionsComponentProps,
  CreateOptionProps,
  RSOptionRef,
} from './types';
import LABEL_THEMES from '../../../styles/themes/library/label.js';
import DropdownIndicator from './DropdownIndicator';
import { getLabelThemeColor } from '../../../styles/themes/library/utils/getLabelThemeColor';

// If you want to insert right side content
// pass a key value pair with "rightSideOptionContent"
// into the options object for the "loadOptions" prop
// see TemplateAsyncSelect for an example.
export const createOption: CreateOptionProps = ({
  props,
  customOptionStyle,
  dataTestId,
  isMulti,
  ref,
}) => {
  if (props?.data?.avatarContent) {
    const role = props?.data?.role ? props?.data?.role.toLowerCase() : null;
    return (
      <Option
        customOptionStyle={customOptionStyle}
        dataTestId={dataTestId}
        applyLabelColor={props?.data?.applyLabelColor}
        isCheckable={isMulti}
        {...props}
        innerRef={ref}
      >
        <OptionColumnWrapper>
          <Avatar size="md" avatarUrl={props?.data?.avatarContent} />
          <ChildrenColumnWrapper>
            <EmphasisText>{props?.data?.label}</EmphasisText>
            {role ?? <MetaText>{role}</MetaText>}
          </ChildrenColumnWrapper>
        </OptionColumnWrapper>
      </Option>
    );
  }
  if (props?.data?.rightSideOptionContent)
    return (
      <Option
        customOptionStyle={customOptionStyle}
        dataTestId={dataTestId}
        applyLabelColor={props?.data?.applyLabelColor}
        isCheckable={isMulti}
        {...props}
        innerRef={ref as RSOptionRef}
      >
        <OptionWrapper>
          <ChildrenWrapper>{props.children}</ChildrenWrapper>
          <RightSideOptionWrapper>
            {props?.data?.rightSideOptionContent}
          </RightSideOptionWrapper>
        </OptionWrapper>
      </Option>
    );
  return (
    <Option
      customOptionStyle={customOptionStyle}
      dataTestId={dataTestId}
      applyLabelColor={props?.data?.applyLabelColor}
      isCheckable={isMulti}
      {...props}
      innerRef={ref as RSOptionRef}
    >
      {props.data.label}
    </Option>
  );
};

export const createDropdownIndicator: CreateDropdownProps = ({
  props,
  dataTestId,
}) => <DropdownIndicator dataTestId={dataTestId} {...props} />;

/** Creates Selected Chip components in input textbar */
export const createMultiValueChip: CreateMultiValueChipProps = ({
  props,
  dataTestId,
}) => {
  const { data, children } = props;
  const removeButton = (children as { props: { innerProps: object } }[])[1];
  const removeProps = removeButton?.props.innerProps;

  return (
    <Chip
      {...props}
      dataTestId={dataTestId}
      onDelete={() => {
        /* override */
      }}
      backgroundColor={getLabelThemeColor(data.color || '')}
      textColor={
        data.color ? LABEL_THEMES.LABEL_TEXT : LABEL_THEMES.LABEL_DARK_TEXT
      }
      customDeleteProps={removeProps}
    >
      {data.label}
    </Chip>
  );
};

export const createNoOptionComponent: CreateNoOptionsComponentProps = ({
  props,
  dataTestId,
  ref,
  noOptionsMessage,
}) => (
  <Option
    dataTestId={dataTestId}
    {...props}
    innerRef={ref}
    isSelected={false}
    customOptionStyle={centeredText}
  >
    {noOptionsMessage || i18n.t('customers-InfiniteScroll-noResultsFound')}
  </Option>
);

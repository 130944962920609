import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { AVAILABLE_PERMISSIONS, useAnnouncementList } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import i18n from 'i18n-js';
import CreateEditList from '../../Modals/CreateEditList';
import PaginationButtonsWrapper from '../../Common/PaginationButtonsWrapper';
import {
  Heading2,
  Heading4,
  Loading,
  Table,
  TableBody,
  TableHead,
  IconButton,
  Button,
  Modal,
  Text,
} from '../../../elements';
import BubbleConfirmation from '../../Common/BubbleConfirmation';
import useMeasure from '../../Animations/useMeasure';
import MeasurableBadgeContainer from '../../Common/MeasurableContainers/MeasurableBadgeContainer';
import useGetUserAccountPolicies from '../../../hooks/customer/useGetUserAccountPolicies';
import {
  setActiveCreateBroadcastListSection,
  setListsSearchFilter,
} from '../../../actions/general';
import ListAudienceFlyout from './ListAudienceFlyout/ListAudienceFlyout';

const ButtonWrapper = styled.span`
  margin-right: 8px;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 8px;
`;

const CreateListWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8px;
`;

const IconsWrapper = styled.td`
  align-items: center;
  justify-content: flex-end;
  display: flex;
`;

const InitialWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 400px;
`;

const InitialWrapperText = styled(Grid)`
  flex-wrap: wrap;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DescriptionSpan = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const ListNameContainer = styled.div`
  overflow: hidden;
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const buttonCustomStyle = () => `
    padding: 0px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
`;

export const limitedAnncListView = (currentUser) => {
  const {
    [AVAILABLE_PERMISSIONS.LIST_TAGS_GROUPS]: accountListTagsPermission,
  } = useGetUserAccountPolicies({
    actionList: [AVAILABLE_PERMISSIONS.LIST_TAGS_GROUPS],
    userId: currentUser?.userId,
  });

  return accountListTagsPermission;
};

const ListsTable = ({
  lists,
  displayOptions,
  handleOnPencilClick,
  handleOnBinClick,
  handleOnCloseBubble,
  handleDeleteTag,
  setListFlyout,
  setDeleteModal,
  showGroups,
  showDelete,
}) => {
  const history = useHistory();
  const broadcastListFF = useSelector(
    (state) => state.accountData.account?.ff_broadcast_list_improvements
  );
  const [measureBind, measure] = useMeasure();
  const groupContainerWidth = measure?.width;

  const currentUser = useSelector((state) => state?.session?.currentUser);

  useGetUserAccountPolicies({
    actionList: [AVAILABLE_PERMISSIONS.LIST_TAGS_GROUPS],
    userId: currentUser?.userId,
  });

  const limitedAnncListEnabled = limitedAnncListView(currentUser);

  return (
    <Table>
      <TableHead>
        <tr>
          <th>{i18n.t('slideouts-CustomerAccountNameEdit-name')}</th>

          <th>{i18n.t('slideouts-GroupMessageOverview-description')}</th>

          <th />

          <th>{i18n.t('broadcasts-BroadcastList-recipients')}</th>
          {limitedAnncListEnabled && showGroups && (
            <th {...measureBind}>{i18n.t('settings-TeamMemberForm-groups')}</th>
          )}

          <th />
        </tr>
      </TableHead>
      <TableBody>
        {lists.map((list) => {
          const badgeLabels = list.tagGroupShares?.map((group) => group.name);

          return (
            <tr key={list.name}>
              <td
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '80px',
                }}
              >
                <Button
                  dataTestId={`list-flyout-button-${list?.id}`}
                  onClick={() => {
                    if (broadcastListFF) {
                      setListFlyout(list);
                    } else {
                      history.push({
                        pathname: `/broadcasts/lists/${list.id}/edit`,
                        state: { listId: list.id },
                      });
                    }
                  }}
                  noOutline
                  type="link"
                  customStyle={buttonCustomStyle}
                >
                  {list.name}
                </Button>
              </td>

              <td>
                <DescriptionSpan>{list.description}</DescriptionSpan>
              </td>

              <td />

              <td>
                <span>{list?.audienceInfo?.reachableAudienceCount}</span>
              </td>
              {limitedAnncListEnabled && showGroups && (
                <td>
                  {groupContainerWidth ? (
                    <MeasurableBadgeContainer
                      badgeLabels={badgeLabels}
                      maxWidth={groupContainerWidth}
                    />
                  ) : null}
                </td>
              )}
              {broadcastListFF ? (
                <IconsWrapper>
                  <ButtonWrapper>
                    <IconButton
                      size="md"
                      onClick={() => handleOnPencilClick(list)}
                      contrast="highColor"
                      dataTestId={`edit-icon-${list?.id}`}
                    >
                      <i className="ri-pencil-fill" />
                    </IconButton>
                  </ButtonWrapper>
                  {showDelete ? (
                    <IconButton
                      dataTestId={`delete-icon-${list?.id}`}
                      size="md"
                      contrast="destructive"
                      onClick={() => {
                        setListFlyout(list);
                        setDeleteModal(true);
                        handleOnBinClick(list);
                      }}
                    >
                      <i className="ri-delete-bin-4-fill" />
                    </IconButton>
                  ) : null}
                </IconsWrapper>
              ) : (
                <IconsWrapper>
                  <ButtonWrapper>
                    <IconButton
                      size="md"
                      onClick={() => handleOnPencilClick(list)}
                    >
                      <i className="ri-pencil-fill" />
                    </IconButton>
                  </ButtonWrapper>
                  {showDelete ? (
                    <IconButton
                      size="md"
                      contrast="destructive"
                      onClick={() => handleOnBinClick(list)}
                    >
                      <i className="ri-delete-bin-4-fill" />
                    </IconButton>
                  ) : null}
                  <BubbleConfirmation
                    isOpen={!!(displayOptions && displayOptions === list.name)}
                    onClickOutside={handleOnCloseBubble}
                    subText={i18n.t('settings-GroupMembers-actionSubtext')}
                    moveLeftVal={20}
                    moveBubbleRightVal={10}
                    top="10px"
                    title={i18n.t('settings-TagList-deleteTag')}
                    cancelAction={handleOnCloseBubble}
                    confirmationButtonText={i18n.t('settings-TagList-delete')}
                    redButtonText
                    confirmationAction={handleDeleteTag}
                  />
                </IconsWrapper>
              )}
            </tr>
          );
        })}
      </TableBody>
    </Table>
  );
};

ListsTable.propTypes = {
  lists: PropTypes.array.isRequired,
  displayOptions: PropTypes.string.isRequired,
  handleOnPencilClick: PropTypes.func.isRequired,
  handleOnBinClick: PropTypes.func.isRequired,
  handleOnCloseBubble: PropTypes.func.isRequired,
  handleDeleteTag: PropTypes.func.isRequired,
  setListFlyout: PropTypes.func,
  setDeleteModal: PropTypes.func,
  showGroups: PropTypes.bool,
  showDelete: PropTypes.bool,
};

ListsTable.defaultProps = {
  setListFlyout: () => {},
  setDeleteModal: () => {},
  showGroups: true,
  showDelete: true,
};

const ListsTableContainer = ({
  openModal,
  setOpenModal,
  setError,
  setSuccess,
  currentUser,
  showGroups,
  showDelete,
}) => {
  const [displayOptions, setDisplayOptions] = useState('');
  const dispatch = useDispatch();
  const [listFlyout, setListFlyout] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);

  const client = useApolloClient();
  const history = useHistory();
  const listsSearchFilter = useSelector(
    (state) => state?.general?.listsSearchFilter
  );
  const broadcastListFF = useSelector(
    (state) => state.accountData.account?.ff_broadcast_list_improvements
  );
  const {
    handleCreateTag,
    handleDeleteTag,
    handleFetchMore,
    handlePrevious,
    setList,
    lists,
    pageInfo,
    loading,
  } = useAnnouncementList({
    client,
    accountId: currentUser.accountId,
    setError,
    setSuccess,
    listsSearchFilter,
    i18n,
  });

  const handleOnPencilClick = (list) => {
    if (broadcastListFF) {
      setListFlyout({});
      dispatch(setActiveCreateBroadcastListSection({ listId: list?.id }));
    }
    dispatch(setListsSearchFilter(''));
    history.push({
      pathname: `/broadcasts/lists/${list.id}/edit`,
      state: { listId: list.id },
    });
  };

  const handleOnCloseModal = () => {
    setOpenModal(false);
    setList(null);
  };

  const handleOnBinClick = (list) => {
    setDisplayOptions(list.name);
    setList(list);
  };

  const handleOnCloseBubble = () => {
    setDisplayOptions('');
    setList(null);
  };

  const renderInitialState = () =>
    listsSearchFilter.length >= 1 ? (
      <HeaderWrapper>
        <Heading2>{i18n.t('slideouts-ListsTable-noList')}</Heading2>
      </HeaderWrapper>
    ) : (
      <>
        <HeaderWrapper>
          <Heading2>{i18n.t('slideouts-ListsTable-noListCreated')}</Heading2>
        </HeaderWrapper>

        {showDelete && (
          <>
            <HeaderWrapper>
              <Heading4>{i18n.t('slideouts-ListsTable-clickButton')}</Heading4>
            </HeaderWrapper>
            <CreateListWrapper>
              <Button
                dataTestId="createFirstListButton"
                onClick={() => setOpenModal(true)}
              >
                {i18n.t('slideouts-GroupMessageRecipients-createList')}
              </Button>
            </CreateListWrapper>
          </>
        )}
      </>
    );

  const onHandleCloseDeleteModal = () => {
    setDeleteModal(false);
    setListFlyout({});
  };

  return (
    <Grid data-testid="announcement-lists">
      <ListAudienceFlyout
        openFlyout={!!listFlyout?.id && !deleteModal}
        listFlyout={listFlyout}
        setListFlyout={setListFlyout}
        handleOnPencilClick={handleOnPencilClick}
      />
      {loading ? (
        <LoadingWrapper>
          <Loading size="sm" />
        </LoadingWrapper>
      ) : lists.length > 0 ? (
        <>
          <ListsTable
            lists={lists}
            displayOptions={displayOptions}
            handleOnPencilClick={handleOnPencilClick}
            handleOnBinClick={handleOnBinClick}
            handleOnCloseBubble={handleOnCloseBubble}
            handleDeleteTag={handleDeleteTag}
            setListFlyout={setListFlyout}
            setDeleteModal={setDeleteModal}
            showGroups={showGroups}
            showDelete={showDelete}
          />
          <Grid>
            <PaginationButtonsWrapper
              handleNext={handleFetchMore}
              handlePrevious={handlePrevious}
              pageInfo={pageInfo}
            />
          </Grid>
        </>
      ) : (
        <InitialWrapper>
          <InitialWrapperText>{renderInitialState()}</InitialWrapperText>
        </InitialWrapper>
      )}
      <Modal
        modalTitle={
          <>
            {i18n.t('broadcasts-list-deleteMessageHeaderNoTitle', {
              defaultValue: `Are you sure you want to delete the list`,
            })}
            :<ListNameContainer>{listFlyout?.name}</ListNameContainer>
          </>
        }
        size="sm"
        isOpen={deleteModal}
        primaryButtonOnClick={() => {
          handleDeleteTag();
          onHandleCloseDeleteModal();
        }}
        secondaryButtonOnClick={onHandleCloseDeleteModal}
        onRequestClose={onHandleCloseDeleteModal}
        primaryButtonText={i18n.t('settings-TeamMemberForm-delete', {
          defaultValue: 'Delete',
        })}
        secondaryButtonText={i18n.t('modals-DeleteTeamMember-cancel', {
          defaultValue: 'Cancel',
        })}
      >
        <Text>
          {i18n.t('broadcasts-list-deleteMessageBody', {
            defaultValue:
              'By proceeding, this list and its links to Broadcast Messages will be permanently deleted. This action cannot be undone.',
          })}
        </Text>
      </Modal>

      <CreateEditList
        handleOnClose={handleOnCloseModal}
        open={openModal}
        client={client}
        handleOnConfirm={handleCreateTag}
      />
    </Grid>
  );
};

ListsTableContainer.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  showGroups: PropTypes.bool,
  showDelete: PropTypes.bool,
};

ListsTableContainer.defaultProps = {
  showGroups: true,
  showDelete: true,
};

export default ListsTableContainer;

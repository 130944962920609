import { PK_TYPENAMES } from 'client-lib';
import type { SelectedAudience } from '../types';
import type { ContactsQueryFilters } from '../../../../elements/FilterBy/ContactsFilterBy.tsx';
import type { CustomerContact } from '../../../../utils/helpers/types.ts';

export interface HandleAudienceSelectionParams {
  // Individual selection parameters
  contactIds?: string[];
  companyIds?: string[];
  groupIds?: string[];
  labelIds?: string[];
  tagIds?: string[];

  // Special flags
  remove?: boolean;
  clearAll?: boolean;

  // Select All functionality
  selectAll?: boolean;
  wasSelectAll?: boolean;

  // For transitioning from selectAll mode
  transitionFromSelectAll?: boolean;

  // For excluding/including specific items
  excludeId?: string;
  excludeType?: string;
  includeId?: string;
  includeType?: string;

  // Don't update state, just update UI
  updateUIOnly?: boolean;

  // All available entities from query
  allContactIds?: string[];
  allCompanyIds?: string[];

  // Stored filters when select all was clicked
  selectAllFilters?: { search?: string } & ContactsQueryFilters;
}

/**
 * Handles updates to Contact and Company selections
 * Manages all the complexity of individual selection and select-all modes
 */
export const handleContactsAndCompanies = (
  prevAudience: SelectedAudience,
  params: HandleAudienceSelectionParams
): SelectedAudience => {
  // Skip state updates if just updating UI
  if (params.updateUIOnly) {
    return prevAudience;
  }

  // Case 1: Clear all selections
  if (params.clearAll) {
    return {
      ...prevAudience,
      selectAll: false,
      selectAllFilters: undefined,
      excludedContactIds: [],
      excludedCompanyIds: [],
      contactIds: [],
      companyIds: [],
    };
  }

  // Case 2: Switching to "select all" mode
  if (params.selectAll) {
    return {
      ...prevAudience,
      selectAll: true,
      wasSelectAll: true,
      // Use provided contact/company IDs or empty arrays
      contactIds: params.contactIds || params.allContactIds || [],
      companyIds: params.companyIds || params.allCompanyIds || [],
      // Reset exclusion lists when selecting all
      excludedContactIds: [],
      excludedCompanyIds: [],
      // Store the filters that were used when selectAll was clicked
      selectAllFilters: params.selectAllFilters,
    };
  }

  // Case 3: Handling exclusions in "select all" mode
  if (params.excludeId && params.excludeType) {
    const isContact = params.excludeType === PK_TYPENAMES.CUSTOMER_CONTACT;

    return {
      ...prevAudience,
      // Keep select all mode active
      selectAll: true,
      excludedContactIds: isContact
        ? [...(prevAudience.excludedContactIds || []), params.excludeId]
        : prevAudience.excludedContactIds || [],
      excludedCompanyIds: !isContact
        ? [...(prevAudience.excludedCompanyIds || []), params.excludeId]
        : prevAudience.excludedCompanyIds || [],
    };
  }

  // Case 4: Handling inclusions in "select all" mode
  if (params.includeId && params.includeType) {
    const isContact = params.includeType === PK_TYPENAMES.CUSTOMER_CONTACT;

    return {
      ...prevAudience,
      selectAll: true, // Keep select all active
      // Remove from excluded items
      excludedContactIds: isContact
        ? (prevAudience.excludedContactIds || []).filter(
            (id) => id !== params.includeId
          )
        : prevAudience.excludedContactIds || [],
      excludedCompanyIds: !isContact
        ? (prevAudience.excludedCompanyIds || []).filter(
            (id) => id !== params.includeId
          )
        : prevAudience.excludedCompanyIds || [],
    };
  }

  // Case 5: Transitioning from "select all" to normal selection
  if (prevAudience.selectAll && params.wasSelectAll && !params.selectAll) {
    return {
      ...prevAudience,
      selectAll: false,
      selectAllFilters: undefined,
      // Keep the explicitly selected items
      // (already set in the prevAudience)
    };
  }

  // Case 6: Removing specific items in normal selection mode
  if (params.remove) {
    return {
      ...prevAudience,
      contactIds: prevAudience.contactIds.filter(
        (id) => !(params.contactIds || []).includes(id)
      ),
      companyIds: prevAudience.companyIds.filter(
        (id) => !(params.companyIds || []).includes(id)
      ),
    };
  }

  // Case 7: Adding specific items in normal selection mode
  return {
    ...prevAudience,
    // Set selectAll to false when adding individual items
    selectAll: false,
    contactIds: [
      ...new Set([...prevAudience.contactIds, ...(params.contactIds || [])]),
    ],
    companyIds: [
      ...new Set([...prevAudience.companyIds, ...(params.companyIds || [])]),
    ],
  };
};

/**
 * Handles the selection of groups, tags, and labels
 * Much simpler than contact/company selection as it doesn't involve "select all" logic
 */
export const handleGroupsTagsLabels = (
  prevAudience: SelectedAudience,
  params: HandleAudienceSelectionParams
): SelectedAudience => {
  const { groupIds = [], tagIds = [], labelIds = [], remove = false } = params;

  // Skip updates if no relevant IDs are provided
  if (!groupIds.length && !tagIds.length && !labelIds.length) {
    return prevAudience;
  }

  if (remove) {
    return {
      ...prevAudience,
      groupIds: prevAudience.groupIds.filter((id) => !groupIds.includes(id)),
      tagIds: prevAudience.tagIds.filter((id) => !tagIds.includes(id)),
      labelIds: prevAudience.labelIds.filter((id) => !labelIds.includes(id)),
    };
  }

  return {
    ...prevAudience,
    groupIds: [...new Set([...prevAudience.groupIds, ...groupIds])],
    tagIds: [...new Set([...prevAudience.tagIds, ...tagIds])],
    labelIds: [...new Set([...prevAudience.labelIds, ...labelIds])],
  };
};

/**
 * Main function to handle all audience selection scenarios
 * Combines the contact/company handling with group/tag/label handling
 */
export const handleAudienceSelection = (
  prevAudience: SelectedAudience,
  params: HandleAudienceSelectionParams
): SelectedAudience => {
  // First handle contacts and companies
  const contactsAndCompaniesResult = handleContactsAndCompanies(
    prevAudience,
    params
  );

  // Then handle groups, tags, labels and return the final result
  return handleGroupsTagsLabels(contactsAndCompaniesResult, params);
};

/**
 * Clear the selected audience or return the previous audience if specified
 */
export const handleClearSelectedAudience = (
  previousAudience: SelectedAudience | null
): SelectedAudience => {
  if (previousAudience) {
    return previousAudience;
  }

  return {
    contactIds: [],
    groupIds: [],
    companyIds: [],
    labelIds: [],
    tagIds: [],
    selectAll: false,
    wasSelectAll: false,
    excludedContactIds: [],
    excludedCompanyIds: [],
  };
};

/**
 * Determines if a specific item should be checked in the UI
 */
export const shouldItemBeChecked = (
  id: string,
  audience: SelectedAudience
): boolean => {
  if (audience.selectAll) {
    // If selectAll is true, all items should be checked
    // unless they're in the excluded lists
    return !(
      (audience.excludedContactIds || []).includes(id) ||
      (audience.excludedCompanyIds || []).includes(id)
    );
  }

  // Otherwise, check if the item is in the selected lists
  return (
    audience?.contactIds?.includes(id) || audience?.companyIds?.includes(id)
  );
};

/**
 * Determines if all visible items are selected
 */
export const determineAllItemsSelected = (
  visibleItems: Array<{ id: string; __typename: string }>,
  audience: SelectedAudience,
  checkContactDisabled?: (contact: CustomerContact) => boolean,
  allSelectableContactIds?: string[]
): boolean => {
  // If no items are loaded, checkbox should be unchecked
  if (!visibleItems.length) return false;

  // Filter visible items to only those that are selectable if checkContactDisabled is provided
  const selectableItems = checkContactDisabled
    ? visibleItems.filter(
        (item) => !checkContactDisabled(item as unknown as CustomerContact)
      )
    : visibleItems;

  // If no items are selectable, checkbox should be unchecked
  if (!selectableItems.length) return false;

  // If in "select all" mode, check for excluded items
  if (audience.selectAll) {
    // Check for excluded items from the selectable items
    return !selectableItems.some((item) => {
      const isContact = item.__typename === PK_TYPENAMES.CUSTOMER_CONTACT;

      // If this item is a contact, check if it's in the excluded list
      if (isContact) {
        // First check if it's in the excluded contacts list
        if ((audience.excludedContactIds || []).includes(item.id)) {
          return true;
        }

        // If we have allSelectableContactIds and this ID isn't in there,
        // then it's effectively excluded even if not in the excludedContactIds
        if (
          allSelectableContactIds &&
          !allSelectableContactIds.includes(item.id)
        ) {
          return true;
        }

        return false;
      }
      // It's a company, check if it's in the excluded companies list
      return (audience.excludedCompanyIds || []).includes(item.id);
    });
  }

  // Check if all selectable visible items are individually selected
  const selectableIds = selectableItems.map((item) => item.id);
  return (
    selectableIds.length > 0 &&
    selectableIds.every(
      (id) =>
        audience.contactIds.includes(id) || audience.companyIds.includes(id)
    )
  );
};
/**
 * Determines if the selection is in an indeterminate state
 */
export const determineIndeterminateState = (
  visibleItems: Array<{ id: string; __typename: string }>,
  audience: SelectedAudience,
  allSelected: boolean,
  checkContactDisabled?: (contact: CustomerContact) => boolean,
  allSelectableContactIds?: string[]
): boolean => {
  // If no items are loaded, not indeterminate
  if (!visibleItems.length) return false;

  // Filter visible items to only those that are selectable if checkContactDisabled is provided
  const selectableItems = checkContactDisabled
    ? visibleItems.filter(
        (item) => !checkContactDisabled(item as unknown as CustomerContact)
      )
    : visibleItems;

  // If no items are selectable, not indeterminate
  if (!selectableItems.length) return false;

  // Handle select all mode with exclusions
  if (audience.selectAll) {
    // Check if some but not all selectable items are excluded
    const someExcluded = selectableItems.some((item) => {
      const isContact = item.__typename === PK_TYPENAMES.CUSTOMER_CONTACT;

      if (isContact) {
        // Check if this contact is excluded either explicitly or by not being in the selectable list
        const explicitlyExcluded = (audience.excludedContactIds || []).includes(
          item.id
        );
        const notInSelectableList =
          allSelectableContactIds && !allSelectableContactIds.includes(item.id);
        return explicitlyExcluded || notInSelectableList;
      }
      // For companies, just check the excluded companies list
      return (audience.excludedCompanyIds || []).includes(item.id);
    });

    // Check if some items are not excluded
    const someIncluded = selectableItems.some((item) => {
      const isContact = item.__typename === PK_TYPENAMES.CUSTOMER_CONTACT;

      if (isContact) {
        // Not excluded if it's not in the excluded list and is in the selectable list (if provided)
        const notExplicitlyExcluded = !(
          audience.excludedContactIds || []
        ).includes(item.id);
        const inSelectableList =
          !allSelectableContactIds || allSelectableContactIds.includes(item.id);
        return notExplicitlyExcluded && inSelectableList;
      }
      // For companies, just check they're not in the excluded list
      return !(audience.excludedCompanyIds || []).includes(item.id);
    });

    // Indeterminate if some are excluded and some are included
    return someExcluded && someIncluded;
  }

  // If all selected or none selected, not indeterminate
  if (allSelected) return false;
  if (!audience.contactIds.length && !audience.companyIds.length) return false;

  // Some but not all selected
  return selectableItems.some(
    (item) =>
      audience.contactIds.includes(item.id) ||
      audience.companyIds.includes(item.id)
  );
};

import React, { useContext, useState, useRef, type RefObject } from 'react';
import styled, { ThemeContext } from 'styled-components';
import theme from 'styled-theming';
import { useSelector } from 'react-redux';
import { Chip } from '../../elements';
import { Sizes, HighlightTypes } from '../../elements/Chip/Chip';
import Bubble from '../Common/Bubble';
import AddTag from './AddTag';
import TextWithHighlights from '../Common/TextWithHighlights';
import type { AppState, WorkOrder } from '../../utils/helpers/types';

const BUBBLE_WIDTH = theme('fontSize', {
  default: '168',
  large: '208',
});

const BUBBLE_HEIGHT = theme('fontSize', {
  default: '160',
  large: '206',
});

const TAG_MAX_WIDTH = theme('fontSize', {
  default: '134',
  large: '174',
});

const TAG_ICON_FONTSIZE = theme('fontSize', {
  default: '16px',
  large: '20px',
});

const MultiTagDisplayWrapper = styled.div`
  display: flex;
  align-items: end; // baseline? Then would need to delete the lower wrapper
  white-space: nowrap;
  text-overflow: ellipsis;
  gap: 12px;
`;

const OptionsButtonContainer = styled.div`
  max-height: ${BUBBLE_HEIGHT}px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: flex-start;
  row-gap: 8px;
`;

interface MultiTagDisplayProps {
  id?: string;
  tags: WorkOrder[];
  handleAddOrRemoveThreadFromWorkOrder: (
    referenceNumber: string,
    remove: boolean
  ) => void;
  tagInputValue?: string;
  setTagInputValue?: (value: string) => void;
  editingRefNum: boolean;
  setEditingRefNum: (value: boolean) => void;
  flipTagBubbleCallback?: (ref: RefObject<HTMLDivElement>) => boolean;
  highlightTarget?: string | null;
  flipWorkOrderOptions?: boolean;
}

/**
 * Allows highlighting of compressed tags
 * @param highlightTarget Target tag to highlight
 * @param tags[] Array of tags to search for the highlight target
 * @returns boolean if the compressed tag should also be highlighted
 */
const shouldHighlightCompressedTag = (
  highlightTarget: string | null,
  tags: WorkOrder[]
): boolean => {
  if (!highlightTarget) return false;

  const highlightTags = !!tags?.filter((tag) =>
    tag?.referenceNumber?.toLowerCase().includes(highlightTarget)
  ).length;

  return highlightTags;
};

const MultiTagDisplay = ({
  id = '',
  tags,
  handleAddOrRemoveThreadFromWorkOrder,
  tagInputValue = '',
  setTagInputValue = () => {},
  editingRefNum,
  setEditingRefNum,
  flipTagBubbleCallback = () => false,
  highlightTarget = null,
  flipWorkOrderOptions = false,
}: MultiTagDisplayProps) => {
  const activeCustomerInfoSlideout = useSelector(
    (state: AppState) => state?.general?.activeCustomerInfoSlideout
  );
  const [optionBubbleOpen, setOptionBubbleOpen] = useState(false);

  const styledTheme = useContext(ThemeContext);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const flipTagBubbleYAxis = flipTagBubbleCallback(wrapperRef);

  const highlightTags = shouldHighlightCompressedTag(highlightTarget, tags);

  const isAIIdentified = (tag: WorkOrder) =>
    tag.source && tag?.source !== 'MANUAL';
  const maybeAddAIIdentifiedTags = (tag: WorkOrder) => {
    if (isAIIdentified(tag)) {
      return {
        highlight: true,
        highlightType: HighlightTypes.AI_IDENTIFIED,
      };
    }
    return null;
  };

  return (
    <MultiTagDisplayWrapper ref={wrapperRef}>
      {tags?.length > 1 || activeCustomerInfoSlideout ? (
        <div
          onClick={() => setOptionBubbleOpen(true)}
          onKeyDown={() => setOptionBubbleOpen(true)}
          onKeyUp={() => setOptionBubbleOpen(true)}
          role="menuitem"
          tabIndex={0}
          data-testid="thread-tag-dropdown"
        >
          <div style={{ cursor: 'pointer' }}>
            <Chip
              size={Sizes.md}
              icon={
                <i
                  className="ri-price-tag-3-fill"
                  style={{
                    fontSize: TAG_ICON_FONTSIZE({ theme: styledTheme }),
                  }}
                />
              }
              highlight={highlightTags}
            >
              {tags?.length?.toString()}
            </Chip>
          </div>
          <Bubble
            isOpen={optionBubbleOpen}
            onClickOutside={() => setOptionBubbleOpen(false)}
            moveBubbleRightVal={-43}
            moveLeftVal={30}
            bubbleMinWidth={Number(BUBBLE_WIDTH({ theme: styledTheme }))}
            top={flipTagBubbleYAxis ? '36px' : '12px'}
            zIndex={200}
            flipYAxis={flipTagBubbleYAxis}
            id={`options_bubble_${id || 0}`}
          >
            <OptionsButtonContainer>
              {tags?.map((tag, index) => (
                <Chip
                  {...maybeAddAIIdentifiedTags(tag)}
                  key={tag.id || index}
                  size={Sizes.md}
                  onDelete={
                    !highlightTarget
                      ? () =>
                          handleAddOrRemoveThreadFromWorkOrder(
                            tag.referenceNumber,
                            true
                          )
                      : null
                  }
                  maxWidth={+TAG_MAX_WIDTH({ theme: styledTheme })}
                >
                  {highlightTarget ? (
                    <TextWithHighlights
                      highlightTarget={highlightTarget}
                      text={tag.referenceNumber}
                      contrast="low"
                    />
                  ) : (
                    tag.referenceNumber
                  )}
                </Chip>
              ))}
            </OptionsButtonContainer>
          </Bubble>
        </div>
      ) : (
        tags?.map((tag, index) => (
          <Chip
            {...maybeAddAIIdentifiedTags(tag)}
            dataTestId="thread-tag-chip"
            key={tag.id || index}
            size={Sizes.md}
            onDelete={
              !highlightTarget
                ? () =>
                    handleAddOrRemoveThreadFromWorkOrder(
                      tag.referenceNumber,
                      true
                    )
                : null
            }
          >
            {highlightTarget ? (
              <TextWithHighlights
                highlightTarget={highlightTarget}
                text={tag.referenceNumber}
                contrast="low"
              />
            ) : (
              tag.referenceNumber
            )}
          </Chip>
        ))
      )}
      {tags.length < 10 && !highlightTarget ? (
        <AddTag
          tagInputValue={tagInputValue}
          setTagInputValue={setTagInputValue}
          editingRefNum={editingRefNum}
          setEditingRefNum={setEditingRefNum}
          handleAddThreadToWorkOrder={handleAddOrRemoveThreadFromWorkOrder}
          flipWorkOrderOptions={flipWorkOrderOptions}
        />
      ) : null}
    </MultiTagDisplayWrapper>
  );
};

export default MultiTagDisplay;

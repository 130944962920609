import theme from 'styled-theming';
import COLORS from '../../colors';

const EXTERNAL_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_40,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK.GREY_40,
});

const EXTERNAL_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_40,
  dark: COLORS.PK_DARK.GREY_3,
  holiday: COLORS.PK.GREY_40,
});

const EXTERNAL_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.WHITE,
});

const INTERNAL_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_30,
  dark: COLORS.PK_DARK.GREY_2,
  holiday: COLORS.PK.GREY_30,
});

const INTERNAL_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK_DARK.BLACK,
  holiday: COLORS.PK.WHITE,
});

const INTERNAL_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.BLUE_80,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_SECONDARY,
});

const BOT_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.BLUE_80,
  dark: COLORS.PK.BLUE_80,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_SECONDARY,
});

const BOT_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.BLUE_80,
  dark: COLORS.PK.BLUE_80,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_SECONDARY,
});

const BOT_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK.WHITE,
  holiday: COLORS.PK.WHITE,
});

const PRESENCE_ACTIVE = theme('mode', {
  classic: COLORS.PK_CLASSIC.TERTIARY_GREEN,
  light: COLORS.PK.GREEN_60,
  dark: COLORS.PK.GREEN_60,
  holiday: COLORS.PK.GREEN_60,
});

const PRESENCE_INACTIVE = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_GRAY,
  light: COLORS.PK.RED_80,
  dark: COLORS.PK.RED_80,
  holiday: COLORS.PK.RED_80,
});

const UNREAD_EXTERNAL_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_70,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_LOW,
  holiday: COLORS.PK.GREY_50,
});

const UNREAD_EXTERNAL_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_70,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_LOW,
  holiday: COLORS.PK.GREY_50,
});

const READ_EXTERNAL_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_30,
  dark: COLORS.PK_DARK.GREY_2,
  holiday: COLORS.PK.GREY_30,
});

const READ_EXTERNAL_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_30,
  dark: COLORS.PK_DARK.GREY_2,
  holiday: COLORS.PK.GREY_30,
});

const ACTIVE_EXTERNAL_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_40,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK.GREY_40,
});

const ACTIVE_EXTERNAL_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_40,
  dark: COLORS.PK_DARK.GREY_3,
  holiday: COLORS.PK.GREY_40,
});

const ACTIVITY_BOARD_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLACK,
  light: COLORS.PK.BLACK,
  dark: COLORS.PK_DARK.BLACK,
  holiday: COLORS.PK.BLACK,
});

const ACTIVITY_BOARD_BACKGROUND = theme('mode', {
  classic: COLORS.PK.NEUTRAL_GREY,
  light: COLORS.PK.NEUTRAL_GREY,
  dark: COLORS.PK.NEUTRAL_GREY,
  holiday: COLORS.PK.NEUTRAL_GREY,
});

const ACTIVITY_BOARD_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLACK,
  light: COLORS.PK.GREY_20,
  dark: COLORS.PK_DARK.GREY_6,
  holiday: COLORS.PK.GREY_20,
});

const THEMES = {
  EXTERNAL_BORDER,
  EXTERNAL_BACKGROUND,
  EXTERNAL_TEXT,
  INTERNAL_BORDER,
  INTERNAL_BACKGROUND,
  INTERNAL_TEXT,
  BOT_BORDER,
  BOT_BACKGROUND,
  BOT_TEXT,
  PRESENCE_ACTIVE,
  PRESENCE_INACTIVE,
  UNREAD_EXTERNAL_BACKGROUND,
  UNREAD_EXTERNAL_BORDER,
  READ_EXTERNAL_BORDER,
  READ_EXTERNAL_BACKGROUND,
  ACTIVE_EXTERNAL_BORDER,
  ACTIVE_EXTERNAL_BACKGROUND,
  ACTIVITY_BOARD_TEXT,
  ACTIVITY_BOARD_BACKGROUND,
  ACTIVITY_BOARD_BORDER,
};

export default THEMES;
